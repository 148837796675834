import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { PhoneNumberField } from 'components'
import { ButtonSubmit, PageTitle } from 'components/common/styles/auth'
import { NotifyService } from 'helpers'
import { useDebounce, useNavigateQuery } from 'hooks'
import { PHONE_NUMBER } from 'models'
import { useForm } from 'react-hook-form'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import * as yup from 'yup'
import LogoAuth from '../../components/LogoAuth'

interface IForgotPasswordForm {
  phoneNumber: PHONE_NUMBER
}

const schema = yup
  .object({
    phoneNumber: yup
      .object({
        countryCode: yup.string().required('Country code is required'),
        phoneNumber: yup.string().required('Phone number is required'),
      })
      .required('Phone number is required'),
  })
  .required()

export const ForgotPassword = () => {
  const navigateQuery = useNavigateQuery()
  const dispatch = useAppDispatch()

  const { handleSubmit, control } = useForm<IForgotPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = useDebounce(async (data: IForgotPasswordForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .forgotPassword({
        countryCode: data.phoneNumber.countryCode,
        number: data.phoneNumber.phoneNumber,
      })
      .then(() => {
        NotifyService.success('Success')
        navigateQuery(APP_ROUTES.NANNY.AUTH.CHECK_CODE.to, {
          countryCode: data.phoneNumber?.countryCode,
          number: data.phoneNumber?.phoneNumber,
        })
      })
      .catch((e) => {
        NotifyService.error(e)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Forgot Password
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Enter the phone number associated with your account and we'll send
        instructions to reset your password
      </Typography>
      <Stack spacing={2}>
        <PhoneNumberField
          control={control}
          name="phoneNumber"
          placeholder="Phone number"
        />
      </Stack>
      <Stack
        mt={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit>Send OTP</ButtonSubmit>
      </Stack>
    </Stack>
  )
}

export default ForgotPassword

import styled from '@emotion/styled'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { theme } from 'theme'

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 42px 35px 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${theme.palette.label.primary};
`

export const StyledDialogContent = styled(DialogContent)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 10px 35px 18px;
  color: ${theme.palette.text.secondary};
`

export const StyledDialogAction = styled(DialogActions)`
  align-items: stretch;
  flex-direction: column;
  padding: 18px 35px 33px;
`

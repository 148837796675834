import styled from '@emotion/styled'
import { Box, Typography, css } from '@mui/material'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface ContentBoxProps {
  $error?: boolean
}

export const ContentBox = styled(Box, transientOptions)<ContentBoxProps>`
  height: 200px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.other.stroke};
  background: ${theme.palette.backgrounds.primary};
  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid ${theme.palette.red.light};
    `}
`

export const DropBox = styled(Box)`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > svg {
    margin-bottom: 16px;
    font-size: 24px;
    color: ${theme.palette.secondary.main};
  }
`

export const DropText = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  color: ${theme.palette.text.primary};
  span {
    color: ${theme.palette.secondary.main};
  }
`

export const DropSubText = styled(Typography)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 92.308%;
  letter-spacing: 0.06px;
  color: #b6b6b6;
`

export const FilePreview = styled(Box)`
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

export const FilePreviewAction = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > svg {
    font-size: 24px;
    color: ${theme.palette.common.white};
  }
  > .MuiTypography-root {
    margin-top: 5px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 92.308%;
    letter-spacing: 0.06px;
    color: ${theme.palette.common.white};
  }
`

export const FilePreviewOverLay = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
    50% / cover no-repeat;
`

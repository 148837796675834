import { Theme } from '@mui/material/styles';

export default function Button(theme: Theme): Theme['components'] {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
            padding: '13.5px 22px 14.5px',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: '#FFA8A1',
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&:disabled': {
              backgroundColor: '#6FA1ED',
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '8px',
            padding: '13.5px 22px 14.5px',
            border: '1px solid rgba(255, 121, 110, 0.5)',
            '&:hover': {
              background: 'rgba(255, 121, 110, 0.04)',
            },
            '&:disabled': {
              border: '1px solid #B7B7B7',
              color: '#B7B7B7',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            borderRadius: '8px',
            padding: '13.5px 22px 14.5px',
            border: '1px solid rgba(34, 111, 227, 0.5)',
            '&:hover': {
              background: 'rgba(34, 111, 227, 0.04)',
            },
            '&:disabled': {
              border: '1px solid #B7B7B7',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderRadius: '8px',
            padding: '13.5px 22px 14.5px',
            '&:hover': {
              background: 'rgba(255, 121, 110, 0.04)',
            },
            '&:disabled': {
              color: '#B7B7B7',
            },
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            borderRadius: '8px',
            padding: '13.5px 22px 14.5px',
            '&:hover': {
              background: 'rgba(34, 111, 227, 0.04)',
            },
            '&:disabled': {
              color: '#B7B7B7',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          height: '48px',
          textTransform: 'initial',
          letterSpacing: '0.25px',
          fontWeight: 500,
          lineHeight: '16px',
          fontSize: 16,
        },
      },
    },
    MuiIconButton: {},
  };
}

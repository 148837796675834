import styled from '@emotion/styled'
import { Button, Skeleton, Stack, Typography } from '@mui/material'
import { theme } from 'theme'

export const StyledCertificationsItem = styled(Stack)`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #959595;
  background: ${theme.palette.white.light};
  padding: 15px 20px;
`

export const StyledViewDetail = styled(Typography)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${theme.palette.primary.main};
`

export const StyledButton = styled(Button)`
  border-radius: 5px;
  background-color: rgba(255, 121, 110, 0.2);
  color: ${theme.palette.primary.main};
  &:hover {
    background-color: rgba(255, 121, 110, 0.3);
  }
`

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 75px;
  border-radius: 5px;
`

export const StyledPageControls = styled(Stack)`
  min-width: 240px;
  position: absolute;
  bottom: 4vh;
  left: 50%;
  transform: translate(-50%);
  background: ${theme.palette.white.light};
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  z-index: 2;
  transition: opacity ease-in-out 0.2s;
  opacity: 0;

  .pdf_view:hover & {
    opacity: 1;
  }
`

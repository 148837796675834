import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, FormHelperText, Stack, Typography } from '@mui/material'
import { InputField } from 'components'
import {
  ButtonBottom,
  ButtonSubmit,
  ForgotLink,
  PageTitle,
} from 'components/common/styles/auth'
import yup from 'config/yup.custom'
import { useAuth } from 'contexts/auth'
import { NotifyService, getDataError } from 'helpers'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import LogoAuth from '../../components/LogoAuth'

interface ILoginForm {
  emailOrNumber: string
  password: string
}

const schema = yup
  .object({
    emailOrNumber: yup.string().required('Email or phone number is required'),
    password: yup.string().required('Password is required'),
  })
  .required()

export const Login = () => {
  const { login } = useAuth()
  const dispatch = useAppDispatch()

  const [incorrect, setIncorrect] = useState(false)

  const { handleSubmit, control, watch } = useForm<ILoginForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const subscription = watch(() => {
      if (incorrect) setIncorrect(false)
    })
    return () => subscription.unsubscribe()
  }, [watch, incorrect])

  const onSubmit = useDebounce(async (data: ILoginForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.parent
      .login({
        emailOrNumber: data.emailOrNumber,
        password: data.password,
      })
      .then(({ data: { data } }) => {
        login(data)
      })
      .catch((e) => {
        const res = getDataError<{ error: string }>(e)
        if (res?.error === 'Unauthorized') {
          setIncorrect(true)
        } else NotifyService.error(e)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)
  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1.625}>
        Parent Login
      </PageTitle>
      <Stack spacing={2}>
        <InputField
          control={control}
          name="emailOrNumber"
          placeholder="Email or phone number"
        />
        <Stack>
          <InputField
            control={control}
            name="password"
            placeholder="Password"
            type="password"
          />
          {incorrect && (
            <FormHelperText error sx={{ textAlign: 'right' }}>
              Incorrect phone or password combination
            </FormHelperText>
          )}
        </Stack>
        <Stack
          mt={2}
          mb={4}
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ForgotLink to={APP_ROUTES.PARENT.AUTH.FORGOT_PASSWORD.to}>
            Forgot Password?
          </ForgotLink>
          <ButtonSubmit color="primary">Login</ButtonSubmit>
        </Stack>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="Web_Label_14" sx={{ opacity: 0.5, mb: 1.25 }}>
          Don’t have an account?
        </Typography>
        <ButtonBottom
          component={NavLink}
          to={APP_ROUTES.PARENT.AUTH.SIGN_UP.to}
        >
          Sign up as Parent
        </ButtonBottom>
      </Stack>
    </Stack>
  )
}

export default Login

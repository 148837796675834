import { Box, Typography, styled } from '@mui/material'

export const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BoxAvatar = styled(Box)`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 8px;
  cursor: pointer;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const EmptyAvatar = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    font-size: 34px;
  }
`

export const Title = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.1px;
  cursor: pointer;
`

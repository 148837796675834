import { SvgIcon, SvgIconProps } from '@mui/material'

export function ImageUploadIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H14C14.4142 2.75 14.75 2.41421 14.75 2C14.75 1.58579 14.4142 1.25 14 1.25H6C3.37665 1.25 1.25 3.37665 1.25 6V18C1.25 20.6234 3.37665 22.75 6 22.75H18C20.6234 22.75 22.75 20.6234 22.75 18V10C22.75 9.58579 22.4142 9.25 22 9.25C21.5858 9.25 21.25 9.58579 21.25 10V13.5091C21.2049 13.473 21.158 13.4387 21.1094 13.4063L18.7285 11.819C17.142 10.7613 15.0295 10.9705 13.6812 12.3188L10.3188 15.6812C8.9705 17.0295 6.85802 17.2387 5.27153 16.181L2.75 14.5V6ZM19 8.75C19.4142 8.75 19.75 8.41421 19.75 8V3.81066L20.4697 4.53033C20.7626 4.82322 21.2374 4.82322 21.5303 4.53033C21.8232 4.23744 21.8232 3.76256 21.5303 3.46967L20.2374 2.17678C19.554 1.49336 18.446 1.49336 17.7626 2.17678L16.4697 3.46967C16.1768 3.76256 16.1768 4.23744 16.4697 4.53033C16.7626 4.82322 17.2374 4.82322 17.5303 4.53033L18.25 3.81066V8C18.25 8.41421 18.5858 8.75 19 8.75ZM8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ImageUploadIcon

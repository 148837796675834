import { API } from 'config'
import { OPTION_ITEM, ResponseType } from 'models'
import { APIInstance } from './configApi'

export const staticDataApi = {
  getEthnicity: () => {
    return APIInstance.get<ResponseType<OPTION_ITEM<string>[]>>(
      API.STATIC_DATA.ETHNICITY,
    )
  },
  getNationality: () => {
    return APIInstance.get<ResponseType<OPTION_ITEM<string>[]>>(
      API.STATIC_DATA.NATIONALITY,
    )
  },
}

import { yupResolver } from '@hookform/resolvers/yup'
import { InputAdornment, Stack, Typography } from '@mui/material'
import { InputField } from 'components'
import {
  ButtonSubmit,
  PageTitle,
  StyledLogoIcon,
} from 'components/common/styles/auth'
import { TOKEN_KEY } from 'config'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { setJWTCookies } from 'utils'
import * as yup from 'yup'
import { ResendBox, ResendText, SentText } from './styles'

interface ICheckCodeForm {
  code: string
}

const RESEND_DURATION = 60 // seconds

const schemaQuery = yup
  .object({
    countryCode: yup.string().required(),
    number: yup.string().notRequired(),
  })
  .required()

const schema = yup
  .object({
    code: yup.string().length(6, 'Incorrect OTP').required('OTP is required'),
  })
  .required()

export const CheckCode = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.global)
  const [isSentOTP, setIsSentOTP] = useState(false)
  const [countDown, setCountDown] = useState(RESEND_DURATION)
  const [resetCountDown, setResetCountDown] = useState(0)

  const queryParams: {
    countryCode?: string
    number?: string
  } = queryString.parse(location.search)

  useEffect(() => {
    const isValidQuery = schemaQuery.isValidSync(queryParams)
    if (!isValidQuery) navigate(APP_ROUTES.NANNY.AUTH.FORGOT_PASSWORD.to)
  }, [navigate, queryParams])

  const { handleSubmit, control } = useForm<ICheckCodeForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setCountDown(RESEND_DURATION)
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre <= 1) {
          clearInterval(interval)
          return 0
        }
        return pre - 1
      })
    }, 1_000)
    return () => clearInterval(interval)
  }, [resetCountDown])

  const onResend = useDebounce(async () => {
    if (countDown || loading) return
    setIsSentOTP(false)
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .forgotPassword({
        countryCode: queryParams.countryCode,
        number: queryParams.number,
      })
      .then(() => {
        setIsSentOTP(true)
        setResetCountDown((pre) => pre + 1)
        NotifyService.success('Success')
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(true)))
  }, 500)

  const onSubmit = useDebounce(async (data: ICheckCodeForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .checkCode({ code: data.code, email: '' })
      .then(({ data: { data } }) => {
        setJWTCookies(
          TOKEN_KEY.NANNY.FORGOT_PASSWORD.ACCESS_TOKEN,
          data.access_token,
        )
        setJWTCookies(
          TOKEN_KEY.NANNY.FORGOT_PASSWORD.REFRESH_TOKEN,
          data.refresh_token,
        )
        NotifyService.success('Success')
        navigate(APP_ROUTES.NANNY.AUTH.RESET_PASSWORD.to)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems="center">
        <StyledLogoIcon />
      </Stack>
      <PageTitle variant="h1" mb={1}>
        Account Verification
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        OTP has been sent to {queryParams.countryCode}
        {queryParams.number}
      </Typography>
      <InputField
        numberOnly
        control={control}
        name="otp"
        placeholder="Enter OTP"
        endAdornment={
          <InputAdornment position="end">
            <ResendBox>
              <ResendText $ready={!countDown && !loading} onClick={onResend}>
                {countDown ? `${countDown}s` : 'Resend'}
              </ResendText>
            </ResendBox>
          </InputAdornment>
        }
      />
      {isSentOTP && <SentText>OTP has been resent.</SentText>}
      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit>Verify 确认</ButtonSubmit>
      </Stack>
    </Stack>
  )
}

export default CheckCode

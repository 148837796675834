import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { type ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'

interface Props {
  children: ReactElement
}

export const NannyRequireAnonymous = ({ children }: Props) => {
  const { isLoggedIn, userType, nanny, authRedirect } = useAuth()
  if (authRedirect.current) {
    if (isLoggedIn) {
      if (userType !== USER_TYPE.NANNY) {
        return <Navigate replace to={APP_ROUTES.PARENT.BOOKING.to} />
      }
      if (nanny?.status === NANNY_STATUS.VERIFIED) {
        return <Navigate replace to={APP_ROUTES.NANNY.COMPLETE_PROFILE.to} />
      }
      if (nanny?.status === NANNY_STATUS.PENDING_APPROVAL) {
        return <Navigate replace to={APP_ROUTES.NANNY.PROFILE.to} />
      }
      return <Navigate replace to={APP_ROUTES.NANNY.BOOKING.to} />
    }
  }

  return <>{children ? children : <Outlet />}</>
}

export default NannyRequireAnonymous

import { Divider, Stack, Typography } from '@mui/material'
import { formatDate, printPrice } from 'helpers'
import { BOOKING_ACTIVITY, activityLogTypeName } from 'models'
import { Fragment, memo } from 'react'
import { StyledActivityLogPanel } from './styles'

interface ActivityLogPanelProps {
  activityList?: BOOKING_ACTIVITY[]
}

const ActivityLogPanel = memo(({ activityList }: ActivityLogPanelProps) => {
  return (
    <StyledActivityLogPanel spacing={2}>
      <Stack>
        <Typography
          sx={{
            opacity: 0.5,
          }}
          variant="Web_Title_14"
        >
          Activity Log
        </Typography>
      </Stack>

      {activityList?.map((item, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && <Divider />}
          <Stack spacing={{ xs: 1, md: 0.5 }}>
            <Typography variant="Web_Label_16">
              {activityLogTypeName[item.type]}
            </Typography>
            {(item.amount ?? null) !== null && (
              <Typography variant="Web_Label_14">
                {printPrice(item.amount)}
              </Typography>
            )}
            {!!item.description && (
              <Typography variant="Web_Label_14">{item.description}</Typography>
            )}
            <Typography variant="Web_paragraph">
              {formatDate(item.updatedAt, { format: 'DD/MM/YYYY HH:mm' })}
            </Typography>
          </Stack>
        </Fragment>
      ))}
    </StyledActivityLogPanel>
  )
})

export default ActivityLogPanel

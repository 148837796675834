import { Stack, Typography } from '@mui/material'
import { ArrowLeftIcon } from 'assets'
import { StatusPanelMobile } from 'components'
import ActivityLogPanel from 'components/common/ActivityLogPanel'
import BookingDetailPanel from 'components/common/BookingDetailPanel'
import StatusPanel, { StatusPanelStep } from 'components/common/StatusPanel'
import { NotifyService } from 'helpers'
import { BOOKING_NANNY_STATUS, NANNY_BOOKING_DETAIL, USER_TYPE } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { bookingApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { theme } from 'theme'

function DetailBooking() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [booking, setBooking] = useState<NANNY_BOOKING_DETAIL>()

  const listStep = useMemo(() => {
    const steps: StatusPanelStep[] = [
      {
        label: 'Confirmed',
        active: [
          BOOKING_NANNY_STATUS.CONFIRMED,
          BOOKING_NANNY_STATUS.COMMENCED,
          BOOKING_NANNY_STATUS.COMPLETED,
          BOOKING_NANNY_STATUS.CANCELLED,
          BOOKING_NANNY_STATUS.DELETED,
        ].includes(booking?.status),
        activeLabel: [
          BOOKING_NANNY_STATUS.CONFIRMED,
          BOOKING_NANNY_STATUS.COMMENCED,
          BOOKING_NANNY_STATUS.COMPLETED,
          BOOKING_NANNY_STATUS.CANCELLED,
          BOOKING_NANNY_STATUS.DELETED,
        ].includes(booking?.status),
      },
      {
        label: 'Commenced',
        active: [
          BOOKING_NANNY_STATUS.COMMENCED,
          BOOKING_NANNY_STATUS.COMPLETED,
          BOOKING_NANNY_STATUS.CANCELLED,
          BOOKING_NANNY_STATUS.DELETED,
        ].includes(booking?.status),
        activeLabel: [
          BOOKING_NANNY_STATUS.COMMENCED,
          BOOKING_NANNY_STATUS.COMPLETED,
        ].includes(booking?.status),
      },
    ]
    if (BOOKING_NANNY_STATUS.CANCELLED === booking?.status) {
      steps.push({
        label: 'Cancelled',
        active: true,
        activeLabel: true,
      })
    } else if (BOOKING_NANNY_STATUS.DELETED === booking?.status) {
      steps.push({
        label: 'Deleted',
        active: true,
        activeLabel: true,
      })
    } else {
      steps.push({
        label: 'Completed',
        active: BOOKING_NANNY_STATUS.COMPLETED === booking?.status,
        activeLabel: BOOKING_NANNY_STATUS.COMPLETED === booking?.status,
      })
    }
    return steps
  }, [booking])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      await bookingApi.nanny
        .getDetail(id)
        .then(({ data: { data } }) => setBooking(data))
        .catch((e) => {
          NotifyService.error(e)
          navigate(APP_ROUTES.NANNY.BOOKING.to)
        })
        .finally(() => dispatch(setGlobalLoading(false)))
    }
    fetchData()
  }, [id, dispatch, navigate])

  useEffect(() => {
    console.log(booking, '====booking===')
  }, [booking])

  return (
    <Stack spacing={2}>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          component={NavLink}
          to={APP_ROUTES.NANNY.BOOKING.to}
          sx={{ textDecoration: 'none', color: theme.palette.black.dark }}
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <ArrowLeftIcon />
          <Typography lineHeight="21px" variant="Web_Label_18">
            Back to Bookings
          </Typography>
        </Stack>
      </Stack>
      {!!booking && (
        <>
          <Stack display={{ xs: 'none', md: 'flex' }}>
            <StatusPanel
              listStep={listStep}
              bookingId={id}
              userType={USER_TYPE.NANNY}
            />
          </Stack>
          <Stack display={{ xs: 'flex', md: 'none' }}>
            <StatusPanelMobile
              bookingId={id}
              steps={listStep}
              userType={USER_TYPE.NANNY}
            />
          </Stack>
          <Stack>
            <BookingDetailPanel userType={USER_TYPE.NANNY} item={booking} />
          </Stack>
          <Stack>
            <ActivityLogPanel activityList={booking.activityList} />
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default DetailBooking

import { SvgIcon, SvgIconProps } from '@mui/material';

function MenuMobileIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" {...props}>
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M0 1.25h24M0 8.25h24M0 15.25h24"
      />
    </SvgIcon>
  );
}

export default MenuMobileIcon;

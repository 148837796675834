import { SvgIcon, SvgIconProps } from '@mui/material'

export function CheckCircleBold(props: SvgIconProps) {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.7987 1.83887C6.28073 1.83887 2.59912 5.52047 2.59912 10.0384C2.59912 14.5564 6.28073 18.238 10.7987 18.238C15.3166 18.238 18.9983 14.5564 18.9983 10.0384C18.9983 5.52047 15.3166 1.83887 10.7987 1.83887ZM14.7181 8.15253L10.0689 12.8017C9.95413 12.9165 9.79834 12.9821 9.63435 12.9821C9.47036 12.9821 9.31456 12.9165 9.19977 12.8017L6.87929 10.4812C6.64151 10.2434 6.64151 9.84984 6.87929 9.61205C7.11708 9.37427 7.51066 9.37427 7.74845 9.61205L9.63435 11.498L13.8489 7.28338C14.0867 7.04559 14.4803 7.04559 14.7181 7.28338C14.9559 7.52117 14.9559 7.90655 14.7181 8.15253Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CheckCircleBold

import { Box, Typography, css, styled } from '@mui/material'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface ResendTextProp {
  $ready?: boolean
}

export const ResendBox = styled(Box)`
  height: 24px;
  min-width: 35px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  border-left: 1px solid ${theme.palette.seperators.opaque};
`

export const ResendText = styled(Typography, transientOptions)<ResendTextProp>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.1px;
  color: #b7b7b7;
  ${({ $ready }) =>
    $ready &&
    css`
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: 0.1px;
      color: ${theme.palette.primary.main};
    `}
`

export const SentText = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.05px;
  margin-top: 4px;
`

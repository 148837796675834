import { yupResolver } from '@hookform/resolvers/yup'
import { InputAdornment, Stack, Typography } from '@mui/material'
import { InputField } from 'components'
import { ButtonSubmit, PageTitle } from 'components/common/styles/auth'
import { TOKEN_KEY } from 'config'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import queryString from 'query-string'
import { memo, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { getCookies, removeCookies } from 'utils'
import * as yup from 'yup'
import LogoAuth from '../../components/LogoAuth'
import { ResendBox, ResendText, SentText } from './styles'

interface IPhoneVerifyForm {
  otp: string
}

const RESEND_DURATION = 60 // seconds

const schemaQuery = yup
  .object({
    accessToken: yup.string().required(),
    refreshToken: yup.string().notRequired(),
    phone: yup.string().required(),
  })
  .required()

const schema = yup
  .object({
    otp: yup.string().length(6, 'Incorrect OTP').required('OTP is required'),
  })
  .required()

interface PhoneVerifyProps {}

export const PhoneVerify = memo((_: PhoneVerifyProps) => {
  const { login } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams: {
    phone?: string
  } = queryString.parse(location.search)

  const registerData = useMemo(() => {
    const accessToken = getCookies(TOKEN_KEY.NANNY.REGISTER.ACCESS_TOKEN)
    const refreshToken = getCookies(TOKEN_KEY.NANNY.REGISTER.REFRESH_TOKEN)
    return {
      phone: queryParams.phone,
      accessToken: accessToken,
      refreshToken: refreshToken,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParams)])

  const { loading } = useAppSelector((state) => state.global)
  const [isSentOTP, setIsSentOTP] = useState(false)
  const [countDown, setCountDown] = useState(RESEND_DURATION)
  const [resetCountDown, setResetCountDown] = useState(0)

  const { handleSubmit, control } = useForm<IPhoneVerifyForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const isValidQuery = schemaQuery.isValidSync(registerData)
    if (!isValidQuery) navigate(APP_ROUTES.NANNY.AUTH.REGISTER.to)
  }, [navigate, registerData])

  useEffect(() => {
    setCountDown(RESEND_DURATION)
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre <= 1) {
          clearInterval(interval)
          return 0
        }
        return pre - 1
      })
    }, 1_000)
    return () => clearInterval(interval)
  }, [resetCountDown])

  const onResend = useDebounce(async () => {
    if (countDown || loading) return
    setIsSentOTP(false)
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .resendOTP(registerData.accessToken)
      .then(() => {
        setIsSentOTP(true)
        setResetCountDown((pre) => pre + 1)
        NotifyService.success('Success')
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  const onSubmit = useDebounce(async (data: IPhoneVerifyForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .verifyPhone(data.otp, registerData.accessToken)
      .then(({ data: { data } }) => {
        removeCookies(TOKEN_KEY.NANNY.REGISTER.ACCESS_TOKEN)
        removeCookies(TOKEN_KEY.NANNY.REGISTER.REFRESH_TOKEN)
        NotifyService.success('Success')
        login(data, () => {
          navigate(APP_ROUTES.NANNY.AUTH.COMPLETED.to)
        })
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Phone Verification 电话验证
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        OTP has been sent to {queryParams.phone}
      </Typography>
      <InputField
        numberOnly
        control={control}
        name="otp"
        placeholder="Enter OTP"
        endAdornment={
          <InputAdornment position="end">
            <ResendBox>
              <ResendText $ready={!countDown && !loading} onClick={onResend}>
                {countDown ? `${countDown}s` : 'Resend'}
              </ResendText>
            </ResendBox>
          </InputAdornment>
        }
      />
      {isSentOTP && <SentText>OTP has been resent.</SentText>}
      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit>Verify 确认</ButtonSubmit>
      </Stack>
    </Stack>
  )
})

export default PhoneVerify

import { TOKEN_KEY } from 'config'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'

export const cookies = new Cookies()

export const getCookies = (key: string): string => {
  return cookies.get(key)
}

export const setCookies = (key: string, value: any, exp?: number) => {
  const timeNow = new Date().getTime()
  const shortExp = timeNow + 1 * 24 * 3600 * 1000 // 1 day
  const expires = exp ? new Date(exp * 1000) : new Date(shortExp)
  cookies.set(key, value, {
    path: '/',
    expires,
  })
}

export const removeCookies = (key: string) => {
  cookies.remove(key, { path: '/' })
}

const getTokenExpires = (token: string) => {
  const { exp } = jwtDecode<{ exp: number }>(token)
  const timeNow = new Date().getTime()
  const shortExp = timeNow + 5 * 3600 * 1000 // 5 hours

  const expires = exp ? new Date(exp * 1000) : new Date(shortExp)
  return expires
}

export const setJWTCookies = (key: string, token: string) => {
  cookies.set(key, token, {
    path: '/',
    expires: getTokenExpires(token),
  })
}

export const setTokenCookies = (token: string) => {
  cookies.set(TOKEN_KEY.ACCESS_TOKEN, token, {
    path: '/',
    expires: getTokenExpires(token),
  })
}

export const setRefreshTokenCookies = (token: string) => {
  const timeNow = new Date().getTime()
  const shortExp = timeNow + 90 * 24 * 3600 * 1000 // 90 days
  cookies.set(TOKEN_KEY.REFRESH_TOKEN, token, {
    path: '/',
    expires: new Date(shortExp),
  })
}

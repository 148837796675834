import { Stack, Typography } from '@mui/material'
import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { useMemo } from 'react'
import { theme } from 'theme'
import { menuRouteNanny, menuRouteParent } from '../constants'
import { StyledNavLink, StyledNavLinkDisable } from './styles'

function MenuLeft() {
  const { userType, nanny } = useAuth()
  const arrRouter = useMemo(
    () => (userType === USER_TYPE.PARENT ? menuRouteParent : menuRouteNanny),
    [userType],
  )

  const isAccountPending =
    userType === USER_TYPE.NANNY &&
    [NANNY_STATUS.PENDING_APPROVAL, NANNY_STATUS.VERIFIED].includes(
      nanny?.status,
    )

  return (
    <Stack mt={1.5} spacing={3} width="100%">
      {arrRouter.map((items, index) => {
        return (
          <Stack spacing={1} key={index}>
            <Typography
              color={theme.palette.grey1.dark}
              textTransform="uppercase"
              variant="Title_Menu_14"
            >
              {items.title}
            </Typography>
            <Stack spacing={1}>
              {items.items.map((router, index) => {
                const isDisabled =
                  isAccountPending &&
                  ['bookings', 'earnings', 'photos', 'testimonials'].includes(
                    router.name,
                  )

                return isDisabled ? (
                  <StyledNavLinkDisable key={index} variant="Web_Label_16">
                    {router.label}
                  </StyledNavLinkDisable>
                ) : (
                  <StyledNavLink
                    $typeUser={userType}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    key={index}
                    to={router.path}
                  >
                    <Typography variant="Web_Label_16">
                      {router.label}
                    </Typography>
                  </StyledNavLink>
                )
              })}
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  )
}

export default MenuLeft

import authNannylayout from './auth/nanny-layout.svg'
import authParentlayout from './auth/parent-layout.svg'
import bgEmpty from './bg/bg-empty.svg'
import directionDown from './icons/direction-down.svg'
import nannyDefaultAvatar from './nanny-default-avatar.png'
import imgNannyDemo from './nanny-photo-demo.png'
import parentDefaultAvatar from './parent-default-avatar.png'

export const APP_IMG = {
  nannyDefaultAvatar,
  parentDefaultAvatar,
  authNannylayout,
  authParentlayout,
  imgNannyDemo,
  icons: {
    directionDown,
  },
  bg: {
    bgEmpty,
  },
}

export default APP_IMG

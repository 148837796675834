import { GET_USER_PROFILE_PARAMS, PayloadActionData } from 'models'

export const TEMP_REQUEST = 'TEMP_REQUEST'

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST'

export const getUserProfileAction = (
  payload: PayloadActionData<GET_USER_PROFILE_PARAMS>,
) => ({
  type: GET_USER_PROFILE_REQUEST,
  payload,
})

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { NotifyService } from 'helpers'
import { DATA_PAGINATION } from 'models'
import { NANNY_CERTIFICATION_ASSOCIATION } from 'models/nanny/certification'
import { memo, useEffect, useMemo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { certificationApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { theme } from 'theme'
import Lightbox, { CustomSlide, Slide } from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import CertificationsItem from './CertificationsItem'
import { StyledButton, StyledPageControls, StyledSkeleton } from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
  verbosity: 0,
}

const mockItems = Array.from(Array(4).keys())

interface SectionCertificationsProps {
  nannyId: string
}

const SectionCertifications = memo(
  ({ nannyId }: SectionCertificationsProps) => {
    const dispatch = useAppDispatch()
    const [listCertification, setListCertification] =
      useState<DATA_PAGINATION<NANNY_CERTIFICATION_ASSOCIATION>>()
    const [isOpenLightBox, setIsOpenLightBox] = useState(false)
    const [fileIndex, setFileIndex] = useState(0)
    const [numPages, setNumPages] = useState<number>(null)
    const [pageNumber, setPageNumber] = useState<number>(1)

    const isPDFFile = (file: string) => file && !!file.includes('.pdf')
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const slides: Slide[] = useMemo(
      () =>
        listCertification?.data.map((img) => ({
          type: (isPDFFile(img.documentUrl) && 'custom-slide') || 'image',
          src: img.documentUrl,
          description: img.documentDescription,
        })) || [],
      [listCertification],
    )

    const isCustomSlide = (slide: Slide): slide is CustomSlide => {
      return slide.type === 'custom-slide'
    }

    const onDocumentLoadSuccess = ({
      numPages,
    }: {
      numPages: number
    }): void => {
      setNumPages(numPages)
    }

    const onShowPreviewFile = (idx: number) => {
      setIsOpenLightBox(true)
      setFileIndex(idx)
    }

    const isHasMore = useMemo(
      () =>
        listCertification &&
        listCertification.pageNum < listCertification.totalPages,
      [listCertification],
    )

    const getCertifications = async (pageNum: number = 1, pageSize = 8) => {
      const data: DATA_PAGINATION<NANNY_CERTIFICATION_ASSOCIATION> =
        await certificationApi.nanny
          .getAllCertificationAssociation({ pageNum, pageSize, nannyId })
          .then(({ data }) => data)
      return data
    }

    const fetchInit = async () => {
      await getCertifications()
        .then((data) => setListCertification(data))
        .catch(() => {})
    }

    const onLoadMore = async () => {
      if (!isHasMore) return
      dispatch(setGlobalLoading(true))
      await getCertifications(listCertification.pageNum + 1)
        .then((data) => {
          setListCertification((pre) => ({
            ...data,
            data: [...pre.data, ...data.data],
          }))
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => dispatch(setGlobalLoading(false)))
    }

    const changePage = (offSet: number) => {
      setPageNumber((prevPageNumber) => prevPageNumber + offSet)
    }

    const changePageBack = () => {
      changePage(-1)
    }

    const changePageNext = () => {
      changePage(+1)
    }

    useEffect(() => {
      setPageNumber(1)
    }, [fileIndex])

    useEffect(() => {
      fetchInit()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nannyId])

    return (
      <Stack component="section" spacing={1}>
        <Typography variant="Web_Title_22">Certifications</Typography>
        <Stack>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {listCertification
              ? listCertification.data.map((item, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <CertificationsItem
                      onClick={onShowPreviewFile}
                      item={item}
                      idx={index}
                    />
                  </Grid>
                ))
              : mockItems.map((_, idx) => (
                  <Grid key={idx} item xs={12} md={6}>
                    <StyledSkeleton variant="rectangular" />
                  </Grid>
                ))}
            {isHasMore && (
              <Grid item xs={12}>
                <StyledButton
                  onClick={onLoadMore}
                  variant="contained"
                  fullWidth
                >
                  Load More Certifications
                </StyledButton>
              </Grid>
            )}
          </Grid>

          <Lightbox
            open={isOpenLightBox}
            close={() => setIsOpenLightBox(false)}
            slides={slides}
            plugins={[Captions]}
            index={fileIndex}
            render={{
              slide: ({ slide }) =>
                isCustomSlide(slide) ? (
                  <Box className="pdf_view" position="relative">
                    <Document
                      file={{
                        url: slide.src,
                      }}
                      options={options}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        pageNumber={pageNumber}
                        width={isMobile ? window.innerWidth : undefined}
                        height={
                          !isMobile ? window.innerHeight * 0.9 : undefined
                        }
                      />
                    </Document>
                    <Stack>
                      <StyledPageControls direction="row" alignItems="center">
                        <Button
                          onClick={changePageBack}
                          disabled={pageNumber <= 1}
                          sx={{ color: theme.palette.black.dark }}
                        >
                          Previous
                        </Button>
                        <Typography>
                          {pageNumber} of {numPages}
                        </Typography>
                        <Button
                          onClick={changePageNext}
                          disabled={pageNumber >= numPages}
                          sx={{ color: theme.palette.black.dark }}
                        >
                          Next
                        </Button>
                      </StyledPageControls>
                    </Stack>
                  </Box>
                ) : undefined,
            }}
          />
        </Stack>
      </Stack>
    )
  },
)

export default SectionCertifications

import { Stack, Typography } from '@mui/material'
import { BoldHeartIcon } from 'assets'
import { NANNY_CERTIFICATION_ASSOCIATION } from 'models'
import { memo } from 'react'
import { StyledCertificationsItem, StyledViewDetail } from '../styles'

interface CertificationsItemProps {
  item: NANNY_CERTIFICATION_ASSOCIATION
  idx: number
  onClick: (idx: number) => void
}

const CertificationsItem = memo(
  ({ item, onClick, idx }: CertificationsItemProps) => {
    return (
      <StyledCertificationsItem direction="row" spacing={1}>
        <BoldHeartIcon color="primary" />
        <Stack pt={0.5}>
          <Typography variant="Web_Label_16">{item.title}</Typography>
          <StyledViewDetail onClick={() => onClick(idx)}>
            View Details
          </StyledViewDetail>
        </Stack>
      </StyledCertificationsItem>
    )
  },
)

export default CertificationsItem

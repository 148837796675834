import { Grid } from '@mui/material'
import { APP_IMG } from 'assets'
import { Outlet } from 'react-router-dom'
import { Content, RightBox } from './styles'

interface Props {
  children?: React.ReactNode
}

export const NannyAuthLayout = ({ children }: Props) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        md={6}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Content>{children ? children : <Outlet />}</Content>
      </Grid>
      <RightBox
        item
        md={6}
        sx={{
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <img src={APP_IMG.authNannylayout} alt="layout" />
      </RightBox>
    </Grid>
  )
}

export default NannyAuthLayout

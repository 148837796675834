import { Box, Stack, Typography } from '@mui/material'
import EmptyUI from 'components/common/EmptyUI'
import { NANNY_BOOKING_SUMMARY } from 'models'
import { useEffect, useState } from 'react'
import { bookingApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import BookingItem from '../../components/BookingItem'

function ListBooking() {
  const dispatch = useAppDispatch()
  const [bookings, setBookings] = useState<NANNY_BOOKING_SUMMARY[]>()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      await bookingApi.nanny
        .getSummary()
        .then(({ data: { data } }) => setBookings(data))
        .catch(() => {})
        .finally(() => dispatch(setGlobalLoading(false)))
    }
    fetchData()
  }, [dispatch])

  return (
    <Box>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Bookings</Typography>
      </Stack>
      <Stack spacing={2}>
        {!!bookings?.length ? (
          bookings.map((booking) => (
            <BookingItem key={booking.bookingId} item={booking} />
          ))
        ) : (
          <EmptyUI content={`You don’t have any bookings yet.`} />
        )}
      </Stack>
    </Box>
  )
}

export default ListBooking

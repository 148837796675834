import styled from '@emotion/styled'
import { FormControlLabel, Typography } from '@mui/material'
import { theme } from 'theme'

export const FormControlLabelStyled = styled(FormControlLabel)`
  align-items: flex-start;
`
export const TextCheckBoxStyled = styled(Typography)`
  color: ${theme.palette.text.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.1px;
  margin-top: 9px;
`

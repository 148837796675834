import styled from '@emotion/styled'
import { Grid, IconButton, Typography } from '@mui/material'
import { theme } from 'theme'

export const StyledImageItem = styled(Grid)`
  position: relative;
  .box_image {
    cursor: pointer;
    display: flex;
    position: relative;
    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background:
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
        50% / cover no-repeat;
    }
  }
  & .image_style {
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
  }
`

export const StyledCaptionImg = styled(Typography)`
  margin-top: 12px;
  display: block;
  color: ${theme.palette.grey1.light};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`

export const StyledButtonRemove = styled(IconButton)`
  position: absolute;
  right: 0;
  z-index: 10;
`

import { Box, Grid, Stack, Typography } from '@mui/material'
import { APP_IMG } from 'assets'
import {
  BOOKING_AVAILABLE_NANNY,
  BOOKING_PARENT_STATUS,
  NANNY_IN_BOOKING_STATUS,
  PARENT_BOOKING_DETAIL,
} from 'models'
import { Fragment, memo } from 'react'
import NannyItem from './NannyItem'
import NannyTestimonial from './NannyTestimonial'
import { StyledNannyPanel } from './styles'

interface NannyPanelProps {
  booking: PARENT_BOOKING_DETAIL
  handleTestimonial: (nanny: BOOKING_AVAILABLE_NANNY) => void
}

const NannyPanel = memo(({ booking, handleTestimonial }: NannyPanelProps) => {
  const checkIsSelected = () =>
    [
      BOOKING_PARENT_STATUS.NANNY_CONFIRMED,
      BOOKING_PARENT_STATUS.DEPOSIT_PAID,
      BOOKING_PARENT_STATUS.COMPLETED,
    ].includes(booking.status)

  const renderContent = () => {
    if (checkIsSelected()) {
      const nanny = booking.availableNannies?.find(
        (t) => t.status === NANNY_IN_BOOKING_STATUS.ASSIGNED,
      )
      if (!nanny) return ''
      return (
        <Fragment>
          <Grid item xs={12} md={6}>
            <NannyItem bookingId={booking.bookingId} nanny={nanny} />
          </Grid>
          {booking.status === BOOKING_PARENT_STATUS.COMPLETED && (
            <Grid item xs={12} md={6}>
              <NannyTestimonial
                nanny={nanny}
                onClick={() => handleTestimonial(nanny)}
              />
            </Grid>
          )}
        </Fragment>
      )
    } else if (booking.status === BOOKING_PARENT_STATUS.NANNIES_MATCHED) {
      return (
        <Fragment>
          {booking.availableNannies.map((nanny) => (
            <Grid key={nanny.id} item xs={12} md={6}>
              <NannyItem bookingId={booking.bookingId} nanny={nanny} />
            </Grid>
          ))}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Grid item xs={12} pb={5}>
          <Stack
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <img src={APP_IMG.bg.bgEmpty} alt="bg_empty" />
            <Typography
              position="absolute"
              bottom={10}
              sx={{ opacity: 0.5 }}
              width="326px"
              textAlign="center"
              variant="Web_Label_14"
            >
              We will <b>pair you with compatible Nannies</b>, check back again
              to view the nannies.
            </Typography>
          </Stack>
        </Grid>
      </Fragment>
    )
  }

  return (
    <StyledNannyPanel spacing={2}>
      <Stack>
        <Typography
          sx={{
            opacity: 0.5,
          }}
          variant="Web_Title_14"
        >
          {checkIsSelected() ? 'Nanny Selected' : 'Nannies Matched'}
        </Typography>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </Box>
    </StyledNannyPanel>
  )
})

export default NannyPanel

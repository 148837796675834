import styled from '@emotion/styled'
import { Box, Dialog } from '@mui/material'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface ModalBoxStyledProps {
  $widthPopup: string
}

export const ModalBoxStyled = styled(
  Box,
  transientOptions,
)<ModalBoxStyledProps>`
  padding: 37px 30px 34px 30px;
  ${theme.breakpoints.up('md')} {
    width: ${({ $widthPopup }) => $widthPopup};
    padding: 37px 60px 34px 50px;
  }
`

export const DialogStyled = styled(Dialog)``

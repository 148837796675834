import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import { APP_IMG, ArrowLeftIcon } from 'assets'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { NANNY_PROFILE, USER_TYPE } from 'models'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { profileApi } from 'services'
import Header from '../Header'
import SectionAboutMe from './components/SectionAboutMe'
import SectionCertifications from './components/SectionCertifications'
import SectionGallery from './components/SectionGallery'
import SectionTestimonials from './components/SectionTestimonials'
import {
  StyledBoxProfile,
  StyledContainer,
  StyledLink,
  StyledTypography,
  TopBox,
} from './styles'

function NannyDetail() {
  const { id } = useParams()
  const { userType } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [profile, setProfile] = useState<NANNY_PROFILE>()
  const {
    bookingId,
  }: {
    bookingId?: string
  } = queryString.parse(location.search)

  const onBack = () => {
    if (userType === USER_TYPE.PARENT) {
      if (bookingId) {
        navigate(APP_ROUTES.PARENT.BOOKING.DETAIL.to(bookingId))
      } else {
        navigate(APP_ROUTES.PARENT.BOOKING.to)
      }
    } else {
      navigate(APP_ROUTES.NANNY.PROFILE.to)
    }
  }

  const getProfile = async () => {
    await profileApi.nanny
      .getProfile(id)
      .then(({ data: { data } }) => setProfile(data))
      .catch((e) => {
        NotifyService.error(e)
        onBack()
      })
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Box>
      <Header />
      <main>
        <TopBox />
        <StyledContainer>
          <StyledBoxProfile
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'center', md: 'start' }}
            alignItems="center"
            spacing={{ xs: 1, md: 1.5 }}
          >
            <StyledLink
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={onBack}
            >
              <ArrowLeftIcon />
              <Typography lineHeight="21px" variant="Web_Label_18">
                {userType === USER_TYPE.PARENT
                  ? 'Back to Browse Nannies'
                  : 'Back to Edit Profile'}
              </Typography>
            </StyledLink>
            <Avatar
              alt="avatar_nanny"
              src={profile?.profilePicture || APP_IMG.nannyDefaultAvatar}
              sx={{ width: { xs: 90, md: 100 }, height: { xs: 90, md: 100 } }}
            />
            <Stack spacing={1}>
              <Typography
                variant="Web_Title_22"
                textAlign={{ xs: 'center', md: 'start' }}
              >
                {profile?.firstName}
              </Typography>
              <Stack
                direction="row"
                spacing={{ xs: 1, md: 2 }}
                sx={{ opacity: 0.5 }}
              >
                <StyledTypography
                  display={{ xs: 'none', md: 'block' }}
                  variant="Web_Label_18"
                >
                  {profile?.age} years old
                </StyledTypography>
                <StyledTypography
                  display={{ xs: 'block', md: 'none' }}
                  variant="Web_Label_18"
                >
                  {profile?.age}
                </StyledTypography>
                <Divider orientation="vertical" flexItem />
                <StyledTypography variant="Web_Label_18">
                  {profile?.ethnicity}
                </StyledTypography>
                <Divider orientation="vertical" flexItem />
                <StyledTypography variant="Web_Label_18">
                  {profile?.nationality}
                </StyledTypography>
                <Divider orientation="vertical" flexItem />
                <StyledTypography variant="Web_Label_18">
                  {profile?.yearsOfExperience} years of experience
                </StyledTypography>
              </Stack>
            </Stack>
          </StyledBoxProfile>
          <Stack pt={4} spacing={4}>
            {profile?.bio && <SectionAboutMe profile={profile} />}
            <SectionCertifications nannyId={id} />
            <SectionGallery nannyId={id} />
            <SectionTestimonials nannyId={id} />
          </Stack>
        </StyledContainer>
      </main>
    </Box>
  )
}

export default NannyDetail

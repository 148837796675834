import { SvgIcon, SvgIconProps } from '@mui/material';

function CloseDialogIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21" {...props}>
      <path
        stroke="currentColor"
        d="M0-.5h30.372"
        transform="matrix(.75258 .6585 -.36245 .932 1 1)"
      />
      <path
        stroke="currentColor"
        d="M0-.5h30.372"
        transform="matrix(.75258 -.6585 .36245 .932 1 21)"
      />
    </SvgIcon>
  );
}

export default CloseDialogIcon;

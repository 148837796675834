import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  RatingProps,
} from '@mui/material'
import { StarIcon } from 'assets'
import { Control, FieldError, useController } from 'react-hook-form'
import { StyledRating } from './styles'

interface RatingFieldProps extends Omit<RatingProps, 'control'> {
  name?: string
  control?: Control<any>
  isRequired?: boolean
  hideHelper?: boolean
  errorMess?: string
  helperTextProps?: FormHelperTextProps
  getErrorMess?: (error: FieldError, value: string) => string
}

function RatingField({
  name,
  control,
  onChange: externalOnChange,
  ref: externalRef,
  value: externalValue,
  hideHelper,
  getErrorMess,
  errorMess,
  helperTextProps,
  ...rest
}: RatingFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = control
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useController({ name, control })
    : {
        field: {
          onChange: externalOnChange as (...event: any[]) => void,
          value: externalValue,
          onBlur: undefined,
          ref: undefined,
        },
        fieldState: { error: undefined },
      }
  return (
    <FormControl error={!!error}>
      <StyledRating
        onBlur={onBlur}
        name="read-only"
        value={Number(value)}
        onChange={onChange}
        icon={<StarIcon sx={{ color: '#FFCC00' }} />}
        emptyIcon={<StarIcon sx={{ color: '#E5E5EA' }} />}
        {...ref}
      />
      {!hideHelper && (error || errorMess) && (
        <FormHelperText error={!!error || !!errorMess} {...helperTextProps}>
          {getErrorMess
            ? getErrorMess(error, value)
            : error?.message || errorMess}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default RatingField

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import {
  DatePickerField,
  InputField,
  SelectField,
  ToggleButtonField,
} from 'components'
import ModalUI from 'components/common/ModalUI'
import CheckboxField from 'components/form-control/CheckboxField'
import yup from 'config/yup.custom'
import { useAuth } from 'contexts/auth'
import { NotifyService, formatDateParam } from 'helpers'
import { useDebounce } from 'hooks'
import {
  OPTION_ITEM,
  houseTypeOptions,
  singleTwinPregnancyOptions,
} from 'models'
import moment from 'moment-timezone'
import { memo } from 'react'
import { useForm } from 'react-hook-form'
import { bookingApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { theme } from 'theme'
import { TextCheckBoxStyled } from './styles'

interface PopupCreateBookingProps {
  onClose: () => void
  onSuccess: (id: string) => void
}

interface CreateBookingForm {
  edd: moment.Moment
  singleTwinPregnancy: OPTION_ITEM<string>
  houseType: OPTION_ITEM<string>
  pets: boolean
  duration: number
  otherRequirements: string
  acceptTerms: boolean
}

const schema = yup
  .object({
    edd: yup.mixed().required('Please fill in this field'),
    singleTwinPregnancy: yup.mixed().required('Please fill in this field'),
    houseType: yup.mixed().required('Please fill in this field'),
    pets: yup
      .boolean()
      .required('Please fill in this field')
      .nullable(false)
      .typeError('Please fill in this field'),
    duration: yup
      .number()
      .required('Please fill in this field')
      .nullable(false)
      .typeError('Please fill in this field'),
    otherRequirements: yup.string().notRequired(),
    acceptTerms: yup
      .boolean()
      .oneOf([true], 'Please accept the terms')
      .required('Please accept the terms'),
  })
  .required()

const PopupCreateBooking = memo(
  ({ onClose, onSuccess }: PopupCreateBookingProps) => {
    const { parent } = useAuth()
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector((state) => state.global)
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<CreateBookingForm>({
      defaultValues: {
        pets: false,
        duration: 28,
        acceptTerms: false,
      },
      mode: 'onChange',
      resolver: yupResolver(schema),
    })

    const onSubmit = useDebounce(async (data: CreateBookingForm) => {
      if (loading || !parent) return
      dispatch(setGlobalLoading(true))
      await bookingApi.parent
        .create({
          parentId: parent.parent_id,
          edd: formatDateParam(data.edd),
          singleTwinPregnancy: data.singleTwinPregnancy?.id,
          houseType: data.houseType?.id,
          pets: data.pets ?? undefined,
          duration: data.duration ?? undefined,
          otherRequirements: data.otherRequirements,
        })
        .then(({ data: { data } }) => {
          onSuccess(data.bookingId)
          NotifyService.success('Success')
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => dispatch(setGlobalLoading(false)))
    }, 500)

    return (
      <ModalUI open onClose={onClose} title="New Booking">
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid mb={2} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                control={control}
                iconColor="primary"
                name="edd"
                label="Expected Due Date"
                placeholder="When is your EDD?"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name="singleTwinPregnancy"
                label="Single/Twin Pregnancy"
                placeholder="Single/Twin Pregnancy"
                options={singleTwinPregnancyOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectField
                control={control}
                name="houseType"
                label="Single/Multi-storey?"
                placeholder="Select one"
                options={houseTypeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ToggleButtonField
                name="pets"
                control={control}
                label="Do you have Pets?"
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonField
                name="duration"
                control={control}
                label="How long do you need the nanny?"
                options={[
                  { label: '28 days', value: 28 },
                  { label: '56 days', value: 56 },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                rows={3}
                multiline
                control={control}
                name="otherRequirements"
                label="Other Requirements"
                placeholder="Other Requirements"
              />
            </Grid>
          </Grid>
          <Divider />
          <Box mt={2}>
            <CheckboxField
              hideHelper
              alignItems="flex-start"
              name="acceptTerms"
              control={control}
              label={
                <TextCheckBoxStyled>
                  I acknowledge that I have read and understood the{' '}
                  <Typography
                    component="span"
                    color={theme.palette.primary.main}
                  >
                    Data Protection Notice
                  </Typography>
                  , and consent to the collection, use and disclosure of my
                  personal data by Aunty SG for the purposes set out in the
                  Notice.
                </TextCheckBoxStyled>
              }
            />
          </Box>
          <Stack
            mt={{ md: 4 }}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Box ml={4} mb={{ xs: 2 }}>
              <FormHelperText
                error={!!errors.acceptTerms}
                sx={{ textAlign: { xs: 'left', md: 'center' } }}
              >
                {errors.acceptTerms && errors.acceptTerms.message}
              </FormHelperText>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: { xs: '100%', sm: '200px' } }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </ModalUI>
    )
  },
)

export default PopupCreateBooking

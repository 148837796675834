import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  SxProps,
} from '@mui/material'
import { memo } from 'react'
import { Control, FieldError, useController } from 'react-hook-form'

export interface InputFieldProps extends OutlinedInputProps {
  label?: string
  sx?: SxProps
  fullWidth?: boolean
  name?: string
  numberOnly?: boolean
  control?: Control<any>
  hideHelper?: boolean
  errorMess?: string
  rootProps?: FormControlProps
  getErrorMess?: (error: FieldError, value: string) => string
  disableZeroFirst?: boolean
  helperTextProps?: FormHelperTextProps
  replaceChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => string
}

export const InputField = memo(
  ({
    label,
    name,
    fullWidth = true,
    rootProps = {},
    numberOnly,
    value: externalValue,
    onChange: externalOnChange,
    control,
    inputProps,
    hideHelper,
    helperTextProps,
    type,
    errorMess,
    multiline,
    getErrorMess,
    disableZeroFirst,
    replaceChange,
    ...rest
  }: InputFieldProps) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
            onBlur: undefined,
            ref: undefined,
          },
          fieldState: { error: undefined },
        }

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} {...rootProps}>
        {label && (
          <Stack flexDirection="row" alignItems="center">
            <FormLabel htmlFor={name}>{label}</FormLabel>
          </Stack>
        )}
        <OutlinedInput
          value={value ?? ''}
          multiline={multiline}
          onChange={(e) => {
            if ((type === 'number' || numberOnly) && !replaceChange) {
              e.target.value = e.target.value.replace(/[^0-9]/g, '')
            } else if (replaceChange) {
              e.target.value = replaceChange(e)
            }
            if (disableZeroFirst) {
              e.target.value = e.target.value.replace(/^0+/gi, '')
            }
            if (control) externalOnChange?.(e)
            onChange(e)
          }}
          onBlur={onBlur}
          inputRef={ref}
          notched={false}
          error={!!error}
          inputProps={{
            ...inputProps,
          }}
          type={type}
          onWheel={(e) =>
            e.target instanceof HTMLElement &&
            (e.target as any).type === 'number' &&
            e.target.blur()
          }
          {...rest}
        />
        {!hideHelper && (error || errorMess) && (
          <FormHelperText
            error={!!error || !!errorMess}
            sx={{ textAlign: label ? 'left' : 'right' }}
            {...helperTextProps}
          >
            {getErrorMess
              ? getErrorMess(error, value)
              : error?.message || errorMess}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

export default InputField

import styled from '@emotion/styled'
import { Accordion, Box } from '@mui/material'
import { USER_TYPE } from 'models'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface StyledStatusDropdownProps {
  $userType: USER_TYPE
}

export const StyledStatusDropdown = styled(
  Box,
  transientOptions,
)<StyledStatusDropdownProps>`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  & .panel_header {
    padding: 18px 22px 17px;
    background-color: ${({ $userType }) =>
      $userType === USER_TYPE.PARENT
        ? theme.palette.primary.light
        : theme.palette.secondary.light};
  }
  & .panel_body {
  }
`

export const StyledAccordion = styled(
  Accordion,
  transientOptions,
)<StyledStatusDropdownProps>`
  width: 100%;
  border-radius: 0 0 8px 8px !important;
  & .MuiAccordionSummary-root {
    min-height: 80px !important;
    padding: 0 22px;
  }
  & .MuiAccordionSummary-content {
    flex-direction: column;
  }
  & .MuiAccordionDetails-root {
    padding: 0 22px;
  }
`

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import TestimonialItem from 'components/common/TestimonialItem'
import { NotifyService } from 'helpers'
import { DATA_PAGINATION, NANNY_TESTIMONIAL } from 'models'
import { memo, useEffect, useMemo, useState } from 'react'
import { testimonialApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { StyledButton } from './styles'

const mockItems = Array.from(Array(4).keys())

interface SectionTestimonialsProps {
  nannyId: string
}

const SectionTestimonials = memo(({ nannyId }: SectionTestimonialsProps) => {
  const dispatch = useAppDispatch()
  const [listTestimonial, setListTestimonial] =
    useState<DATA_PAGINATION<NANNY_TESTIMONIAL>>()

  const isHasMore = useMemo(
    () =>
      listTestimonial && listTestimonial.pageNum < listTestimonial.totalPages,
    [listTestimonial],
  )

  const getTestimonials = async (pageNum: number = 1, pageSize = 8) => {
    const data: DATA_PAGINATION<NANNY_TESTIMONIAL> = await testimonialApi.nanny
      .getTestimonials({ pageNum, pageSize, nannyId })
      .then(({ data }) => data)
    return data
  }

  const fetchInit = async () => {
    await getTestimonials()
      .then((data) => setListTestimonial(data))
      .catch(() => {})
  }

  const onLoadMore = async () => {
    if (!isHasMore) return
    dispatch(setGlobalLoading(true))
    await getTestimonials(listTestimonial.pageNum + 1)
      .then((data) => {
        setListTestimonial((pre) => ({
          ...data,
          data: [...pre.data, ...data.data],
        }))
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nannyId])

  return (
    <Stack component="section" spacing={1}>
      <Typography variant="Web_Title_22">Testimonials</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {listTestimonial
            ? listTestimonial?.data?.map((item) => (
                <Grid key={item.id} item xs={12} md={6}>
                  <TestimonialItem item={item} />
                </Grid>
              ))
            : mockItems.map((_, idx) => (
                <Grid key={idx} item xs={12} md={6}>
                  <Skeleton
                    sx={{ height: '198px', borderRadius: '8px' }}
                    variant="rectangular"
                  />
                </Grid>
              ))}
          {isHasMore && (
            <Grid item xs={12}>
              <StyledButton onClick={onLoadMore} variant="contained" fullWidth>
                Load More Testimonials
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Stack>
  )
})

export default SectionTestimonials

import { SvgIcon, SvgIconProps } from '@mui/material'

export function CloseCircle(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="currentColor"
      />
      <path
        d="M9.16986 15.5799C8.97986 15.5799 8.78986 15.5099 8.63986 15.3599C8.34986 15.0699 8.34986 14.5899 8.63986 14.2999L14.2999 8.63986C14.5899 8.34986 15.0699 8.34986 15.3599 8.63986C15.6499 8.92986 15.6499 9.40986 15.3599 9.69986L9.69986 15.3599C9.55986 15.5099 9.35986 15.5799 9.16986 15.5799Z"
        fill="currentColor"
      />
      <path
        d="M14.8299 15.5799C14.6399 15.5799 14.4499 15.5099 14.2999 15.3599L8.63986 9.69986C8.34986 9.40986 8.34986 8.92986 8.63986 8.63986C8.92986 8.34986 9.40986 8.34986 9.69986 8.63986L15.3599 14.2999C15.6499 14.5899 15.6499 15.0699 15.3599 15.3599C15.2099 15.5099 15.0199 15.5799 14.8299 15.5799Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CloseCircle

import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'
import { transientOptions } from 'utils/transientOptions'

interface FormControlLabelStyledProps {
  $alignItems: string
}

export const FormControlLabelStyled = styled(
  FormControlLabel,
  transientOptions,
)<FormControlLabelStyledProps>`
  align-items: ${({ $alignItems }) => $alignItems};
`

import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { USER_TYPE } from 'models'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface StyledStatusPanelProps {
  $userType: USER_TYPE
}

interface StyledStepperProps {
  $userType: USER_TYPE
}

export const StyledStatusPanel = styled(
  Box,
  transientOptions,
)<StyledStatusPanelProps>`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  & .panel_header {
    padding: 18px 31px 17px;
    background-color: ${({ $userType }) =>
      $userType === USER_TYPE.PARENT
        ? theme.palette.primary.light
        : theme.palette.secondary.light};
  }
  & .panel_body {
    padding: 25px 42px;
  }
`

export const StyledStepper = styled(Box, transientOptions)<StyledStepperProps>`
  flex: 1;
  height: 1px;
  border-bottom: 1px solid #b6b6b6;
  position: relative;
  top: 12px;
  margin: 0 -10px;
  &.active {
    border-bottom: 1px solid
      ${({ $userType }) =>
        $userType === USER_TYPE.PARENT
          ? theme.palette.primary.main
          : theme.palette.secondary.main};
  }
`

import { useAuth } from 'contexts/auth'
import { Navigate, Outlet } from 'react-router'

interface Props {
  children: any
  redirectTo: string
}

export const RequireAuth = ({ children, redirectTo }: Props) => {
  const { isLoggedIn, authRedirect } = useAuth()

  if (authRedirect.current) {
    if (!isLoggedIn) return <Navigate to={redirectTo} replace />
  }

  return <>{children ? children : <Outlet />}</>
}

export default RequireAuth

import { Button, Stack, Typography } from '@mui/material'
import { ArrowLeftIcon } from 'assets'
import { ComfirmDialog } from 'components'
import ActivityLogPanel from 'components/common/ActivityLogPanel'
import BookingDetailPanel from 'components/common/BookingDetailPanel'
import { StatusPanel, StatusPanelStep } from 'components/common/StatusPanel'
import StatusPanelMobile from 'components/common/StatusPanelMobile'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import {
  ACTION_TYPE,
  BOOKING_AVAILABLE_NANNY,
  BOOKING_PARENT_STATUS,
  MODAL_ACTION,
  PARENT_BOOKING_DETAIL,
  USER_TYPE,
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { bookingApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { theme } from 'theme'
import NannyPanel from '../../components/NannyPanel'
import PopupConfirm from '../../components/PopupConfirm'
import PopupTestimonial from '../../components/PopupTestimonial'

function DetailBooking() {
  const { id } = useParams()
  const { parent } = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [booking, setBooking] = useState<PARENT_BOOKING_DETAIL>()
  const [modalAction, setModalAction] =
    useState<MODAL_ACTION<PARENT_BOOKING_DETAIL | BOOKING_AVAILABLE_NANNY>>()
  const listStep = useMemo(() => {
    const steps: StatusPanelStep[] = [
      {
        label: 'Submitted',
        active: true,
        activeLabel: true,
      },
      {
        label: 'Nanny Matched',
        active: [
          BOOKING_PARENT_STATUS.NANNIES_MATCHED,
          BOOKING_PARENT_STATUS.NANNY_CONFIRMED,
          BOOKING_PARENT_STATUS.DEPOSIT_PAID,
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
          BOOKING_PARENT_STATUS.CANCELLED,
          BOOKING_PARENT_STATUS.DELETED,
        ].includes(booking?.status),
        activeLabel: [
          BOOKING_PARENT_STATUS.NANNIES_MATCHED,
          BOOKING_PARENT_STATUS.NANNY_CONFIRMED,
          BOOKING_PARENT_STATUS.DEPOSIT_PAID,
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
        ].includes(booking?.status),
      },
      {
        label: 'Deposit Paid',
        active: [
          BOOKING_PARENT_STATUS.DEPOSIT_PAID,
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
          BOOKING_PARENT_STATUS.CANCELLED,
          BOOKING_PARENT_STATUS.DELETED,
        ].includes(booking?.status),
        activeLabel: [
          BOOKING_PARENT_STATUS.DEPOSIT_PAID,
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
        ].includes(booking?.status),
      },
      {
        label: 'Commenced',
        active: [
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
          BOOKING_PARENT_STATUS.CANCELLED,
          BOOKING_PARENT_STATUS.DELETED,
        ].includes(booking?.status),
        activeLabel: [
          BOOKING_PARENT_STATUS.COMMENCED,
          BOOKING_PARENT_STATUS.COMPLETED,
        ].includes(booking?.status),
      },
    ]
    if (BOOKING_PARENT_STATUS.CANCELLED === booking?.status) {
      steps.push({
        label: 'Cancelled',
        active: true,
        activeLabel: true,
      })
    } else if (BOOKING_PARENT_STATUS.DELETED === booking?.status) {
      steps.push({
        label: 'Deleted',
        active: true,
        activeLabel: true,
      })
    } else {
      steps.push({
        label: 'Completed',
        active: BOOKING_PARENT_STATUS.COMPLETED === booking?.status,
        activeLabel: BOOKING_PARENT_STATUS.COMPLETED === booking?.status,
      })
    }
    return steps
  }, [booking])

  const onGoBack = () => {
    navigate(APP_ROUTES.PARENT.BOOKING.to)
  }

  const fetchData = useDebounce(async () => {
    dispatch(setGlobalLoading(true))
    await bookingApi.parent
      .getDetail(id)
      .then(({ data: { data } }) => {
        dispatch(setGlobalLoading(false))
        setBooking(data)
      })
      .catch((e) => {
        dispatch(setGlobalLoading(false))
        NotifyService.error(e)
        onGoBack()
      })
  }, 50)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onCloseModalAction = () => {
    setModalAction(undefined)
  }

  const onCancel = useDebounce(async () => {
    dispatch(setGlobalLoading(true))
    await bookingApi.parent
      .cancel(booking.bookingId)
      .then(() => {
        setModalAction({ type: ACTION_TYPE.CANCELLED })
        NotifyService.success('Success')
        fetchData()
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 250)

  return (
    <Stack spacing={2}>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          component={NavLink}
          to={APP_ROUTES.PARENT.BOOKING.to}
          sx={{ textDecoration: 'none', color: theme.palette.black.dark }}
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <ArrowLeftIcon />
          <Typography lineHeight="21px" variant="Web_Label_18">
            Back to Bookings
          </Typography>
        </Stack>
        {![
          BOOKING_PARENT_STATUS.CANCELLED,
          BOOKING_PARENT_STATUS.DELETED,
          BOOKING_PARENT_STATUS.COMPLETED,
        ].includes(booking?.status) && (
          <Button
            variant="outlined"
            sx={{
              display: { xs: 'none', md: 'flex' },
              padding: '11px 22px',
            }}
            onClick={() => setModalAction({ type: ACTION_TYPE.CANCEL })}
          >
            Cancel Booking
          </Button>
        )}
      </Stack>
      {booking && (
        <>
          <Stack display={{ xs: 'none', md: 'flex' }}>
            <StatusPanel
              bookingId={id}
              listStep={listStep}
              userType={USER_TYPE.PARENT}
            />
          </Stack>
          <Stack display={{ xs: 'flex', md: 'none' }}>
            <StatusPanelMobile
              bookingId={id}
              userType={USER_TYPE.PARENT}
              steps={listStep}
            />
          </Stack>
          <Stack>
            <BookingDetailPanel item={booking} userType={USER_TYPE.PARENT} />
          </Stack>
          <Stack>
            <NannyPanel
              booking={booking}
              handleTestimonial={(nanny) =>
                setModalAction({ type: ACTION_TYPE.CUSTOM, data: nanny })
              }
            />
          </Stack>
          <Stack>
            <ActivityLogPanel activityList={booking.activityList} />
          </Stack>
        </>
      )}
      <Stack>
        <Button
          variant="outlined"
          sx={{
            display: { xs: 'flex', md: 'none' },
            padding: '11px 22px',
          }}
          onClick={() => setModalAction({ type: ACTION_TYPE.CANCEL })}
        >
          Cancel Booking
        </Button>
      </Stack>
      {modalAction?.type === ACTION_TYPE.CANCEL && (
        <ComfirmDialog
          open
          title="Cancel Booking"
          description="Are you sure you want to cancel this booking?"
          onClose={onCloseModalAction}
          onConfirm={onCancel}
        />
      )}
      {modalAction?.type === ACTION_TYPE.CANCELLED && (
        <PopupConfirm
          title="Booking Cancelled"
          content={
            <Typography variant="Web_Label_14">
              Your booking has been cancelled
            </Typography>
          }
          textButton="Back to My Bookings"
          hrefButton={APP_ROUTES.PARENT.BOOKING.to}
          onClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.CUSTOM && (
        <PopupTestimonial
          parentId={parent.parent_id}
          bookingId={booking.bookingId}
          nanny={modalAction.data as BOOKING_AVAILABLE_NANNY}
          onClose={onCloseModalAction}
          onSuccess={fetchData}
        />
      )}
    </Stack>
  )
}

export default DetailBooking

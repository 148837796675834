import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
} from '@mui/material'
import { InfoCircleIcon } from 'assets'
import { memo } from 'react'
import { theme } from 'theme'
import { Description, SubTitle, Title } from './styles'

interface AccountPendingInfoProps extends DialogProps {}

export const AccountPendingInfo = memo(
  ({ onClose, ...rest }: AccountPendingInfoProps) => {
    return (
      <Dialog
        open
        fullWidth
        scroll="body"
        PaperProps={{ sx: { maxWidth: '456px' } }}
        onClose={onClose}
        {...rest}
      >
        <Stack alignItems="center">
          <Stack alignItems="center" sx={{ pt: 3, pb: 0.5, px: 3 }}>
            <InfoCircleIcon
              sx={{ color: theme.palette.secondary_shades[50], fontSize: 66 }}
            />
            <Title>Account Pending Approval</Title>
          </Stack>
          <DialogContent
            sx={{
              pt: 0.5,
              px: 5,
              pb: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SubTitle>
              Your account is still under review. Please{' '}
              <span>complete the following items</span> and our admin will
              contact you within <span>2-3 business days.</span>
            </SubTitle>
            <Description>
              Ensure you fill in the personal info so our admin can check.
            </Description>
          </DialogContent>
          <DialogActions sx={{ pt: 3.75, px: 3, pb: 4.25 }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: 200 }}
              onClick={() => onClose({}, 'backdropClick')}
            >
              OK
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    )
  },
)

export default AccountPendingInfo

import { Button } from '@mui/material'
import { CheckRectangleIcon } from 'assets'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { ContentBox, SubTitle, Title } from './styles'

export const Submitting = () => {
  return (
    <ContentBox>
      <CheckRectangleIcon sx={{ color: 'secondary.main', fontSize: 100 }} />
      <Title mt={3}>Thank you for submitting</Title>
      <SubTitle>
        We’ll contact you within 2-3 business days to further verify your
        details.
      </SubTitle>
      <Button
        variant="contained"
        color="secondary"
        sx={{ width: { xs: '100%', sm: '200px' } }}
        component={Link}
        to={APP_ROUTES.NANNY.PROFILE.to}
      >
        Go to My Profile
      </Button>
    </ContentBox>
  )
}

export default Submitting

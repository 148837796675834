import { GlobalLoading } from 'components'
import moment from 'moment-timezone'
import { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import configAppRoutes from 'routers'
import { useAppSelector } from 'store/hook'
import { ThemeProvider } from 'theme'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

moment.tz.setDefault('Asia/Singapore')

const App = () => {
  const routes = useRoutes(configAppRoutes)
  const { loading } = useAppSelector((state) => state.global)
  const { loadingAuth } = useAppSelector((state) => state.auth)

  return (
    <Suspense fallback={<GlobalLoading open />}>
      <ThemeProvider>
        {!loadingAuth && routes}
        <GlobalLoading open={loading} />
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  )
}

export default App

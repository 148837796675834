export const APP_ROUTES = {
  PARENT: {
    path: 'parent/*',
    to: '/parent',
    AUTH: {
      path: 'account/*',
      to: '/parent/account',
      LOGIN: {
        path: 'login',
        to: '/parent/account/login',
      },
      SIGN_UP: {
        path: 'sign-up',
        to: '/parent/account/sign-up',
      },
      VERIFY: {
        path: 'verify',
        to: '/parent/account/verify',
      },
      FORGOT_PASSWORD: {
        path: 'forgot-password',
        to: '/parent/account/forgot-password',
      },
      RESET_PASSWORD: {
        path: 'reset-password',
        to: '/parent/account/reset-password',
      },
      CHECK_CODE: {
        path: 'check-code',
        to: '/parent/account/check-code',
      },
      VERIFIED: {
        path: 'verified',
        to: '/parent/account/verified',
      },
    },
    BOOKING: {
      path: 'booking/*',
      to: '/parent/booking',
      DETAIL: {
        path: 'detail/:id',
        to: (id: string | number) => `/parent/booking/detail/${id}`,
      },
    },
    NANNY_DETAIL: {
      path: 'nanny-detail/:id',
      to: (id: string | number) => `/parent/nanny-detail/${id}`,
    },
    PROFILE: {
      path: 'profile',
      to: '/parent/profile',
    },
  },
  NANNY: {
    path: 'nanny/*',
    to: '/nanny',
    AUTH: {
      path: 'account/*',
      to: '/nanny/account',
      LOGIN: {
        path: 'login',
        to: '/nanny/account/login',
      },
      REGISTER: {
        path: 'register',
        to: '/nanny/account/register',
      },
      PHONE_VERIFY: {
        path: 'phone-verify',
        to: '/nanny/account/phone-verify',
      },
      EMAIL_VERIFY: {
        path: 'email-verify',
        to: '/nanny/account/email-verify',
      },
      FORGOT_PASSWORD: {
        path: 'forgot-password',
        to: '/nanny/account/forgot-password',
      },
      CHECK_CODE: {
        path: 'check-code',
        to: '/nanny/account/check-code',
      },
      RESET_PASSWORD: {
        path: 'reset-password',
        to: '/nanny/account/reset-password',
      },
      COMPLETED: {
        path: 'completed',
        to: '/nanny/account/completed',
      },
    },
    COMPLETE_PROFILE: {
      path: 'complete-profile',
      to: '/nanny/complete-profile',
    },
    BOOKING: {
      path: 'booking/*',
      to: '/nanny/booking',
      DETAIL: {
        path: 'detail/:id',
        to: (id: string | number) => `/nanny/booking/detail/${id}`,
      },
    },
    EARNING: {
      path: 'earning',
      to: '/nanny/earning',
    },
    PHOTO: {
      path: 'photo',
      to: '/nanny/photo',
    },
    TESTIMONIAL: {
      path: 'testimonial',
      to: '/nanny/testimonial',
    },
    PROFILE: {
      path: 'profile/*',
      to: '/nanny/profile',
      PREVIEW: {
        path: 'preview/:id',
        to: (id: string | number) => `/nanny/preview/${id}`,
      },
    },
  },
  NOT_FOUND: {
    path: '404',
    to: '/404',
  },
}

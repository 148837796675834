import { Box, Container, Grid } from '@mui/material'
import { Header } from 'components/common'
import MenuLeft from 'components/common/Menu/MenuLeft'
import { Outlet } from 'react-router-dom'

export const ParentMainLayout = () => {
  return (
    <Box>
      <Header />
      <main>
        <Container>
          <Grid py={2.25} container>
            <Grid item display={{ xs: 'none', md: 'flex' }} md={2}>
              <MenuLeft />
            </Grid>
            <Grid item xs={12} md={10} pl={{ xs: 0, md: 4.5 }}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
      </main>
    </Box>
  )
}

export default ParentMainLayout

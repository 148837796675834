import { SvgIcon, SvgIconProps } from '@mui/material'

export function UnCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 4.5C2.25 3.25736 3.25736 2.25 4.5 2.25H19.5C20.7427 2.25 21.75 3.25737 21.75 4.5V19.5C21.75 20.7427 20.7427 21.75 19.5 21.75H4.5C3.25737 21.75 2.25 20.7427 2.25 19.5V4.5ZM4.5 3.75C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08578 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08578 19.9142 3.75 19.5 3.75H4.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default UnCheckIcon

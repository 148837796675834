import { Button, Stack, Step, StepLabel, Typography } from '@mui/material'
import { CheckCircleIcon, DirectionRightIcon } from 'assets'
import { StyledLogoIcon } from 'components/common/styles/auth'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { BoxIcon, Root, StyledStepper, SubTitle } from './styles'

export const Completed = () => (
  <Root alignItems="center" sx={{ mb: { xs: 0, md: '132px' } }}>
    <Stack mb="95px">
      <Link to={APP_ROUTES.NANNY.AUTH.LOGIN.to}>
        <StyledLogoIcon sx={{ mb: '0px !important' }} />
      </Link>
    </Stack>
    <BoxIcon justifyContent="center" alignItems="center">
      <CheckCircleIcon />
    </BoxIcon>
    <Typography variant="Web_Title_18" mt={2.625} mb={1} align="center">
      Signup Successful
    </Typography>
    <SubTitle>
      Welcome to Aunty App, our admin will get in contact with you{' '}
      <span>within 2-3 business days.</span>
    </SubTitle>
    <StyledStepper activeStep={0} alternativeLabel>
      <Step>
        <StepLabel>Sign up</StepLabel>
      </Step>
      <Step>
        <StepLabel>Interview</StepLabel>
      </Step>
      <Step>
        <StepLabel>Approval</StepLabel>
      </Step>
    </StyledStepper>
    <SubTitle mb={1.625}>
      Meanwhile, you may start to set up your <span>personal information</span>{' '}
      by clicking the button below.
    </SubTitle>
    <Button
      fullWidth
      sx={{ maxWidth: '335px' }}
      color="secondary"
      variant="contained"
      component={Link}
      to={APP_ROUTES.NANNY.COMPLETE_PROFILE.to}
    >
      Set up Personal Info <DirectionRightIcon sx={{ ml: 1 }} />
    </Button>
  </Root>
)

export default Completed

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { PlusIcon } from 'assets'
import { ComfirmDialog, EmptyUI } from 'components'
import { ShowMoreBtn } from 'components/common/styles/nanny'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import { ACTION_TYPE, DATA_PAGINATION, MODAL_ACTION } from 'models'
import { NANNY_PHOTO } from 'models/nanny/photo'
import { useEffect, useMemo, useState } from 'react'
import { photoApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import PictureItem from '../../components/PictureItem'
import PopupUploadPhoto from '../../components/PopupUploadPhoto'

export const Photos = () => {
  const { nanny } = useAuth()
  const dispatch = useAppDispatch()

  const [activeIndex, setActiveIndex] = useState(0)
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [listPhoto, setListPhoto] = useState<DATA_PAGINATION<NANNY_PHOTO>>()
  const [modalAction, setModalAction] = useState<MODAL_ACTION<NANNY_PHOTO>>()

  const isHasMore = useMemo(() => {
    return listPhoto && listPhoto.pageNum < listPhoto.totalPages
  }, [listPhoto])

  const slides = useMemo(
    () =>
      listPhoto?.data.map((img) => ({
        src: img.imageUrl,
        description: img.caption || '',
      })) || [],
    [listPhoto],
  )

  const getPhotos = async (pageNum: number = 1, pageSize = 8) => {
    const data: DATA_PAGINATION<NANNY_PHOTO> = await photoApi.nanny
      .getPhotos({ pageNum, pageSize, nannyId: nanny.nannyId })
      .then(({ data }) => data)
    return data
  }

  const fetchInit = async () => {
    dispatch(setGlobalLoading(true))
    await getPhotos()
      .then((data) => setListPhoto(data))
      .catch(() => {})
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  const onLoadMore = async () => {
    if (!isHasMore) return
    dispatch(setGlobalLoading(true))
    await getPhotos(listPhoto.pageNum + 1)
      .then((data) => {
        setListPhoto((pre) => ({ ...data, data: [...pre.data, ...data.data] }))
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nanny?.nannyId])

  const onShowPhoto = (idx: number) => {
    setActiveIndex(idx)
    setIsOpenLightBox(true)
  }

  const onCloseModalAction = () => {
    setModalAction(undefined)
  }

  const onDelete = useDebounce(async () => {
    if (!modalAction?.data) return
    dispatch(setGlobalLoading(true))
    await photoApi.nanny
      .deletePhoto(modalAction.data.id)
      .then(() => {
        fetchInit()
        onCloseModalAction()
        NotifyService.success('Success')
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 250)

  return (
    <Stack spacing={2.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="Web_Title_22">Photos</Typography>
        <Button
          sx={{
            padding: '8.2px 16.4px',
          }}
          variant="contained"
          endIcon={<PlusIcon />}
          color="secondary"
          onClick={() => setModalAction({ type: ACTION_TYPE.CREATE })}
        >
          Upload New Photos
        </Button>
      </Stack>
      {!!listPhoto?.data.length ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 1, sm: 2.5 }}>
            {listPhoto?.data?.map((item, idx) => (
              <PictureItem
                key={idx}
                item={item}
                index={idx}
                onShowPhoto={onShowPhoto}
                onDelete={(item) =>
                  setModalAction({ type: ACTION_TYPE.DELETE, data: item })
                }
              />
            ))}
            {isHasMore && (
              <Grid item xs={12}>
                <ShowMoreBtn onClick={onLoadMore} variant="contained" fullWidth>
                  Load More Photos
                </ShowMoreBtn>
              </Grid>
            )}
          </Grid>
          <Lightbox
            open={isOpenLightBox}
            slides={slides}
            plugins={[Captions]}
            index={activeIndex}
            close={() => setIsOpenLightBox(false)}
          />
        </Box>
      ) : (
        <EmptyUI content="You don’t have any photos yet" />
      )}
      {modalAction?.type === ACTION_TYPE.CREATE && (
        <PopupUploadPhoto
          onClose={onCloseModalAction}
          onSuccess={() => {
            fetchInit()
            onCloseModalAction()
          }}
        />
      )}
      {modalAction?.type === ACTION_TYPE.DELETE && (
        <ComfirmDialog
          open
          title="Delete photo"
          description="Are you sure you want to delete this photo?"
          onClose={onCloseModalAction}
          onConfirm={onDelete}
        />
      )}
    </Stack>
  )
}

export default Photos

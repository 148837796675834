import { ParentMainLayout } from 'components'
import NannyDetail from 'components/common/NannyDetail'
import { Helmet } from 'react-helmet-async'
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom'
import { ParentRequireAuth } from 'routers/parent'
import { APP_ROUTES } from 'routers/routes'
import AuthFeature from './auth'
import { DetailBooking, ListBooking } from './bookings'
import { EditProfile } from './editProfile'

const configRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.PARENT.AUTH.path,
    element: <AuthFeature />,
  },
  {
    path: APP_ROUTES.PARENT.NANNY_DETAIL.path,
    element: (
      <ParentRequireAuth>
        <NannyDetail />
      </ParentRequireAuth>
    ),
  },
  {
    path: '*',
    element: (
      <ParentRequireAuth>
        <ParentMainLayout />
        <Helmet>
          <title>Aunty Confinement</title>
          <meta name="description" content="Aunty Confinement" />
        </Helmet>
      </ParentRequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.PARENT.BOOKING.path,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ListBooking />,
          },
          {
            path: APP_ROUTES.PARENT.BOOKING.DETAIL.path,
            element: <DetailBooking />,
          },
          {
            path: '*',
            element: <Navigate to={APP_ROUTES.PARENT.BOOKING.to} replace />,
          },
        ],
      },
      {
        path: APP_ROUTES.PARENT.PROFILE.path,
        element: <EditProfile />,
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.PARENT.BOOKING.to} replace />,
      },
    ],
  },
]

function ParentFeature() {
  const routes = useRoutes(configRoutes)
  return <>{routes}</>
}

export default ParentFeature

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  Stack,
  ToggleButtonGroupProps,
} from '@mui/material'
import { memo } from 'react'
import { Control, FieldError, useController } from 'react-hook-form'
import { ToggleButtonGroupStyled, ToggleButtonStyled } from './styles'

export interface ToggleButtonFieldProps extends ToggleButtonGroupProps {
  label?: string
  name?: string
  control?: Control<any>
  hideHelper?: boolean
  errorMess?: string
  rootProps?: FormControlProps
  onChange?: (value: any) => void
  getErrorMess?: (error: FieldError, value: string) => string
  helperTextProps?: FormHelperTextProps
  options: {
    label: string
    value: any
  }[]
}

export const ToggleButtonField = memo(
  ({
    label,
    name,
    fullWidth = true,
    disabled,
    rootProps = {},
    value: externalValue,
    onChange: externalOnChange,
    control,
    hideHelper,
    helperTextProps,
    errorMess,
    options,
    getErrorMess,
    ...rest
  }: ToggleButtonFieldProps) => {
    const {
      field: { onChange, value },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange,
            value: externalValue,
          },
          fieldState: { error: undefined },
        }

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} {...rootProps}>
        {label && (
          <Stack flexDirection="row" alignItems="center">
            <FormLabel htmlFor={name}>{label}</FormLabel>
          </Stack>
        )}

        <ToggleButtonGroupStyled
          fullWidth
          color="primary"
          value={value ?? null}
          exclusive
          $error={!!error}
          onChange={(_, val) => {
            onChange(val ?? null)
          }}
          aria-label="Platform"
          {...rest}
        >
          {options.map((item, idx) => (
            <ToggleButtonStyled key={idx} value={item.value}>
              {item.label}
            </ToggleButtonStyled>
          ))}
        </ToggleButtonGroupStyled>
        {!hideHelper && (error || errorMess) && (
          <FormHelperText
            error={!!error || !!errorMess}
            sx={{ textAlign: label ? 'left' : 'right' }}
            {...helperTextProps}
          >
            {getErrorMess
              ? getErrorMess(error, value)
              : error?.message || errorMess}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

export default ToggleButtonField

import { Box, Typography } from '@mui/material'
import { Header } from 'components'
import { useAuth } from 'contexts/auth'
import { NANNY_STATUS } from 'models'
import PersonalInfo from '../../components/PersonalInfo'
import Submitting from '../../components/Submitting'
import { StyledContainer, TopBox } from './styles'

export const CompleteProfile = () => {
  const { nanny } = useAuth()
  return (
    <Box>
      <Header />
      <main>
        <TopBox>
          <Typography variant="Web_Title_22" color="white.light" align="center">
            Complete your nanny profile
          </Typography>
        </TopBox>
        <StyledContainer>
          {nanny?.status === NANNY_STATUS.VERIFIED && <PersonalInfo />}
          {nanny?.status === NANNY_STATUS.PENDING_APPROVAL && <Submitting />}
        </StyledContainer>
      </main>
    </Box>
  )
}

export default CompleteProfile

import { Box, OutlinedInput, css, styled } from '@mui/material'
import { APP_IMG } from 'assets'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface InputContainerProps {
  $error?: boolean
  $disabled?: boolean
}
export const InputContainer = styled(Box, transientOptions)<
  InputContainerProps & {}
>`
  display: flex;
  height: 48px;
  border-radius: 8px;
  background: ${theme.palette.backgrounds.primary};
  border: 1px solid ${theme.palette.other.stroke};
  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid ${theme.palette.red.light};
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      background: ${theme.palette.backgrounds.tertiary};
    `}
`

export const StyledInput = styled(OutlinedInput)`
  background: transparent;
  border: none;
  height: 100%;
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-readOnly,
  &.Mui-disabled {
    background: transparent;
  }
  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-input {
    padding-left: 9px;
  }
`

export const BasePhoneInput = styled(
  ({ className, ...rest }: PhoneInputProps & { className?: string }) => (
    <PhoneInput containerClass={className} {...rest} />
  ),
)<{}>`
  &.react-tel-input {
    min-width: 85px;
    width: 85px;
    display: flex;
    border-right: 1px solid #e5e5ea;
    .form-control {
      border-radius: 0;
      border: none;
      background: transparent;
      padding: 5px 5px 5px 16px;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: 0.25px;
      color: ${theme.palette.label.primary};
      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.25px;
        color: #e5e5ea;
      }
    }
    .flag-dropdown {
      width: 100%;
      .selected-flag {
        width: auto;
        padding: 0;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 9px;
        .flag {
          margin: 0;
          top: 0;
          position: unset;
          width: 16px;
          height: 16px;
          .arrow {
            left: 0;
            border: none;
            width: 16px;
            height: 16px;
            top: auto;
            margin: 0;
            position: unset;
            background-image: url(${APP_IMG.icons.directionDown});
            .up {
              transform: rotate(180deg);
            }
          }
          background: transparent;
        }
      }
    }
  }
`

export const StyledPhoneInput = styled(
  ({ className, ...rest }: PhoneInputProps & { className?: string }) => (
    <BasePhoneInput dropdownClass={className} {...rest} />
  ),
)``

import PageError from 'components/common/PageError'
import NannyFeature from 'modules/nanny'
import ParentFeature from 'modules/parent'
import { Navigate, RouteObject } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import { APP_ROUTES } from './routes'

const configAppRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.PARENT.path,
    element: <ParentFeature />,
  },
  {
    path: APP_ROUTES.NANNY.path,
    element: <NannyFeature />,
  },
  {
    path: APP_ROUTES.NOT_FOUND.path,
    element: (
      <RequireAuth redirectTo={APP_ROUTES.PARENT.AUTH.LOGIN.to}>
        <PageError />
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: <Navigate to={APP_ROUTES.NOT_FOUND.to} replace />,
  },
]

export default configAppRoutes

import styled from '@emotion/styled'
import { DialogTitle, Typography } from '@mui/material'
import { theme } from 'theme'

export const Title = styled(DialogTitle)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 8px;
  padding: 0;
  color: ${theme.palette.secondary.main};
`

export const SubTitle = styled(Typography)`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: ${theme.palette.text.secondary};
  span {
    font-weight: 700;
  }
`

export const Description = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  text-align: center;
  margin-top: 20px;
  max-width: 269px;
  color: ${theme.palette.secondary.main};
`

import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { theme } from 'theme'

export const StyledSubLogo = styled(Typography)`
  color: ${theme.palette.grey1.light};
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

import { Theme } from '@mui/material/styles';

export default function Forms(theme: Theme): Theme['components'] {
  return {
    MuiFormControl: {},
    MuiFormControlLabel: {},
    MuiFormGroup: {},
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: '4px',
          marginTop: '4px',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '12px',
          whiteSpace: 'pre-line',
          letterSpacing: '0.07px',
          color: theme.palette.red.light,
          '&.Mui-error': {
            color: theme.palette.red.light,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: '8px',
          fontWeight: '400',
          fontSize: '16px',
          color: theme.palette.label.primary,
          lineHeight: '20px',
          letterSpacing: '0.1px',
          '&.Mui-focused': {
            color: theme.palette.label.primary,
          },
        },
        focused: {
          color: theme.palette.label.primary,
        },
      },
    },
  };
}

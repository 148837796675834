import axios, { AxiosResponse } from 'axios'
import { API, TOKEN_KEY } from 'config'
import { ResponseType } from 'models'
import {
  getCookies,
  removeCookies,
  setRefreshTokenCookies,
  setTokenCookies,
} from 'utils/cookies'
import { setToken } from './configApi'

const refreshAccessToken = async (): Promise<string | null> => {
  const refreshToken = getCookies(TOKEN_KEY.REFRESH_TOKEN)
  if (!refreshToken) {
    return null
  }

  try {
    const response: AxiosResponse<
      ResponseType<{
        access_token: string
        refresh_token: string
      }>
    > = await axios.post(
      `${process.env.REACT_APP_VINOVA_API}/${API.AUTH.REFRESH_TOKEN}`,
      {
        refreshToken: refreshToken,
      },
    )
    const { access_token, refresh_token } = response.data.data
    setTokenCookies(access_token)
    setRefreshTokenCookies(refresh_token)
    return access_token
  } catch (error) {
    console.log(error)
    throw new Error('Failed to refresh access token')
  }
}

const token = getCookies(TOKEN_KEY.ACCESS_TOKEN)

const _APIInstance = axios.create({
  baseURL: `${process.env.REACT_APP_VINOVA_API_V2}`,
})

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

export default _APIInstance

export const APIInstanceV2 = _APIInstance

_APIInstance.defaults.headers.common['Content-Type'] =
  'application/json; charset=utf-8'
// _APIInstance.defaults.headers.common['Time-Zone'] =
//   Intl.DateTimeFormat().resolvedOptions().timeZone
export const setTokenV2 = (token: string) => {
  _APIInstance.defaults.headers.common.Authorization = `Bearer ${token}`
}

if (token) setTokenV2(token)

_APIInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      try {
        const accessToken = await refreshAccessToken()
        if (accessToken) {
          setToken(accessToken)
          setTokenV2(accessToken)
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          processQueue(null, accessToken)
          return axios(originalRequest)
        } else {
          removeCookies(TOKEN_KEY.ACCESS_TOKEN)
          removeCookies(TOKEN_KEY.REFRESH_TOKEN)
          // const _window: any = window;
          // _window.location = '/';
          processQueue(new Error('Failed to refresh access token'))
          // Redirect to login page or show authentication error
        }
      } catch (refreshError) {
        removeCookies(TOKEN_KEY.ACCESS_TOKEN)
        removeCookies(TOKEN_KEY.REFRESH_TOKEN)
        // const _window: any = window;
        // _window.location = '/';
        processQueue(refreshError)
        // Redirect to login page or show authentication error
      } finally {
        isRefreshing = false
      }
    }
    return Promise.reject(error)
  },
)

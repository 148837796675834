import { Stack, Typography } from '@mui/material'
import { EmptyUI } from 'components'
import TestimonialItem from 'components/common/TestimonialItem'
import { ShowMoreBtn } from 'components/common/styles/nanny'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { DATA_PAGINATION, NANNY_TESTIMONIAL } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { testimonialApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'

export const Testimonials = () => {
  const { nanny } = useAuth()
  const dispatch = useAppDispatch()

  const [listTestimonial, setListTestimonial] =
    useState<DATA_PAGINATION<NANNY_TESTIMONIAL>>()

  const isHasMore = useMemo(
    () =>
      listTestimonial && listTestimonial.pageNum < listTestimonial.totalPages,
    [listTestimonial],
  )

  const getTestimonials = async (pageNum: number = 1, pageSize = 8) => {
    const data: DATA_PAGINATION<NANNY_TESTIMONIAL> = await testimonialApi.nanny
      .getTestimonials({ pageNum, pageSize, nannyId: nanny.nannyId })
      .then(({ data }) => data)
    return data
  }

  const fetchInit = async () => {
    dispatch(setGlobalLoading(true))
    await getTestimonials()
      .then((data) => setListTestimonial(data))
      .catch(() => {})
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  const onLoadMore = async () => {
    if (!isHasMore) return
    dispatch(setGlobalLoading(true))
    await getTestimonials(listTestimonial.pageNum + 1)
      .then((data) => {
        setListTestimonial((pre) => ({
          ...data,
          data: [...pre.data, ...data.data],
        }))
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nanny?.nannyId])

  return (
    <Stack spacing={2.25}>
      <Typography variant="Web_Title_22">Testimonials</Typography>
      <Stack spacing={2}>
        {!!listTestimonial?.data.length ? (
          <>
            {listTestimonial.data?.map((item, index) => (
              <TestimonialItem key={index} item={item} />
            ))}
            {isHasMore && (
              <ShowMoreBtn onClick={onLoadMore} variant="contained" fullWidth>
                Load More Testimonials
              </ShowMoreBtn>
            )}
          </>
        ) : (
          <EmptyUI content="You don’t have any testimonials yet" />
        )}
      </Stack>
    </Stack>
  )
}

export default Testimonials

import { SvgIcon, SvgIconProps } from '@mui/material';

export function ChevronDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40401 8.28799L5.99001 9.70199L12 15.713Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default ChevronDownIcon;

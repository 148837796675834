import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { theme } from 'theme';

export const ContentBox = styled(Box)`
  padding-top: 116px;
  max-width: 356px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${theme.breakpoints.down('lg')} {
    padding: 26px 0;
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: ${theme.palette.label.primary};
`;

export const SubTitle = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: 0.1px;
  color: #959595;
  text-align: center;
  margin-bottom: 24px;
`;

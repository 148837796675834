import { ParentAuthLayout } from 'components'
import { Helmet } from 'react-helmet-async'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { ParentRequireAnonymous } from 'routers/parent'
import { APP_ROUTES } from 'routers/routes'
import CheckCode from './pages/CheckCode'
import ConfirmVerify from './pages/ConfirmVerify'
import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import PhoneVerify from './pages/PhoneVerify'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'

const configRoutes: RouteObject[] = [
  {
    path: '*',
    element: (
      <ParentRequireAnonymous>
        <ParentAuthLayout />
      </ParentRequireAnonymous>
    ),
    children: [
      {
        path: APP_ROUTES.PARENT.AUTH.LOGIN.path,
        element: (
          <>
            <Login />
            <Helmet>
              <title>
                Connecting Parents to Trustworthy Confinement Nannies | Aunty
              </title>
              <meta
                name="description"
                content="Aunty Confinement Parent Login Page"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.PARENT.AUTH.SIGN_UP.path,
        element: (
          <>
            <Register />
            <Helmet>
              <title>Aunty Confinement Parent Sign Up Page</title>
              <meta
                name="description"
                content="Confinement Nanny (Parent) Registration Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.PARENT.AUTH.VERIFY.path,
        element: <PhoneVerify />,
      },
      {
        path: APP_ROUTES.PARENT.AUTH.FORGOT_PASSWORD.path,
        element: (
          <>
            <ForgotPassword />
            <Helmet>
              <title>Aunty Confinement Parent Forgot Password Page</title>
              <meta
                name="description"
                content="Confinement Nanny (Parent) Forgot Password Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.PARENT.AUTH.RESET_PASSWORD.path,
        element: <ResetPassword />,
      },
      {
        path: APP_ROUTES.PARENT.AUTH.CHECK_CODE.path,
        element: <CheckCode />,
      },
      {
        path: APP_ROUTES.PARENT.AUTH.VERIFIED.path,
        element: <ConfirmVerify />,
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.PARENT.AUTH.LOGIN.to} replace />,
      },
    ],
  },
]

function AuthFeature() {
  const routes = useRoutes(configRoutes)
  return <>{routes}</>
}

export default AuthFeature

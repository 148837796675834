import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { USER_TYPE } from 'models'
import { NavLink } from 'react-router-dom'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface StyledNavLinkProps {
  $typeUser: USER_TYPE
}

export const StyledNavLink = styled(
  NavLink,
  transientOptions,
)<StyledNavLinkProps>`
  text-decoration: none;
  padding: 10px 24px 8px 24px;
  background-color: ${theme.palette.background.light};
  border-radius: 5px;
  color: ${theme.palette.grey2.dark};
  &.active {
    background-color: ${({ $typeUser }) =>
      $typeUser === USER_TYPE.PARENT
        ? theme.palette.primary.main
        : theme.palette.secondary.main};
    color: ${theme.palette.white.light};
  }
`

export const StyledNavLinkDisable = styled(Typography)`
  text-decoration: none;
  padding: 10px 24px 8px 24px;
  background-color: ${theme.palette.background.light};
  border-radius: 5px;
  color: ${theme.palette.grey2.dark};
  opacity: 0.5;
`

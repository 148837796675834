import _ from 'lodash'
import {
  URLSearchParamsInit,
  createSearchParams,
  useNavigate,
} from 'react-router-dom'

export function useNavigateQuery() {
  const navigate = useNavigate()
  return (pathname: string, params?: URLSearchParamsInit) =>
    navigate({
      pathname,
      search: !_.isEmpty(params) ? `?${createSearchParams(params)}` : undefined,
    })
}

export default useNavigateQuery

import { OPTION_ITEM } from 'models/common'
import { NANNY_TESTIMONIAL } from 'models/nanny/testimonial'

export enum BOOKING_PARENT_STATUS {
  SUBMITTED = 'SUBMITTED',
  NANNIES_MATCHED = 'NANNIES_MATCHED',
  NANNY_CONFIRMED = 'NANNY_CONFIRMED',
  DEPOSIT_PAID = 'DEPOSIT_PAID',
  COMMENCED = 'COMMENCED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const bookingParentStatusTitle = {
  [BOOKING_PARENT_STATUS.SUBMITTED]: 'Submitted',
  [BOOKING_PARENT_STATUS.NANNIES_MATCHED]: 'Matched',
  [BOOKING_PARENT_STATUS.NANNY_CONFIRMED]: 'Confirmed',
  [BOOKING_PARENT_STATUS.DEPOSIT_PAID]: 'Deposit Paid',
  [BOOKING_PARENT_STATUS.COMMENCED]: 'Commenced',
  [BOOKING_PARENT_STATUS.COMPLETED]: 'Completed',
  [BOOKING_PARENT_STATUS.CANCELLED]: 'Cancelled',
  [BOOKING_PARENT_STATUS.DELETED]: 'Deleted',
}

export const bookingParentStatusColor = {
  [BOOKING_PARENT_STATUS.SUBMITTED]: 'yellow.light',
  [BOOKING_PARENT_STATUS.NANNIES_MATCHED]: 'secondary.main',
  [BOOKING_PARENT_STATUS.NANNY_CONFIRMED]: 'secondary.main',
  [BOOKING_PARENT_STATUS.DEPOSIT_PAID]: 'secondary.main',
  [BOOKING_PARENT_STATUS.COMMENCED]: 'green.light',
  [BOOKING_PARENT_STATUS.COMPLETED]: 'green.light',
  [BOOKING_PARENT_STATUS.CANCELLED]: '#B6B6B6',
  [BOOKING_PARENT_STATUS.DELETED]: '#B6B6B6',
}

export interface PARENT_BOOKING {
  bookingId: string
  duration: number
  parentStatus: BOOKING_PARENT_STATUS
  parentId: string
  houseType: string
  edd: string
  commencementDate: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export enum ACTIVITY_LOG_TYPE {
  BOOKING_CREATED = 'BOOKING_CREATED',
  NANNY_SELECTED = 'NANNY_SELECTED',
  ADMIN_NOTE = 'ADMIN_NOTE',
  PAYMENT_PARENT_AUNTY = 'PAYMENT_PARENT_AUNTY',
}

export const activityLogTypeName = {
  [ACTIVITY_LOG_TYPE.BOOKING_CREATED]: 'Booking created',
  [ACTIVITY_LOG_TYPE.NANNY_SELECTED]: 'Nanny selected',
  [ACTIVITY_LOG_TYPE.ADMIN_NOTE]: 'Admin note',
  [ACTIVITY_LOG_TYPE.PAYMENT_PARENT_AUNTY]: 'Payment parent aunty',
}

export interface BOOKING_ACTIVITY {
  amount?: number
  description?: string
  id: string
  type: ACTIVITY_LOG_TYPE
  createdAt: string
  updatedAt: string
}

export enum NANNY_IN_BOOKING_STATUS {
  AVAILABLE = 'AVAILABLE',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const nannyInBookingStatusName = {
  [NANNY_IN_BOOKING_STATUS.AVAILABLE]: 'Available',
  [NANNY_IN_BOOKING_STATUS.ASSIGNED]: 'Assigned',
  [NANNY_IN_BOOKING_STATUS.DELETED]: 'Deleted',
  [NANNY_IN_BOOKING_STATUS.CANCELLED]: 'Cancelled',
}

export const nannyInBookingStatusColor = {
  [NANNY_IN_BOOKING_STATUS.AVAILABLE]: 'yellow.light',
  [NANNY_IN_BOOKING_STATUS.ASSIGNED]: 'secondary.main',
  [NANNY_IN_BOOKING_STATUS.DELETED]: '#B6B6B6',
  [NANNY_IN_BOOKING_STATUS.CANCELLED]: '#B6B6B6',
}

export interface BOOKING_AVAILABLE_NANNY {
  id: string
  nannyFirstName: string
  profilePicture: string
  nannyId: string
  status: NANNY_IN_BOOKING_STATUS
  totalAmount: number
  createdAt: string
  age: string
  nationality: string
  yearsOfExp: string
  testimonial: NANNY_TESTIMONIAL
}

export interface PARENT_BOOKING_DETAIL {
  bookingId: string
  parentFirstName: string
  status: BOOKING_PARENT_STATUS
  edd: string
  commencementDate: string
  duration: number
  singleTwinPregnancy: string
  houseType: string
  pets: boolean
  otherRequirements: string
  activityList: BOOKING_ACTIVITY[]
  availableNannies: BOOKING_AVAILABLE_NANNY[]
  createdAt: string
}

export interface PARENT_CREATE_BOOKING {
  parentId: string
  edd: string
  singleTwinPregnancy: string
  houseType: string
  pets: boolean
  duration: number
  otherRequirements: string
}

export const singleTwinPregnancyOptions: OPTION_ITEM<string>[] = [
  { id: 'Single', name: 'Single' },
  { id: 'Twin', name: 'Twin' },
  { id: 'More than two', name: 'More than two' },
]

export const houseTypeOptions: OPTION_ITEM<string>[] = [
  { id: '1 floor', name: '1 floor' },
  { id: '2 floors', name: '2 floors' },
  { id: '3 or more floors', name: '3 or more floors' },
]

export interface PARENT_CREATE_TESTIMONIAL_REQUEST {
  nannyId: string
  parentId: string
  bookingId: string | null
  stars: number
  content?: string | null
  isAnonymous: boolean
}

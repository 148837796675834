import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { ChevronDownIcon, XIcon } from 'assets';
import { useDebounce } from 'hooks';
import { METADATA, typedMemo } from 'models';
import { CSSProperties, useEffect, useState } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';
import ReactSelect, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  StylesConfig,
  components,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export const customStyles = <Option, IsMulti extends boolean = boolean>(
  theme: Theme,
  isError?: boolean
): StylesConfig<Option, IsMulti> => ({
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '> div': {
      padding: '6px 16px 6px 4px',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '6px 0px 6px',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    padding: '6px 0px 6px !important',
  }),
  option: (provided, state) => ({
    ...provided,
    ...provided,
    fontStyle: 'normal',
    fontSize: 13,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: theme.palette.text.primary,
    background: theme.palette.white.light,
    ...(state.isFocused || state.isSelected
      ? {
          color: theme.palette.white.light,
          background: theme.palette.secondary.main,
        }
      : {}),
    ':active': {
      color: theme.palette.white.light,
      background: theme.palette.secondary.main,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: '#B6B6B6',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '13px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: theme.palette.text.primary,
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    borderRadius: '5px',
    borderColor: theme.palette.other.stroke,
    boxShadow: 'unset',
    background: theme.palette.backgrounds.primary,
    ':hover': {
      ...(!isError && !state.isDisabled
        ? {
            padding: '0px',
            borderColor: theme.palette.other.stroke,
          }
        : isError
        ? {
            borderColor: theme.palette.red.light,
          }
        : {
            borderColor: theme.palette.other.stroke,
          }),
    },
    ...(!isError && !state.isDisabled && state.isFocused
      ? {
          padding: '0px',
          borderColor: theme.palette.other.stroke,
        }
      : {}),
    ...(state.isDisabled
      ? {
          background: theme.palette.backgrounds.tertiary,
        }
      : {}),
    ...(isError
      ? {
          borderColor: theme.palette.red.light,
        }
      : {}),
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
  }),
  loadingMessage: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 999999,
  }),
  menu: (provided) => ({
    ...provided,
  }),
});

export const DropdownIndicator = <Option, IsMulti extends boolean = boolean>(
  props: DropdownIndicatorProps<Option, IsMulti>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        sx={{
          color: props.isDisabled ? '#2E3A59' : '#2E3A59',
          transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
          fontSize: '24px',
        }}
      />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = <Option, IsMulti extends boolean = boolean>(
  props: ClearIndicatorProps<Option, IsMulti>
) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      <XIcon sx={{ color: '#2E3A59', fontSize: '16px' }} />
    </div>
  );
};

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <XIcon sx={{ fontSize: '14px' }} />
    </components.MultiValueRemove>
  );
};

export interface SelectFieldProps<Option, IsMulti extends boolean = boolean>
  extends StateManagerProps<Option, IsMulti> {
  label?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  name?: string;
  control?: Control<any>;
  hideHelper?: boolean;
  errorMess?: string;
  rootProps?: FormControlProps;
  getErrorMess?: (error: FieldError, value: string) => string;
  bindKey?: string;
  bindLabel?: string;
  isHasMore?: boolean;
  filterFunc?: boolean;
  loadOptionInit?: boolean;
  refreshWhenOpen?: boolean;
  helperTextProps?: FormHelperTextProps;
  getOptions?: (
    pageIndex?: number,
    pageSize?: number,
    keyword?: string
  ) => Promise<GetOptionsRes | null | undefined> | null | undefined;
}

export interface GetOptionsRes {
  options: any;
  metadata: METADATA;
}

export const SelectField = typedMemo(
  <Option, IsMulti extends boolean = boolean>({
    label,
    name,
    fullWidth = true,
    rootProps = {},
    value: externalValue,
    onChange: externalOnChange,
    control,
    hideHelper,
    helperTextProps,
    errorMess,
    getErrorMess,
    bindKey,
    refreshWhenOpen,
    bindLabel,
    isHasMore,
    filterFunc = true,
    getOptions,
    loadOptionInit,
    ...rest
  }: SelectFieldProps<Option, IsMulti>) => {
    const {
      field: { onChange, onBlur, value },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
            onBlur: undefined,
          },
          fieldState: { error: undefined },
        };

    const theme = useTheme();

    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>();
    const [options, setOptions] = useState<any[]>([]);
    const [metadata, setMetadata] = useState<METADATA>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onMenuScrollToBottom = useDebounce(async () => {
      if (
        isLoading ||
        !isHasMore ||
        !getOptions ||
        !metadata ||
        metadata.pageIndex >= metadata.totalPages ||
        !options?.length
      )
        return;
      setIsLoading(true);
      const newPage = metadata.pageIndex + 1;
      const data = await getOptions(newPage, metadata.pageSize, keyword);
      setOptions((pre) => pre.concat(data?.options || []));
      setMetadata(data?.metadata);
      setIsLoading(false);
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onInputChange = useDebounce(async (newValue: string) => {
      if (!isHasMore || !getOptions || (!keyword && !newValue)) return;
      setKeyword(newValue);
      if (!isLoading) {
        setIsLoading(true);
        const data = await getOptions(1, metadata?.pageSize, newValue);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      }
    }, 200);

    const onMenuOpen = async () => {
      if (
        !isHasMore ||
        isLoading ||
        (options?.length && !refreshWhenOpen) ||
        !getOptions
      )
        return;
      setIsLoading(true);
      const data = await getOptions(1, metadata?.pageSize);
      setMetadata(data?.metadata);
      setOptions(data?.options);
      setIsLoading(false);
      setKeyword('');
    };

    useEffect(() => {
      const getOptionsInit = async () => {
        if (!isHasMore || !getOptions) return;
        setIsLoading(true);
        const data = await getOptions(1, metadata?.pageSize);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      };
      if (loadOptionInit) {
        getOptionsInit();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} {...rootProps}>
        {label && (
          <Stack flexDirection="row" alignItems="center">
            <FormLabel focused={isFocus}>{label}</FormLabel>
          </Stack>
        )}
        <ReactSelect
          value={value ?? null}
          menuPosition="fixed"
          classNamePrefix="nanny_react_select"
          styles={customStyles<Option, IsMulti>(theme, !!error)}
          getOptionValue={(option: any) => option[bindKey || 'id']}
          getOptionLabel={(option: any) => option[bindLabel || 'name']}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          isLoading={isLoading}
          options={options}
          filterOption={
            filterFunc
              ? (option: any, input) => {
                  return (option.data[bindLabel || 'name'] || '')
                    .toLocaleLowerCase()
                    .includes((input || '').toLocaleLowerCase());
                }
              : () => true
          }
          onMenuOpen={onMenuOpen}
          onInputChange={onInputChange}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            onBlur?.();
            setIsFocus(false);
          }}
          onChange={(...t) => {
            if (control) (externalOnChange as any)?.(...t);
            onChange(...t);
          }}
          {...rest}
        />
        {!hideHelper && (error || errorMess) && (
          <FormHelperText
            error={!!error || !!errorMess}
            sx={{ textAlign: label ? 'left' : 'right' }}
            {...helperTextProps}
          >
            {getErrorMess
              ? getErrorMess(error, value)
              : error?.message || errorMess}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default SelectField;

import styled from '@emotion/styled'
import { ToggleButton, ToggleButtonGroup, css } from '@mui/material'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

interface ToggleButtonGroupStyledProps {
  $error?: boolean
}

export const ToggleButtonGroupStyled = styled(
  ToggleButtonGroup,
  transientOptions,
)<ToggleButtonGroupStyledProps>`
  padding: 8px 16px;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid ${theme.palette.other.stroke};
  background: ${theme.palette.background.light};
  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid ${theme.palette.red.light};
    `}
`
export const ToggleButtonStyled = styled(ToggleButton)`
  height: 32px;
  border-radius: 5px !important;
  background-color: ${theme.palette.grey6.light};
  border: none;
  text-transform: none;
  &.Mui-selected {
    background-color: ${theme.palette.primary.main} !important;
    color: ${theme.palette.white.light};
  }
`

import { API } from 'config'
import { DATA_PAGINATION, ResponseType } from 'models'
import {
  NANNY_CREATE_TESTIMONIAL_REQUEST,
  NANNY_GET_TESTIMONIALS_REQUEST,
  NANNY_TESTIMONIAL,
} from 'models/nanny/testimonial'
import { APIInstance } from './configApi'

export const testimonialApi = {
  nanny: {
    getTestimonials: (params: NANNY_GET_TESTIMONIALS_REQUEST) => {
      return APIInstance.get<DATA_PAGINATION<NANNY_TESTIMONIAL>>(
        API.NANNY.TESTIMONIAL.LIST,
        {
          params,
        },
      )
    },
    createTestimonial: (data: NANNY_CREATE_TESTIMONIAL_REQUEST) => {
      return APIInstance.post<ResponseType<any>>(API.NANNY.TESTIMONIAL.CREATE, {
        data,
      })
    },
  },
}

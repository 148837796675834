import { splitNumber } from "helpers";
import * as yup from "yup";
import { AnyObject, Maybe, Message } from "yup/lib/types";

yup.addMethod<yup.StringSchema>(yup.string, 'phone', function (msg) {
  return this.test("isValidPhone", msg, function (value) {
    const { path, createError } = this;
    const phone = splitNumber(value || '')
    if (phone && (!phone?.code || !phone?.number)) {
      return createError({ path, message: msg ?? "Invalid phone" })
    }
    return true
  })
})

yup.addMethod<yup.NumberSchema>(yup.number, 'empty', function () {
  return this.transform((val, originalVal) => originalVal === '' ? undefined : val)
})
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    phone(msg?: Message<{}>): StringSchema<TType, TContext>;
    emails(msg?: Message<{}>): StringSchema<TType, TContext>;

  }
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    empty(): NumberSchema<TType, TContext>;
  }
}

export default yup;
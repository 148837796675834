import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { Navigate, Outlet } from 'react-router'
import { APP_ROUTES } from 'routers/routes'

interface Props {
  children: any
}

export const ParentRequireAuth = ({ children }: Props) => {
  const { isLoggedIn, userType, nanny, authRedirect } = useAuth()

  if (authRedirect.current) {
    if (!isLoggedIn) {
      return <Navigate to={APP_ROUTES.PARENT.AUTH.LOGIN.to} replace />
    }
    if (userType !== USER_TYPE.PARENT) {
      if (nanny?.status === NANNY_STATUS.VERIFIED) {
        return <Navigate replace to={APP_ROUTES.NANNY.COMPLETE_PROFILE.to} />
      }
      return <Navigate to={APP_ROUTES.NANNY.BOOKING.to} replace />
    }
  }

  return <>{children ? children : <Outlet />}</>
}

export default ParentRequireAuth

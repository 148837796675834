import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'

interface Props {
  isPending?: boolean
  children: any
  isEditProfile?: boolean
}

export const NannyRequireAuth = ({
  isEditProfile = false,
  isPending = false,
  children,
}: Props) => {
  const { isLoggedIn, userType, nanny, authRedirect } = useAuth()
  if (authRedirect.current) {
    if (!isLoggedIn) {
      return <Navigate to={APP_ROUTES.NANNY.AUTH.LOGIN.to} replace />
    }
    if (userType !== USER_TYPE.NANNY) {
      return <Navigate to={APP_ROUTES.PARENT.BOOKING.to} replace />
    }
    if (!isPending && nanny?.status === NANNY_STATUS.VERIFIED) {
      return <Navigate replace to={APP_ROUTES.NANNY.COMPLETE_PROFILE.to} />
    }
    if (!isEditProfile && nanny?.status === NANNY_STATUS.PENDING_APPROVAL) {
      return <Navigate replace to={APP_ROUTES.NANNY.PROFILE.to} />
    }
  }
  return <>{children ? children : <Outlet />}</>
}

export default NannyRequireAuth

import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { theme } from 'theme'

export const ShowMoreBtn = styled(Button)`
  border-radius: 5px;
  background-color: ${theme.palette.secondary_shades[20]};
  color: ${theme.palette.secondary.main};
  &:hover {
    background-color: ${theme.palette.secondary_shades[30]};
  }
`

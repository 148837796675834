import { Theme } from '@mui/material/styles'

export default function Table(_: Theme): Theme['components'] {
  return {
    MuiTable: {},
    MuiTableBody: {},
    MuiTableCell: {},
    MuiTableRow: {},
  }
}

import { NannyAuthLayout } from 'components'
import { Helmet } from 'react-helmet-async'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { NannyRequireAnonymous, NannyRequireAuth } from 'routers/nanny'
import { APP_ROUTES } from 'routers/routes'
import CheckCode from './pages/CheckCode'
import Completed from './pages/Completed'
import EmailVerify from './pages/EmailVerify'
import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import PhoneVerify from './pages/PhoneVerify'
import Register from './pages/Register'
import ResetPassword from './pages/ResetPassword'

const configRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.NANNY.AUTH.COMPLETED.path,
    element: (
      <NannyRequireAuth isPending>
        <NannyAuthLayout>
          <Completed />
        </NannyAuthLayout>
      </NannyRequireAuth>
    ),
  },
  {
    path: '*',
    element: (
      <NannyRequireAnonymous>
        <NannyAuthLayout />
      </NannyRequireAnonymous>
    ),
    children: [
      {
        path: APP_ROUTES.NANNY.AUTH.LOGIN.path,
        element: (
          <>
            <Login />
            <Helmet>
              <title>Find Confinement Nanny Jobs | Aunty</title>
              <meta
                name="description"
                content="Aunty Confinement Nanny Login Page"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.NANNY.AUTH.REGISTER.path,
        element: (
          <>
            <Register />
            <Helmet>
              <title>Aunty Confinement Nanny Sign Up Page</title>
              <meta
                name="description"
                content="Confinement Nanny (Nanny) Registration Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.NANNY.AUTH.PHONE_VERIFY.path,
        element: <PhoneVerify />,
      },
      {
        path: APP_ROUTES.NANNY.AUTH.EMAIL_VERIFY.path,
        element: <EmailVerify />,
      },
      {
        path: APP_ROUTES.NANNY.AUTH.FORGOT_PASSWORD.path,
        element: (
          <>
            <ForgotPassword />
            <Helmet>
              <title>Aunty Confinement Nanny Sign Up Page</title>
              <meta
                name="description"
                content="Confinement Nanny (Nanny) Sign Up Page | Aunty SG"
              />
            </Helmet>
          </>
        ),
      },
      {
        path: APP_ROUTES.NANNY.AUTH.CHECK_CODE.path,
        element: <CheckCode />,
      },
      {
        path: APP_ROUTES.NANNY.AUTH.RESET_PASSWORD.path,
        element: <ResetPassword />,
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.NANNY.AUTH.LOGIN.to} replace />,
      },
    ],
  },
]

function AuthFeature() {
  const routes = useRoutes(configRoutes)
  return <>{routes}</>
}

export default AuthFeature

import { API } from 'config'
import { DATA_PAGINATION, ResponseType } from 'models'
import {
  NANNY_GET_PHOTOS_REQUEST,
  NANNY_PHOTO,
  NANNY_UPLOAD_PHOTO_REQUEST,
} from 'models/nanny/photo'
import { APIInstance } from './configApi'

export const photoApi = {
  nanny: {
    getPhotos: (params: NANNY_GET_PHOTOS_REQUEST) => {
      return APIInstance.get<DATA_PAGINATION<NANNY_PHOTO>>(
        API.NANNY.IMAGE.LIST,
        {
          params,
        },
      )
    },
    deletePhoto: (id: string) => {
      return APIInstance.delete<ResponseType<any>>(API.NANNY.IMAGE.DELETE, {
        data: { imageId: id },
      })
    },
    uploadPhoto: (data: NANNY_UPLOAD_PHOTO_REQUEST) => {
      const _data = new FormData()
      _data.append('id', data.id)
      _data.append('caption', data.caption)
      _data.append('file', data.file)
      return APIInstance.post<ResponseType<any>>(
        API.NANNY.IMAGE.UPLOAD,
        _data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    },
  },
}

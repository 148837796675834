import {
  Avatar,
  Box,
  Container,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { APP_IMG, BoldInfoCircle, LogoIcon, MenuMobileIcon } from 'assets'
import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { theme } from 'theme'
import MenuMobile from '../Menu/MenuMobile'
import AccountPendingInfo from './components/AccountPendingInfo'
import { MenuStyled, StatusAccount } from './styles'

export const Header = () => {
  const { userType, nanny, parent, logout } = useAuth()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [openStatusInfo, setOpenStatusInfo] = useState<boolean>(false)
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setOpenMenu(false)
  }, [location])

  const renderSmallProfile = () => {
    switch (userType) {
      case USER_TYPE.PARENT:
        return (
          <>
            <Box>
              <Typography
                component="p"
                textAlign="right"
                variant="Small_Profile_14"
              >
                Hello Parent
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                component="p"
                variant="Small_Profile_16"
              >
                {parent.full_name}
              </Typography>
            </Box>
            <IconButton onClick={handleClick}>
              <Avatar
                alt="avatar_parent"
                src={parent?.profile_picture || APP_IMG.parentDefaultAvatar}
                sx={{ width: 35, height: 35 }}
              />
            </IconButton>
          </>
        )

      default:
        return (
          <>
            <Box textAlign="right">
              <Typography component="p" variant="Small_Profile_14">
                Hello Nanny
              </Typography>
              <Typography
                color={theme.palette.secondary.main}
                component="p"
                variant="Small_Profile_16"
              >
                {`${nanny?.firstName} ${nanny?.lastName}`.trim()}
              </Typography>
            </Box>
            <IconButton onClick={handleClick}>
              <Avatar
                alt="avatar_nanny"
                src={nanny?.profilePicture || APP_IMG.nannyDefaultAvatar}
                sx={{ width: 35, height: 35 }}
              />
            </IconButton>
          </>
        )
    }
  }

  return (
    <Box
      pt={2.25}
      pb={{ xs: 2, md: 3 }}
      component="section"
      position="sticky"
      top={0}
      zIndex={10}
      borderBottom="1px solid var(--light-other-stroke, rgba(0, 0, 0, 0.08))"
      bgcolor={theme.palette.white.light}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={5} alignItems="center">
            <Link
              to={
                userType === USER_TYPE.PARENT
                  ? APP_ROUTES.PARENT.BOOKING.to
                  : APP_ROUTES.NANNY.BOOKING.to
              }
            >
              <LogoIcon
                sx={{
                  color:
                    userType === USER_TYPE.PARENT
                      ? theme.palette.primary.main
                      : theme.palette.primary.main,
                  width: { xs: '71px', md: '89px' },
                  height: { xs: '51px', md: '63px' },
                }}
              />
            </Link>
            {userType === USER_TYPE.NANNY &&
              [NANNY_STATUS.PENDING_APPROVAL, NANNY_STATUS.VERIFIED].includes(
                nanny?.status,
              ) && (
                <StatusAccount
                  display={{ xs: 'none', md: 'flex' }}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  onClick={() => setOpenStatusInfo(true)}
                >
                  <BoldInfoCircle />
                  <Typography variant="Web_Label_14">
                    Account Pending
                  </Typography>
                </StatusAccount>
              )}
          </Stack>
          <AccountPendingInfo
            open={openStatusInfo}
            onClose={() => setOpenStatusInfo(false)}
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            {renderSmallProfile()}
            <MenuStyled
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={logout}>
                <Typography variant="Title_Menu_14">Logout</Typography>
              </MenuItem>
            </MenuStyled>
            <IconButton
              color={userType === USER_TYPE.PARENT ? 'primary' : 'secondary'}
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '40px',
                height: '40px',
              }}
              aria-label="MenuMobile"
              onClick={() => setOpenMenu(true)}
            >
              <MenuMobileIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <MenuMobile open={openMenu} onClose={() => setOpenMenu(false)} />
    </Box>
  )
}

export default Header

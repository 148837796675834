import { Avatar, Divider, Rating, Stack, Typography } from '@mui/material'
import { APP_IMG, StarIcon } from 'assets'
import { formatDate } from 'helpers'
import { NANNY_TESTIMONIAL } from 'models'
import { memo } from 'react'
import { StyledTestimonialItem } from './styles'

interface TestimonialItemProps {
  item: NANNY_TESTIMONIAL
  disableAvatar?: boolean
}

const TestimonialItem = memo(
  ({ item, disableAvatar = false }: TestimonialItemProps) => {
    return (
      <StyledTestimonialItem spacing={3} direction="row">
        {!disableAvatar && (
          <Avatar
            alt="avatar_parent"
            src={item.parentProfilePicture || APP_IMG.parentDefaultAvatar}
            sx={{ width: 36, height: 36 }}
          />
        )}
        <Stack spacing={1}>
          <Rating
            name="read-only"
            value={item.stars}
            readOnly
            icon={<StarIcon sx={{ color: '#FFCC00' }} />}
            emptyIcon={<StarIcon sx={{ color: '#E5E5EA' }} />}
          />
          {/* <Typography
              fontWeight={700}
              variant="Web_Title_18"
              color={theme.palette.secondary.main}
            >
              Amazing nanny!
            </Typography> */}
          {item.content && (
            <Typography variant="Web_paragraph">{item.content}</Typography>
          )}
          <Divider />
          <Typography sx={{ opacity: 0.6 }} variant="Small_Body_16">
            {`Posted on: ${formatDate(item.createdAt, {
              format: 'DD/MM/YYYY',
            })} by ${item.anonymous ? 'Anonymous' : item.parentFirstName}`}
          </Typography>
        </Stack>
      </StyledTestimonialItem>
    )
  },
)

export default TestimonialItem

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack } from '@mui/material'
import { InputField, UploadImageField } from 'components'
import ModalUI from 'components/common/ModalUI'
import yup from 'config/yup.custom'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import { useForm } from 'react-hook-form'
import { photoApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'

interface IUploadPhotoForm {
  file: File
  caption: string
}

interface Props {
  onClose: () => void
  onSuccess: () => void
}

const schema = yup
  .object({
    file: yup.mixed().required('Photo is required'),
    caption: yup.string().notRequired(),
  })
  .required()

export const PopupUploadPhoto = ({ onClose, onSuccess }: Props) => {
  const { nanny } = useAuth()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.global)
  const { handleSubmit, control } = useForm<IUploadPhotoForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const _onClose = () => {
    onClose()
  }

  const onSubmit = useDebounce(async (data: IUploadPhotoForm) => {
    if (loading || !nanny) return
    dispatch(setGlobalLoading(true))
    await photoApi.nanny
      .uploadPhoto({
        id: nanny.nannyId,
        file: data.file,
        caption: data.caption || '',
      })
      .then(() => {
        onSuccess()
        NotifyService.success('Success')
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <ModalUI
      open
      onClose={_onClose}
      title="Upload photo"
      PaperProps={{ sx: { maxWidth: '483px', width: '100%' } }}
    >
      <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5}>
          <UploadImageField label="Photo" name="file" control={control} />
          <InputField
            rows={3}
            multiline
            control={control}
            name="caption"
            label="Caption"
            placeholder="Describe your picture"
          />
        </Stack>
        <Stack mt={3.75} direction="row" justifyContent="center">
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ width: 200 }}
          >
            Upload
          </Button>
        </Stack>
      </Stack>
    </ModalUI>
  )
}

export default PopupUploadPhoto

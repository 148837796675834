import { Stack, Stepper, Typography, styled } from '@mui/material'
import { theme } from 'theme'

export const Root = styled(Stack)`
  max-width: 417px;
`

export const BoxIcon = styled(Stack)`
  width: 88px;
  height: 88px;
  > svg {
    font-size: 73px;
    color: ${theme.palette.primary.main};
  }
`

export const SubTitle = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.1px;
  text-align: center;
  color: ${theme.palette.text.secondary};
  > span {
    font-weight: 700;
  }
`

export const StyledStepper = styled(Stepper)`
  width: 100%;
  margin: 48px 0px 40px;
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-iconContainer {
        .MuiSvgIcon-root {
          font-size: 28px;
          color: ${theme.palette.grey6.light};
          .MuiStepIcon-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 100% */
            letter-spacing: 0.25px;
            fill: ${theme.palette.label.primary};
            opacity: 0.5;
          }
          &.Mui-active {
            color: ${theme.palette.secondary.main};
            .MuiStepIcon-text {
              opacity: 1;
              fill: ${theme.palette.white.light};
            }
          }
        }
      }
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          margin-top: 10px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.1px;
          color: '#959595';
          &.Mui-active {
            color: ${theme.palette.secondary.main};
            font-weight: 700;
          }
        }
      }
    }
  }
  .MuiStepConnector-root {
    top: 14px;
    left: calc(-50% + 26px);
    right: calc(50% + 26px);
    .MuiStepConnector-line {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
`

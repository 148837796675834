import {
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { CloseCircle } from 'assets'
import { theme } from 'theme'
import { ModalBoxStyled } from './styles'

export interface Props extends DialogProps {
  hideCloseButton?: boolean
  forceAction?: boolean
}

function ModalUI({
  title,
  open,
  onClose,
  children,
  forceAction,
  hideCloseButton,
  ...rest
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog
      open={open}
      onClose={forceAction ? undefined : onClose}
      fullScreen={isMobile}
      PaperProps={{ sx: { maxWidth: '597px', width: '100%' } }}
      {...rest}
    >
      <ModalBoxStyled>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={4.5}
        >
          <Typography variant="Web_Title_22">{title}</Typography>
          {!hideCloseButton && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={(event) => onClose(event, 'backdropClick')}
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              <CloseCircle />
            </IconButton>
          )}
        </Stack>
        {children}
      </ModalBoxStyled>
    </Dialog>
  )
}

export default ModalUI

import { SvgIcon, SvgIconProps } from '@mui/material';

function PlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.414 5.12a.615.615 0 1 0-1.23 0v4.304H5.879a.615.615 0 0 0 0 1.23h4.305v4.305a.615.615 0 1 0 1.23 0v-4.305h4.305a.615.615 0 1 0 0-1.23h-4.305V5.119Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}

export default PlusIcon;

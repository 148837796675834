import { Button, Stack, Typography } from '@mui/material'
import { CheckCircleIcon } from 'assets'
import ModalUISmall from 'components/common/ModalUISmall'
import { ReactNode, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from 'theme'

interface Props {
  onClose: () => void
  title: string
  content: string | ReactNode
  textButton: string
  hrefButton?: string
}

const PopupConfirm = memo(
  ({ onClose, title, content, textButton, hrefButton }: Props) => {
    const navigate = useNavigate()

    const handleClickConfirm = () => {
      onClose()
      if (hrefButton) navigate(hrefButton)
    }

    return (
      <ModalUISmall open hideCloseButton onClose={onClose}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <CheckCircleIcon
            color="primary"
            sx={{ width: '55px', height: '55px' }}
          />
          <Typography variant="Web_Title_18" color={theme.palette.primary.main}>
            {title}
          </Typography>
          <Typography textAlign="center">{content}</Typography>
          <Button onClick={handleClickConfirm} variant="contained">
            {textButton}
          </Button>
        </Stack>
      </ModalUISmall>
    )
  },
)

export default PopupConfirm

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { ArrowDownIcon } from 'assets'
import { USER_COLOR } from 'config'
import { USER_TYPE } from 'models'
import { Fragment, memo } from 'react'
import { theme } from 'theme'
import { StatusPanelStep } from '../StatusPanel'
import { StyledAccordion, StyledStatusDropdown } from './styles'

interface StatusPanelMobileProps {
  bookingId: number | string
  userType: USER_TYPE
  steps: StatusPanelStep[]
}

export const StatusPanelMobile = memo(
  ({ bookingId, userType, steps }: StatusPanelMobileProps) => {
    return (
      <StyledStatusDropdown $userType={userType}>
        <Box className="panel_header">
          <Typography variant="Web_Title_18">Booking #{bookingId}</Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          className="panel_body"
        >
          <StyledAccordion $userType={userType}>
            <AccordionSummary
              expandIcon={
                <ArrowDownIcon sx={{ color: theme.palette.black.light }} />
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                component="p"
                variant="Web_Label_18"
                color={
                  steps[0].activeLabel ? USER_COLOR[userType].main : '#B6B6B6'
                }
              >
                {steps[0].label}
              </Typography>
              {/* <Typography component="p" variant="Small_Body_16" fontWeight={300}>
              {listStep[0].update_date || 'n/a'}
            </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              {steps.map((step, idx) => {
                return (
                  <Fragment key={idx}>
                    {idx !== 0 && (
                      <Box borderTop="1px solid rgba(0, 0, 0, 0.08)" py={2.25}>
                        <Typography
                          component="p"
                          variant="Web_Label_18"
                          color={
                            step.activeLabel
                              ? USER_COLOR[userType].main
                              : '#B6B6B6'
                          }
                        >
                          {step.label}
                        </Typography>
                        {/* <Typography variant="Small_Body_16" fontWeight={300}>
                        {step.update_date || 'n/a'}
                      </Typography> */}
                      </Box>
                    )}
                  </Fragment>
                )
              })}
            </AccordionDetails>
          </StyledAccordion>
        </Stack>
      </StyledStatusDropdown>
    )
  },
)

export default StatusPanelMobile

import moment from 'moment-timezone'

export const formatDate = (
  value: moment.MomentInput,
  options?: { format?: string; empty?: string; valFormat?: string },
) => {
  return value
    ? moment(value, options?.valFormat).format(options?.format || 'DD MMM YYYY')
    : options?.empty || ''
}

export const formatDateParam = (value: moment.MomentInput) => {
  if (!value) return undefined
  return moment(value).format('DD-MM-YYYY')
}

import { Stack, Typography } from '@mui/material'
import { formatPriceToSGD } from 'helpers'
import { theme } from 'theme'
import EarningItem from '../../components/EarningItem'
import { DATA_EARNINGS_BREAKDOWN, DATA_EARNINGS_SUMMARY } from '../../constant'
import { SummaryPanelStyled } from './styles'

export const Earnings = () => {
  const earningSummary = DATA_EARNINGS_SUMMARY
  const earningBreakdown = DATA_EARNINGS_BREAKDOWN
  return (
    <Stack spacing={3.75}>
      <Stack spacing={1}>
        <Typography variant="Web_Title_22">Earning Summary</Typography>
        <SummaryPanelStyled spacing={3}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ xs: 'start', md: 'space-between' }}
            spacing={{ xs: 4, md: 0 }}
          >
            <Stack spacing={1}>
              <Typography
                variant="Web_Label_18"
                color={theme.palette.text.secondary}
              >
                Total Earnings
              </Typography>
              <Typography
                variant="Web_Title_18"
                color={theme.palette.secondary.main}
              >
                {formatPriceToSGD(earningSummary.total_earnings)}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="Web_Label_18"
                color={theme.palette.text.secondary}
              >
                Total Paid
              </Typography>
              <Typography variant="Web_Title_18">
                {formatPriceToSGD(earningSummary.total_paid)}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="Web_Label_18"
                color={theme.palette.text.secondary}
              >
                Total Pending
              </Typography>
              <Typography variant="Web_Title_18">
                {formatPriceToSGD(earningSummary.total_pending)}
              </Typography>
            </Stack>
          </Stack>
          <Stack color={theme.palette.grey3.dark} direction="row" spacing={0.5}>
            <Typography variant="Small_Body_16">Last updated:</Typography>
            <Typography variant="Small_Body_16">
              {earningSummary.last_updated}
            </Typography>
          </Stack>
        </SummaryPanelStyled>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="Web_Title_22">Earning Breakdown</Typography>
        {earningBreakdown.map((item) => (
          <EarningItem key={item.booking_id} item={item} />
        ))}
      </Stack>
    </Stack>
  )
}

export default Earnings

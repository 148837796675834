import styled from '@emotion/styled'
import { Button, Grid, Skeleton, Typography } from '@mui/material'
import { theme } from 'theme'

export const StyledImageItem = styled(Grid)`
  & .image_style {
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
  }
`

export const StyledCaptionImg = styled(Typography)`
  display: block;
  color: ${theme.palette.grey1.light};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`

export const StyledButton = styled(Button)`
  border-radius: 5px;
  background-color: rgba(255, 121, 110, 0.2);
  color: ${theme.palette.primary.main};
  &:hover {
    background-color: rgba(255, 121, 110, 0.3);
  }
`

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 231px;
  ${theme.breakpoints.down('md')} {
    height: 170px;
  }
`

import { API } from 'config'
import {
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  NANNY_CHECK_CODE_DATA,
  NANNY_CHECK_CODE_RESPONSE,
  NANNY_FORGOT_PASSWORD_DATA,
  NANNY_RESET_PASSWORD_DATA,
  PARENT_CHOOSE_ROLE_DATA,
  PARENT_FORGOT_PASSWORD_DATA,
  PARENT_LOGIN_REQUEST,
  PARENT_REGISTER_REQUEST,
  PARENT_RESET_PASSWORD_DATA,
  PARENT_VERIFY_PHONE_RESPONSE,
  REGISTER_REQUEST,
  REGISTER_RESPONSE,
  ResponseType,
  VERIFY_PHONE_RESPONSE,
} from 'models'
import { APIInstanceV2 } from './configAPIV2'
import { APIInstance } from './configApi'

export const authApi = {
  nanny: {
    login: (params: LOGIN_REQUEST) => {
      return APIInstance.post<ResponseType<LOGIN_RESPONSE>>(
        API.NANNY.AUTH.LOGIN,
        params,
      )
    },
    register: (params: REGISTER_REQUEST) => {
      return APIInstance.post<ResponseType<REGISTER_RESPONSE>>(
        API.NANNY.AUTH.REGISTER,
        params,
      )
    },
    verifyPhone: (otp: string, token: string) => {
      return APIInstance.post<ResponseType<VERIFY_PHONE_RESPONSE>>(
        API.NANNY.AUTH.VERIFY_PHONE,
        {
          otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
    },
    verifyEmail: (code: string) => {
      return APIInstance.get<ResponseType<any>>(API.NANNY.AUTH.VERIFY_EMAIL, {
        params: { code },
      })
    },
    resendOTP: (token: string) => {
      return APIInstance.get<ResponseType<any>>(API.NANNY.AUTH.RESEND_OTP, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
    },
    forgotPassword: (data: NANNY_FORGOT_PASSWORD_DATA) => {
      return APIInstance.post<ResponseType<any>>(
        API.NANNY.AUTH.FORGOT_PASSWORD,
        data,
      )
    },
    resetPassword: (data: NANNY_RESET_PASSWORD_DATA, token: string) => {
      return APIInstance.post<ResponseType<any>>(
        API.NANNY.AUTH.RESET_PASSWORD,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
    },
    checkCode: (data: NANNY_CHECK_CODE_DATA) => {
      return APIInstance.post<ResponseType<NANNY_CHECK_CODE_RESPONSE>>(
        API.NANNY.AUTH.CHECK_CODE,
        data,
      )
    },
  },
  parent: {
    login: (params: PARENT_LOGIN_REQUEST) => {
      return APIInstance.post<ResponseType<LOGIN_RESPONSE>>(
        API.PARENT.AUTH.LOGIN,
        params,
      )
    },
    register: (params: PARENT_REGISTER_REQUEST) => {
      return APIInstanceV2.post<ResponseType<any>>(
        API.PARENT.AUTH.REGISTER,
        params,
      )
    },
    resendOTP: (phone: string) => {
      return APIInstanceV2.post<ResponseType<any>>(API.PARENT.AUTH.RESEND_OTP, {
        phone_number: phone,
      })
    },
    verifyPhone: (data: { phone_number: string; code: string }) => {
      return APIInstanceV2.post<ResponseType<PARENT_VERIFY_PHONE_RESPONSE>>(
        API.PARENT.AUTH.VERIFY_PHONE,
        data,
      )
    },
    checkCode: (data: NANNY_CHECK_CODE_DATA) => {
      return APIInstanceV2.post<ResponseType<PARENT_VERIFY_PHONE_RESPONSE>>(
        API.PARENT.AUTH.CHECK_CODE,
        data,
      )
    },
    forgotPassword: (data: PARENT_FORGOT_PASSWORD_DATA) => {
      return APIInstanceV2.post<ResponseType<any>>(
        API.PARENT.AUTH.FORGOT_PASSWORD,
        data,
      )
    },
    resetPassword: (data: PARENT_RESET_PASSWORD_DATA) => {
      return APIInstanceV2.put<ResponseType<any>>(
        API.PARENT.AUTH.RESET_PASSWORD,
        data,
      )
    },
    chooseRole: (data: PARENT_CHOOSE_ROLE_DATA) => {
      return APIInstanceV2.post<ResponseType<any>>(
        API.PARENT.AUTH.CHOOSE_ROLE,
        data,
      )
    },
  },
}

import { Dialog, DialogProps } from '@mui/material'
import { ModalBoxStyled } from './styles'

export interface Props extends DialogProps {
  hideCloseButton?: boolean
  forceAction?: boolean
  width?: string
}

function ModalUISmall({
  title,
  open,
  onClose,
  children,
  forceAction,
  hideCloseButton,
  width = '456px',
  ...rest
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={forceAction ? undefined : onClose}
      // keepMounted={keepMounted}
      {...rest}
    >
      <ModalBoxStyled $widthPopup={width}>{children}</ModalBoxStyled>
    </Dialog>
  )
}

export default ModalUISmall

import styled from '@emotion/styled'
import { Button, ButtonProps, Typography } from '@mui/material'
import { LogoIcon } from 'assets'
import { Link } from 'react-router-dom'
import { theme } from 'theme'

export const StyledLogoIcon = styled(LogoIcon)`
  width: 105.952px;
  height: 75px;
  color: ${theme.palette.primary.main};
`

export const PageTitle = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.408px;
  color: ${theme.palette.label.primary};
`

export const ForgotLink = styled(Link)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.1px;
  color: ${theme.palette.label.primary};
`

export const ButtonSubmit = styled((props: ButtonProps) => (
  <Button color="secondary" variant="contained" type="submit" {...props} />
))`
  width: 174px;
`

export const ButtonBottom: typeof Button = styled((props: ButtonProps) => (
  <Button fullWidth variant="outlined" {...props} />
))`
  background: transparent;
  color: ${theme.palette.text.secondary};
  border: 1px solid ${theme.palette.text.secondary};
  &:hover,
  &:focus {
    color: ${theme.palette.text.secondary};
    background: transparent;
    border: 1px solid ${theme.palette.text.secondary};
  }
` as any

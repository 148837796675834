import styled from '@emotion/styled'
import { Menu, Stack } from '@mui/material'
import { theme } from 'theme'

export const StatusAccount = styled(Stack)`
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${theme.palette.primary.main};
  background: ${theme.palette.white.light};
  color: ${theme.palette.primary.main};
`
export const MenuStyled = styled(Menu)`
  margin-top: 8px;
  & .MuiButtonBase-root {
    padding: 16px;
    width: 141px;
    justify-content: flex-end;
  }
  & .MuiList-root {
    padding: 0px;
  }
`

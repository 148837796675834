import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Stack, Typography } from '@mui/material'
import { DatePickerField, InputField, PhoneNumberField } from 'components'
import {
  ButtonBottom,
  ButtonSubmit,
  PageTitle,
} from 'components/common/styles/auth'
import { TOKEN_KEY } from 'config'
import { NotifyService } from 'helpers'
import { useDebounce, useNavigateQuery } from 'hooks'
import { PHONE_NUMBER } from 'models'
import moment from 'moment-timezone'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import { setJWTCookies } from 'utils'
import * as yup from 'yup'
import LogoAuth from '../../components/LogoAuth'

interface ISignUpForm {
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNumber: PHONE_NUMBER
  dateOfBirth: string
  password: string
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Incorrect Email').notRequired(),
    phoneNumber: yup
      .object({
        countryCode: yup.string().required('Country code is required'),
        phoneNumber: yup.string().required('Phone number is required'),
      })
      .required('Phone number is required'),
    dateOfBirth: yup.mixed().required('Date of birth is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  })
  .required()

export const Register = () => {
  const navigateQuery = useNavigateQuery()
  const dispatch = useAppDispatch()

  const { handleSubmit, control } = useForm<ISignUpForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = useDebounce(async (data: ISignUpForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .register({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        countryCode: data.phoneNumber?.countryCode,
        phoneNumber: data.phoneNumber?.phoneNumber,
        dateOfBirth: data.dateOfBirth
          ? moment(data.dateOfBirth).format('DD-MM-YYYY')
          : '',
        password: data.password,
      })
      .then(
        ({
          data: {
            data: { access_token, refresh_token },
          },
        }) => {
          setJWTCookies(TOKEN_KEY.NANNY.REGISTER.ACCESS_TOKEN, access_token)
          setJWTCookies(TOKEN_KEY.NANNY.REGISTER.REFRESH_TOKEN, refresh_token)
          NotifyService.success('Success')
          navigateQuery(APP_ROUTES.NANNY.AUTH.PHONE_VERIFY.to, {
            phone: `${data.phoneNumber?.countryCode}${data.phoneNumber?.phoneNumber}`,
          })
        },
      )
      .catch((e) => {
        NotifyService.error(e.response.data.message)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Sign up as Nanny 月嫂报名
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Please enter your information correctly.
        <br /> * required fields
      </Typography>
      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          alignItems="flex-start"
        >
          <InputField
            control={control}
            name="firstName"
            placeholder="First name 名字*"
          />
          <InputField
            control={control}
            name="lastName"
            placeholder="Last name 姓名*"
          />
        </Stack>
        <PhoneNumberField
          control={control}
          name="phoneNumber"
          placeholder="Phone number 电话号码*"
        />
        <InputField
          control={control}
          name="email"
          placeholder="Email 电子邮件"
        />
        <DatePickerField
          iconColor="secondary"
          control={control}
          name="dateOfBirth"
          placeholder="Date of birth 出生日期*"
        />
        <InputField
          control={control}
          name="password"
          placeholder="Password 密码*"
          type="password"
        />
      </Stack>
      <Stack
        mt={2}
        spacing={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit>Sign up 报名</ButtonSubmit>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Typography variant="Web_Label_14" sx={{ opacity: 0.5, mb: 1.25 }}>
        Already have an account 已有帐户?
      </Typography>
      <ButtonBottom component={NavLink} to={APP_ROUTES.NANNY.AUTH.LOGIN.to}>
        Login as Nanny 登录
      </ButtonBottom>
    </Stack>
  )
}

export default Register

import {
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  Divider,
  Stack,
} from '@mui/material'
import { memo } from 'react'
import {
  StyledDialogAction,
  StyledDialogContent,
  StyledDialogTitle,
} from './styles'

interface ComfirmDialogProps extends DialogProps {
  title: string
  description: React.ReactNode
  backProps?: ButtonProps
  confirmProps?: ButtonProps
  onClose?: () => void
  onConfirm?: () => void
}

export const ComfirmDialog = memo(
  ({
    title,
    description,
    backProps,
    confirmProps,
    onClose,
    onConfirm,
    ...rest
  }: ComfirmDialogProps) => {
    return (
      <Dialog
        fullWidth
        scroll="body"
        PaperProps={{ sx: { maxWidth: '597px' } }}
        onClose={onClose}
        {...rest}
      >
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <StyledDialogContent>{description}</StyledDialogContent>
        <StyledDialogAction>
          <Divider sx={{ borderColor: 'rgba(0,0,0,0.1)' }} />
          <Stack
            mt={2}
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              sx={{ width: '160px' }}
              color="secondary"
              variant="contained"
              onClick={onClose}
              {...backProps}
            >
              Back
            </Button>
            <Button
              sx={{ width: '164px' }}
              color="primary"
              variant="contained"
              onClick={onConfirm}
              {...confirmProps}
            >
              Confirm
            </Button>
          </Stack>
        </StyledDialogAction>
      </Dialog>
    )
  },
)

export default ComfirmDialog

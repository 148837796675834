import { SvgIcon, SvgIconProps } from '@mui/material';

export function DirectionRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8302 11.2899L10.5902 7.04995C10.4972 6.95622 10.3866 6.88183 10.2648 6.83106C10.1429 6.78029 10.0122 6.75415 9.88019 6.75415C9.74818 6.75415 9.61747 6.78029 9.49561 6.83106C9.37375 6.88183 9.26315 6.95622 9.17019 7.04995C8.98394 7.23731 8.87939 7.49076 8.87939 7.75495C8.87939 8.01913 8.98394 8.27259 9.17019 8.45995L12.7102 11.9999L9.17019 15.5399C8.98394 15.7273 8.87939 15.9808 8.87939 16.2449C8.87939 16.5091 8.98394 16.7626 9.17019 16.9499C9.26363 17.0426 9.37444 17.116 9.49628 17.1657C9.61812 17.2155 9.74858 17.2407 9.88019 17.2399C10.0118 17.2407 10.1423 17.2155 10.2641 17.1657C10.3859 17.116 10.4967 17.0426 10.5902 16.9499L14.8302 12.7099C14.9239 12.617 14.9983 12.5064 15.0491 12.3845C15.0998 12.2627 15.126 12.132 15.126 11.9999C15.126 11.8679 15.0998 11.7372 15.0491 11.6154C14.9983 11.4935 14.9239 11.3829 14.8302 11.2899Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default DirectionRightIcon;

import { API } from 'config'
import {
  NANNY_PROFILE,
  NANNY_RESET_PASSWORD_DATA,
  PARENT_PROFILE,
  ResponseType,
} from 'models'
import {
  NANNY_EDIT_PROFILE,
  NANNY_PROFILE_SUMMARY,
  NANNY_UPDATE_PROFILE_REQUEST,
} from 'models/nanny'
import {
  PARENT_CHANGE_PASSWORD_REQUEST,
  PARENT_UPDATE_PROFILE_REQUEST,
  PARENT_UPLOAD_FILE_RESPONSE,
} from 'models/parent'
import { APIInstanceV2 } from './configAPIV2'
import { APIInstance } from './configApi'

export const profileApi = {
  nanny: {
    getProfile: (id: string) => {
      return APIInstance.get<ResponseType<NANNY_PROFILE>>(
        `${API.NANNY.PROFILE.DEFAULT}/${id}`,
      )
    },
    getEditProfile: (id: string) => {
      return APIInstance.get<ResponseType<NANNY_EDIT_PROFILE>>(
        `${API.NANNY.PROFILE.EDIT}/${id}`,
      )
    },
    updateProfile: (data: NANNY_UPDATE_PROFILE_REQUEST) => {
      return APIInstance.put<ResponseType<any>>(API.NANNY.PROFILE.UPDATE, data)
    },
    uploadPicture: (id: string, file: File) => {
      const data = new FormData()
      data.append('id', id)
      data.append('file', file)
      return APIInstance.post<ResponseType<any>>(
        API.NANNY.PROFILE.UPLOAD_PICTURE,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    },
    getProfileSummary: () => {
      return APIInstance.get<ResponseType<NANNY_PROFILE_SUMMARY>>(
        API.NANNY.PROFILE.SUMMARY,
      )
    },
    changePassword: (data: NANNY_RESET_PASSWORD_DATA) => {
      return APIInstance.post<ResponseType<any>>(
        API.NANNY.AUTH.RESET_PASSWORD,
        data,
      )
    },
  },
  parent: {
    getProfile: () => {
      return APIInstanceV2.get<ResponseType<PARENT_PROFILE>>(
        API.PARENT.PROFILE.DEFAULT,
      )
    },
    updateProfile: (data: PARENT_UPDATE_PROFILE_REQUEST) => {
      return APIInstanceV2.put<ResponseType<any>>(API.PARENT.PROFILE.EDIT, data)
    },
    uploadPicture: (data: any) => {
      return APIInstanceV2.post<ResponseType<PARENT_UPLOAD_FILE_RESPONSE>>(
        API.PARENT.PROFILE.UPLOAD_FILE,
        data,
      )
    },
    changePassword: (data: PARENT_CHANGE_PASSWORD_REQUEST) => {
      return APIInstanceV2.put<ResponseType<any>>(
        API.PARENT.PROFILE.CHANGE_PASSWORD,
        data,
      )
    },
  },
}

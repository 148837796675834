import { Divider, Stack, Typography } from '@mui/material'
import { formatDate } from 'helpers'
import { NANNY_BOOKING_SUMMARY } from 'models'
import { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { theme } from 'theme'
import { StyledBookingItem } from './styles'

interface BookingItemProps {
  item: NANNY_BOOKING_SUMMARY
}

const BookingItem = memo(({ item }: BookingItemProps) => {
  return (
    <StyledBookingItem
      component={NavLink}
      to={APP_ROUTES.NANNY.BOOKING.DETAIL.to(item.bookingId)}
      spacing={2}
    >
      <Stack justifyContent={{ xs: 'start', md: 'space-between' }} spacing={1}>
        <Stack direction="row" spacing={{ xs: 1, md: 0.5 }}>
          <Typography variant="Web_Label_18">Booking#</Typography>
          <Typography variant="Web_Label_18">{item.bookingId}</Typography>
        </Stack>
        <Stack direction="row" spacing={{ xs: 1, md: 0.5 }}>
          <Typography variant="Web_Title_18">Status: </Typography>
          <Typography variant="Web_Title_18" color={theme.palette.primary.main}>
            {item.nannyStatus}
          </Typography>
        </Stack>
        {item.commencementDate && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 1, md: 0.5 }}
          >
            <Typography variant="Web_Label_18">Commencement Date:</Typography>
            <Typography variant="Web_Label_18">
              {formatDate(item.commencementDate, {
                format: 'DD/MM/YYYY',
                valFormat: 'DD-MM-YYYY',
              })}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Divider />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 4, md: 14 }}
      >
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Parent
          </Typography>
          <Typography variant="Web_Label_18">{item.parentFirstName}</Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Expected Due Date:
          </Typography>
          <Typography
            variant="Web_Title_18"
            color={theme.palette.secondary.main}
          >
            {formatDate(item.edd, {
              format: 'DD/MM/YYYY',
              valFormat: 'DD-MM-YYYY',
            })}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Duration
          </Typography>
          <Typography variant="Web_Label_18">{item.duration} Days</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack color={theme.palette.grey3.dark} direction="row" spacing={0.5}>
        <Typography variant="Small_Body_16">Created on:</Typography>
        <Typography variant="Small_Body_16">
          {' '}
          {formatDate(item.createdAt, { format: 'DD/MM/YYYY' })}
        </Typography>
      </Stack>
    </StyledBookingItem>
  )
})

export default BookingItem

import { Stack, Typography } from '@mui/material'
import { ButtonSubmit, PageTitle } from 'components/common/styles/auth'
import { TOKEN_KEY } from 'config'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { removeCookies } from 'utils'
import LogoAuth from '../../components/LogoAuth'

function ConfirmVerify() {
  const navigate = useNavigate()

  const handleClickLogin = () => {
    navigate(APP_ROUTES.PARENT.AUTH.LOGIN.to)
    removeCookies(TOKEN_KEY.PARENT.REGISTER.ACCESS_TOKEN)
  }

  return (
    <Stack>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Phone Number Verified
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Your phone number has been verified successfully
      </Typography>
      <Stack
        mt={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <ButtonSubmit onClick={handleClickLogin} color="primary">
          Login Now
        </ButtonSubmit>
      </Stack>
    </Stack>
  )
}

export default ConfirmVerify

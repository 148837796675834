import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { theme } from 'theme';

export const StyledBoxContent = styled(Box)`
  padding: 18px 51px 21px 28px;
  border-radius: 5px;
  background-color: ${theme.palette.primary_shades[8]};
  ${theme.breakpoints.down('md')} {
    padding: 18px 21px;
  }
`;

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, FormHelperText, Stack, Typography } from '@mui/material'
import { InputField } from 'components'
import {
  ButtonBottom,
  ButtonSubmit,
  ForgotLink,
  PageTitle,
} from 'components/common/styles/auth'
import { useAuth } from 'contexts/auth'
import { NotifyService, getDataError } from 'helpers'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import * as yup from 'yup'
import LogoAuth from '../../components/LogoAuth'
import { StyledSubLogo } from './styles'

interface ILoginForm {
  number: string
  password: string
}

const schema = yup
  .object({
    number: yup.string().required('Phone number is required'),
    password: yup.string().required('Password is required'),
  })
  .required()

export const Login = () => {
  const { login } = useAuth()
  const dispatch = useAppDispatch()

  const [incorrect, setIncorrect] = useState(false)

  const { handleSubmit, control, watch } = useForm<ILoginForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const subscription = watch(() => {
      if (incorrect) setIncorrect(false)
    })
    return () => subscription.unsubscribe()
  }, [watch, incorrect])

  const onSubmit = useDebounce(async (data: ILoginForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .login({
        number: data.number,
        password: data.password,
      })
      .then(({ data: { data } }) => {
        login(data)
      })
      .catch((e) => {
        const res = getDataError<{ error: string }>(e)
        if (res?.error === 'Unauthorized') {
          setIncorrect(true)
        } else NotifyService.error(e)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={0.5} alignItems="center" mb={6}>
        <LogoAuth />
        <Box maxWidth="124px">
          <StyledSubLogo>
            UEN - 202117870K EA License No. (23C1575)
          </StyledSubLogo>
        </Box>
      </Stack>
      <PageTitle variant="h1" mb={1.625}>
        Nanny Login 月嫂登录
      </PageTitle>
      <Stack spacing={2}>
        <InputField
          control={control}
          name="number"
          placeholder="Phone number 电话"
        />
        <Stack>
          <InputField
            control={control}
            name="password"
            placeholder="Password 密码"
            type="password"
          />
          {incorrect && (
            <FormHelperText error sx={{ textAlign: 'right' }}>
              Incorrect phone or password combination
            </FormHelperText>
          )}
        </Stack>
      </Stack>
      <Stack
        mt={2}
        mb={4}
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ForgotLink to={APP_ROUTES.NANNY.AUTH.FORGOT_PASSWORD.to}>
          Forgot Password?
        </ForgotLink>
        <ButtonSubmit>Login 登录</ButtonSubmit>
      </Stack>
      <Divider sx={{ mb: 3 }} />
      <Typography variant="Web_Label_14" sx={{ opacity: 0.5, mb: 1.25 }}>
        Don’t have an account 没有帐户?
      </Typography>
      <ButtonBottom component={NavLink} to={APP_ROUTES.NANNY.AUTH.REGISTER.to}>
        Sign up as Nanny 报名
      </ButtonBottom>
    </Stack>
  )
}

export default Login

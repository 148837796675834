import { APP_ROUTES } from 'routers/routes'

export const menuRouteParent = [
  {
    title: 'Main Menu',
    items: [
      {
        label: 'Bookings',
        path: APP_ROUTES.PARENT.BOOKING.to,
        name: 'bookings',
      },
      {
        label: 'Edit Profile',
        path: APP_ROUTES.PARENT.PROFILE.to,
        name: 'profile',
      },
    ],
  },
]

export const menuRouteNanny = [
  {
    title: 'Dashboard',
    items: [
      {
        label: 'Bookings 预订',
        path: APP_ROUTES.NANNY.BOOKING.to,
        name: 'bookings',
      },
      // {
      //   label: 'Earnings',
      //   path: APP_ROUTES.NANNY.EARNING.to,
      //   name: 'earnings',
      // },
    ],
  },
  {
    title: 'My Profile',
    items: [
      {
        label: 'Photos 相片',
        path: APP_ROUTES.NANNY.PHOTO.to,
        name: 'photos',
      },
      {
        label: 'Testimonials 感言',
        path: APP_ROUTES.NANNY.TESTIMONIAL.to,
        name: 'testimonials',
      },
      {
        label: 'Edit Profile 个人资料',
        path: APP_ROUTES.NANNY.PROFILE.to,
        name: 'profile',
      },
    ],
  },
]

export const NOTIS = 2

import { Stack, Typography } from '@mui/material'
import { NANNY_PROFILE } from 'models'
import { theme } from 'theme'
import { StyledBoxContent } from './styles'

interface SectionAboutMeProps {
  profile: NANNY_PROFILE
}

function SectionAboutMe({ profile }: SectionAboutMeProps) {
  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">About me</Typography>
      <StyledBoxContent>
        <Typography
          variant="Web_paragraph"
          color={theme.palette.text.secondary}
        >
          {profile?.bio}
        </Typography>
      </StyledBoxContent>
    </Stack>
  )
}

export default SectionAboutMe

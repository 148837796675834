import { memo } from 'react'

export type Opt<T> = T | null | undefined
export type Dict<T> = Record<string, T>

//models head table
export type Order = 'asc' | 'desc'

//models response & error from api
export interface ResponseType<T> {
  status: number
  message: string
  data: T
}

export interface DataListResponseType {
  pageNum: number
  pageSize: number
  totalSize: number
  totalPages: number
}

export interface METADATA {
  pageNum: number
  pageSize: number
  totalSize: number
  totalPages: number
}

export interface DATA_PAGINATION<T = any> extends METADATA {
  data: T[]
}

export interface REDUX_ACTION_CALLBACK<T = any> {
  onSuccess?: (data?: T) => void
  onError?: (e: any) => void
}

export interface REDUX_ACTION_PAYLOAD<T1 = any, T2 = any> {
  data: T1
  onSuccess?: (data?: T2) => void
  onError?: (e: any) => void
}

export interface OPTION_ITEM<T extends any = number> {
  id?: T
  name: string
  [key: string]: any
}

export interface PayloadActionData<T1 extends any = any, T2 extends any = any> {
  data?: T1
  onSuccess?: (data?: T2) => void
  onFailed?: (err?: any) => void
  onFinally?: () => void
}

export enum USER_TYPE {
  PARENT = 'PARENT',
  NANNY = 'NANNY',
}

export enum NANNY_ROLE {
  NANNY_TO_BE = 'NANNY_TO_BE',
  NANNY_PENDING_APPROVAL = 'NANNY_PENDING_APPROVAL',
  NANNY_RESET_PASSWORD = 'NANNY_RESET_PASSWORD',
  NANNY = 'NANNY',
}

export enum NANNY_STATUS {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACTIVE = 'ACTIVE',
}

export enum PARENT_ROLE {}

export interface PHONE_NUMBER {
  countryCode: string
  phoneNumber: string
}

export const typedMemo: <T>(
  c: T,
  areEqual?: (
    prevProps: Readonly<React.PropsWithChildren<T>>,
    nextProps: Readonly<React.PropsWithChildren<T>>,
  ) => boolean,
) => T = memo

export interface METADATA {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPages: number
}

export interface DATA_PAGINATION<T = any> extends METADATA {
  data: T[]
}

export enum ACTION_TYPE {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  SUBMITTED = 'submitted',
  CANCEL = 'cancel',
  CANCELLED = 'cancelled',
  CUSTOM = 'custom',
}

export interface MODAL_ACTION<T extends any = any> {
  data?: T
  type: ACTION_TYPE
  custom?: any
}

import { Box, Button, Stack, Typography } from '@mui/material'
import { BoldMessageIcon } from 'assets'
import TestimonialItem from 'components/common/TestimonialItem'
import { BOOKING_AVAILABLE_NANNY } from 'models'
import { memo } from 'react'
import { StyledNannyTestimonial } from '../styles'

interface NannyTestimonialProps {
  nanny: BOOKING_AVAILABLE_NANNY
  onClick: () => void
}

const NannyTestimonial = memo(({ nanny, onClick }: NannyTestimonialProps) => {
  return (
    <>
      {nanny?.testimonial ? (
        <TestimonialItem item={nanny.testimonial} disableAvatar />
      ) : (
        <StyledNannyTestimonial direction="row" spacing={1}>
          <BoldMessageIcon sx={{ opacity: 0.5 }} color={'primary'} />
          <Stack spacing={1}>
            <Box>
              <Typography
                component="p"
                variant="Web_Title_14"
                sx={{ lineHeight: '21px' }}
              >
                How was the experience?
              </Typography>
              <Typography
                variant="Web_paragraph"
                sx={{ opacity: 0.5, lineHeight: '125%', display: 'block' }}
              >
                Write a testimonial for the nanny, share your experience with
                others
              </Typography>
            </Box>
            <Button onClick={onClick} className="btn_view" variant="contained">
              Write a Testimonial
            </Button>
          </Stack>
        </StyledNannyTestimonial>
      )}
    </>
  )
})

export default NannyTestimonial

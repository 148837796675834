import { SvgIcon, SvgIconProps } from '@mui/material'

export function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40627 0.890599C5.7772 0.334202 6.40166 0 7.07037 0H8.92963C9.59834 0 10.2228 0.334202 10.5937 0.8906L11.5 2.25H15.25C15.6642 2.25 16 2.58579 16 3C16 3.41421 15.6642 3.75 15.25 3.75H0.75C0.335786 3.75 0 3.41421 0 3C0 2.58579 0.335786 2.25 0.75 2.25H4.5L5.40627 0.890599ZM11 20H5C2.79086 20 1 18.2091 1 16V5H15V16C15 18.2091 13.2091 20 11 20ZM6 8.25C6.41421 8.25 6.75 8.58579 6.75 9V16C6.75 16.4142 6.41421 16.75 6 16.75C5.58579 16.75 5.25 16.4142 5.25 16L5.25 9C5.25 8.58579 5.58579 8.25 6 8.25ZM10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V9C9.25 8.58579 9.58579 8.25 10 8.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default StarIcon

import { NannyMainLayout } from 'components'
import NannyDetail from 'components/common/NannyDetail'
import { Helmet } from 'react-helmet-async'
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom'
import { NannyRequireAuth } from 'routers/nanny'
import { APP_ROUTES } from 'routers/routes'
import AuthFeature from './auth'
import { DetailBooking, ListBooking } from './bookings'
import { CompleteProfile } from './complete-profile'
import { Earnings } from './earnings'
import { EditProfile } from './editProfile'
import { Photos } from './photos'
import { Testimonials } from './testimonials'

const configRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.NANNY.AUTH.path,
    element: <AuthFeature />,
  },
  {
    path: APP_ROUTES.NANNY.PROFILE.PREVIEW.path,
    element: (
      <NannyRequireAuth>
        <NannyDetail />
      </NannyRequireAuth>
    ),
  },
  {
    path: APP_ROUTES.NANNY.COMPLETE_PROFILE.path,
    element: (
      <NannyRequireAuth isPending>
        <CompleteProfile />
      </NannyRequireAuth>
    ),
  },
  {
    path: '*',
    element: (
      <>
        <NannyMainLayout />
        <Helmet>
          <title>Aunty Confinement</title>
          <meta name="description" content="Aunty Confinement" />
        </Helmet>
      </>
    ),
    children: [
      {
        path: APP_ROUTES.NANNY.BOOKING.path,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <NannyRequireAuth>
                <ListBooking />
              </NannyRequireAuth>
            ),
          },
          {
            path: APP_ROUTES.NANNY.BOOKING.DETAIL.path,
            element: (
              <NannyRequireAuth>
                <DetailBooking />
              </NannyRequireAuth>
            ),
          },
          {
            path: '*',
            element: <Navigate to={APP_ROUTES.NANNY.BOOKING.to} replace />,
          },
        ],
      },
      {
        path: APP_ROUTES.NANNY.EARNING.path,
        element: (
          <NannyRequireAuth>
            <Earnings />
          </NannyRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.NANNY.TESTIMONIAL.path,
        element: (
          <NannyRequireAuth>
            <Testimonials />
          </NannyRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.NANNY.PHOTO.path,
        element: (
          <NannyRequireAuth>
            <Photos />
          </NannyRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.NANNY.PROFILE.path,
        element: (
          <NannyRequireAuth isEditProfile>
            <EditProfile />
          </NannyRequireAuth>
        ),
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.NANNY.BOOKING.to} replace />,
      },
    ],
  },
]

function NannyFeature() {
  const routes = useRoutes(configRoutes)
  return <>{routes}</>
}

export default NannyFeature

import type { ThemeOptions } from '@mui/material/styles';
// import { createBreakpoints } from '@mui/system';

// const breakpoints = createBreakpoints({});
/**
 * Style overrides for Material UI components.
 *
 * @see https://mui.com/customization/theme-components/#global-style-overrides
 */
export const typography: ThemeOptions['typography'] = {
  fontFamily: ['Poppins', 'sans-serif'].join(','),
  // title
  Web_Title_22: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  Web_Title_18: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  Web_Title_14: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
  },

  // label
  Web_Label_18: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  Web_Label_16: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  Web_Label_14: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },

  // order
  Web_paragraph: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '125%',
  },
  Small_Profile_14: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '125%',
  },
  Small_Profile_16: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '125%',
  },
  Title_Menu_14: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '110%',
  },

  // mobile
  Small_Body_16: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },

  // components
  Components_Button: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  Components_Column_tittle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  Components_Column_content: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  Components_UnstyledContainer: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
};

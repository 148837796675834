import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import {
  GET_USER_PROFILE_PARAMS,
  PARENT_PROFILE,
  PayloadActionData,
  ResponseType,
  USER_TYPE,
} from 'models'
import { NANNY_PROFILE_SUMMARY } from 'models/nanny'
import { call, put, takeLatest } from 'redux-saga/effects'
import { profileApi } from 'services'
import { setNannyProfile, setParentProfile } from 'store/reducers/auth'
import { GET_USER_PROFILE_REQUEST } from 'store/reducers/auth/actionTypes'

function* getUserProfileRequest(
  action: PayloadAction<PayloadActionData<GET_USER_PROFILE_PARAMS>>,
) {
  try {
    switch (action.payload.data.userType) {
      case USER_TYPE.NANNY:
        const {
          data: { data: nanny },
        }: AxiosResponse<ResponseType<NANNY_PROFILE_SUMMARY>> = yield call(
          profileApi.nanny.getProfileSummary,
        )
        yield put(setNannyProfile(nanny))
        break
      case USER_TYPE.PARENT:
        const {
          data: { data: parent },
        }: AxiosResponse<ResponseType<PARENT_PROFILE>> = yield call(
          profileApi.parent.getProfile,
        )
        yield put(setParentProfile(parent))
        break
    }
    action.payload.onSuccess?.()
  } catch (error) {
    action.payload.onFailed?.(error)
  } finally {
    action.payload.onFinally?.()
  }
}

function* getUserProfile() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, getUserProfileRequest)
}
export default getUserProfile

import { Typography } from '@mui/material'
import { formatPriceToSGD } from 'helpers'
import { EarningBreakdown } from 'models'
import { theme } from 'theme'
import { EarningItemPanelStyled } from './styles'

interface Props {
  item: EarningBreakdown
}

export const EarningItem = ({ item }: Props) => {
  return (
    <EarningItemPanelStyled direction="row" justifyContent="space-between">
      <Typography variant="Web_Label_18" color={theme.palette.text.secondary}>
        #Booking {item.booking_id}
      </Typography>
      <Typography variant="Web_Title_18">
        {formatPriceToSGD(item.price)}
      </Typography>
    </EarningItemPanelStyled>
  )
}

export default EarningItem

import { API } from 'config'
import {
  NANNY_BOOKING_DETAIL,
  NANNY_BOOKING_SUMMARY,
  PARENT_BOOKING,
  PARENT_BOOKING_DETAIL,
  PARENT_CREATE_BOOKING,
  PARENT_CREATE_TESTIMONIAL_REQUEST,
  ResponseType,
} from 'models'
import { APIInstance } from './configApi'

export const bookingApi = {
  nanny: {
    getSummary: () => {
      return APIInstance.get<ResponseType<NANNY_BOOKING_SUMMARY[]>>(
        API.NANNY.BOOKING.SUMMARY,
      )
    },
    getDetail: (id: string) => {
      return APIInstance.get<ResponseType<NANNY_BOOKING_DETAIL>>(
        `${API.NANNY.BOOKING.DEFAULT}/${id}`,
      )
    },
  },
  parent: {
    getSummary: () => {
      return APIInstance.get<ResponseType<PARENT_BOOKING[]>>(
        API.PARENT.BOOKING.SUMMARY,
      )
    },
    getDetail: (id: string) => {
      return APIInstance.get<ResponseType<PARENT_BOOKING_DETAIL>>(
        `${API.PARENT.BOOKING.DEFAULT}/${id}`,
      )
    },
    create: (data: PARENT_CREATE_BOOKING) => {
      return APIInstance.post<ResponseType<PARENT_BOOKING_DETAIL>>(
        API.PARENT.BOOKING.CREATE,
        data,
      )
    },
    cancel: (id: string) => {
      return APIInstance.put<ResponseType<any>>(
        `${API.PARENT.BOOKING.CANCEL}/${id}`,
      )
    },
    createTestimonial: (data: PARENT_CREATE_TESTIMONIAL_REQUEST) => {
      return APIInstance.post<ResponseType<any>>(
        API.PARENT.BOOKING.CREATE_TESTIMONIAL,
        data,
      )
    },
  },
}

import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { theme } from 'theme'

export const StyledButton = styled(Button)`
  border-radius: 5px;
  background-color: rgba(255, 121, 110, 0.2);
  color: ${theme.palette.primary.main};
  &:hover {
    background-color: rgba(255, 121, 110, 0.3);
  }
`

import { useEffect, useMemo, useRef, useState } from 'react'

import { TOKEN_KEY } from 'config'
import { useAppDispatch, useAppSelector } from 'store/hook'

import { useDebounce } from 'hooks'
import {
  ACCESS_TOKEN_DATA,
  LOGIN_RESPONSE,
  NANNY_ROLE,
  REFRESH_TOKEN_DATA,
  USER_PROFILE,
  USER_TYPE,
} from 'models'
import { NANNY_PROFILE_SUMMARY } from 'models/nanny'
import { setTokenV2 } from 'services/configAPIV2'
import { setToken } from 'services/configApi'
import { setLoadingAuth, setNannyProfile } from 'store/reducers/auth'
import { getUserProfileAction } from 'store/reducers/auth/actionTypes'
import { CookieChangeOptions } from 'universal-cookie'
import { getJWTDecode } from 'utils'
import {
  cookies,
  getCookies,
  removeCookies,
  setRefreshTokenCookies,
  setTokenCookies,
} from 'utils/cookies'
import createContext from 'utils/createContext'

interface AuthContextValues {
  isLoggedIn: boolean
  userType: USER_TYPE
  nanny?: NANNY_PROFILE_SUMMARY
  parent?: USER_PROFILE
  authRedirect: React.MutableRefObject<boolean>
  login: (data: LOGIN_RESPONSE, callback?: () => void) => void
  logout: () => void
}

interface TOKEN_INFO {
  type: USER_TYPE
  nannyId: string
  parentId: string
}

const [Provider, useAuth] = createContext<AuthContextValues>({
  name: 'auth',
})

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch()
  const { parent, nanny } = useAppSelector((state) => state.auth)

  const getTokenInfo = () => {
    const tokenDecode = getJWTDecode<ACCESS_TOKEN_DATA>(
      getCookies(TOKEN_KEY.ACCESS_TOKEN),
    )
    const refreshTokenDecode = getJWTDecode<REFRESH_TOKEN_DATA>(
      getCookies(TOKEN_KEY.REFRESH_TOKEN),
    )
    if (!tokenDecode && !refreshTokenDecode) return null
    let type: USER_TYPE
    const _type = tokenDecode?.role || refreshTokenDecode?.role
    if (Object.values(NANNY_ROLE).includes(_type as NANNY_ROLE))
      type = USER_TYPE.NANNY
    else type = USER_TYPE.PARENT
    return {
      type: type,
      nannyId: tokenDecode?.nanny_id || refreshTokenDecode?.nanny_id,
      parentId: tokenDecode?.parent_id || refreshTokenDecode?.parent_id,
    }
  }

  const authRedirect = useRef(true)
  const loginCallback = useRef<() => void>()
  const [tokenInfo, setTokenInfo] = useState<TOKEN_INFO>(getTokenInfo())
  const isLoggedIn = useMemo(() => {
    if (!tokenInfo) return false
    if (tokenInfo.type === USER_TYPE.NANNY) return !!nanny
    return !!parent
  }, [tokenInfo, parent, nanny])

  useEffect(() => {
    if (isLoggedIn) {
      loginCallback.current?.()
      loginCallback.current = undefined
    }
  }, [isLoggedIn])

  useEffect(() => {
    function onChangeCookie(data: CookieChangeOptions) {
      if (
        [TOKEN_KEY.ACCESS_TOKEN, TOKEN_KEY.REFRESH_TOKEN].includes(data.name)
      ) {
        setTokenInfo(getTokenInfo())
      }
    }
    cookies.addChangeListener(onChangeCookie)
    return () => {
      cookies.removeChangeListener(onChangeCookie)
    }
  }, [])

  const login = (data: LOGIN_RESPONSE, callback?: () => void) => {
    if (callback) {
      authRedirect.current = false
      loginCallback.current = () => {
        callback()
        authRedirect.current = true
      }
    }
    setTokenCookies(data.access_token)
    setRefreshTokenCookies(data.refresh_token)
    setToken(data.access_token)
    setTokenV2(data.access_token)
    removeCookies(TOKEN_KEY.NANNY.REGISTER.ACCESS_TOKEN)
    removeCookies(TOKEN_KEY.NANNY.REGISTER.REFRESH_TOKEN)
    removeCookies(TOKEN_KEY.NANNY.FORGOT_PASSWORD.ACCESS_TOKEN)
    removeCookies(TOKEN_KEY.NANNY.FORGOT_PASSWORD.REFRESH_TOKEN)
  }

  const logout = () => {
    removeCookies(TOKEN_KEY.ACCESS_TOKEN)
    removeCookies(TOKEN_KEY.REFRESH_TOKEN)
    window.location.reload()
  }

  const fetchAuth = useDebounce(() => {
    !loginCallback.current && dispatch(setLoadingAuth(true))
    if (tokenInfo) {
      dispatch(
        getUserProfileAction({
          data: {
            userType: tokenInfo.type,
          },
          onFailed: () => {
            logout()
          },
          onFinally: () =>
            !loginCallback.current && dispatch(setLoadingAuth(false)),
        }),
      )
    } else {
      dispatch(setNannyProfile(null))
      !loginCallback.current && dispatch(setLoadingAuth(false))
    }
  }, 50)

  useEffect(() => {
    fetchAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(tokenInfo)])

  const value = useMemo(() => {
    return {
      isLoggedIn,
      userType: tokenInfo?.type,
      authRedirect,
      nanny,
      parent,
      login,
      logout,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, tokenInfo?.type, nanny, parent])

  return <Provider value={value}>{children}</Provider>
}

export { AuthProvider, useAuth }

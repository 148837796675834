import { useAuth } from 'contexts/auth'
import { NANNY_STATUS, USER_TYPE } from 'models'
import { Navigate, Outlet } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'

interface Props {
  children: any
}

export const ParentRequireAnonymous = ({ children }: Props) => {
  const { isLoggedIn, userType, nanny, authRedirect } = useAuth()

  if (authRedirect.current) {
    if (isLoggedIn) {
      if (userType !== USER_TYPE.PARENT) {
        if (nanny?.status === NANNY_STATUS.VERIFIED) {
          return <Navigate replace to={APP_ROUTES.NANNY.COMPLETE_PROFILE.to} />
        }
        return <Navigate replace to={APP_ROUTES.NANNY.BOOKING.to} />
      }
      return <Navigate replace to={APP_ROUTES.PARENT.BOOKING.to} />
    }
  }

  return <>{children ? children : <Outlet />}</>
}

export default ParentRequireAnonymous

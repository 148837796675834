import { USER_TYPE } from 'models'
import { ToastOptions } from 'react-toastify'
import { theme } from 'theme'

export const API = {
  NANNY: {
    PROFILE: {
      DEFAULT: 'nanny/profile',
      EDIT: 'nanny/edit-profile',
      UPDATE: 'nanny/update-profile',
      SUMMARY: 'nanny/profile-summary',
      UPLOAD_PICTURE: 'nanny/upload-profile-picture',
    },
    AUTH: {
      LOGIN: 'auth/nanny/login',
      REGISTER: 'auth/nanny/register',
      VERIFY_PHONE: 'auth/nanny/verify-number',
      VERIFY_EMAIL: 'auth/nanny/verify-email',
      RESEND_OTP: 'auth/nanny/resend-otp',
      FORGOT_PASSWORD: 'auth/nanny/forgot-password',
      RESET_PASSWORD: 'auth/nanny/reset-password',
      CHECK_CODE: 'auth/nanny/check-code',
    },
    BOOKING: {
      DEFAULT: 'nanny/booking',
      SET_AVAILABLE: 'nanny/booking/set-available',
      SUMMARY: 'nanny/booking/booking-summary',
    },
    IMAGE: {
      LIST: 'nanny/images',
      UPLOAD: 'nanny/upload-image',
      DELETE: 'nanny/delete-image',
    },
    TESTIMONIAL: {
      LIST: 'nanny/testimonial',
      CREATE: 'nanny/create-testimonial',
    },
    CERTIFICATION: {
      LIST: 'nanny/certificate/get-certificate-association',
    },
  },
  PARENT: {
    PROFILE: {
      DEFAULT: 'users/profile',
      EDIT: 'parents/profile',
      UPLOAD_FILE: 'parents/upload',
      CHANGE_PASSWORD: 'users/change-password',
    },
    AUTH: {
      LOGIN: 'auth/parent/login',
      REGISTER: 'auth/register',
      RESEND_OTP: 'auth/resend-code',
      VERIFY_PHONE: 'auth/verify-account',
      FORGOT_PASSWORD: 'auth/forgot-password',
      CHECK_CODE: 'auth/check-code',
      RESET_PASSWORD: 'auth/reset-password',
      CHOOSE_ROLE: 'auth/choose-role',
    },
    BOOKING: {
      DEFAULT: 'parent/booking',
      CREATE: 'parent/booking/create-booking',
      SUMMARY: 'parent/booking/booking-summary',
      CANCEL: 'parent/booking/cancel-booking',
      CREATE_TESTIMONIAL: 'nanny/testimonial/create-testimonial',
    },
  },
  AUTH: {
    REFRESH_TOKEN: 'auth/refresh-token',
  },
  STATIC_DATA: {
    NATIONALITY: 'staticdata/nationality',
    ETHNICITY: 'staticdata/ethnicity',
  },
}

export const TOAST: { [key: string]: ToastOptions } = {
  error: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  success: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
}

export const isRefreshing = 'isRefreshing'
export const INIT_COUNTRY_CODE = { code: '+65', name: 'sg' }

export const REGEX = {
  OTP: /^[0-9]{6}$/,
}

export const USER_COLOR: { [key in USER_TYPE]: { main: string } } = {
  [USER_TYPE.PARENT]: { main: theme.palette.primary.main },
  [USER_TYPE.NANNY]: { main: theme.palette.secondary.main },
}

export const TOKEN_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN_NANNY_WEB',
  REFRESH_TOKEN: 'REFRESH_TOKEN_NANNY_WEB',
  NANNY: {
    REGISTER: {
      ACCESS_TOKEN: 'NANNY_REGISTER_ACCESS_TOKEN_NANNY_WEB',
      REFRESH_TOKEN: 'NANNY_REGISTER_REFRESH_TOKEN_NANNY_WEB',
    },
    FORGOT_PASSWORD: {
      ACCESS_TOKEN: 'NANNY_FORGOT_PASSWORD_ACCESS_TOKEN_NANNY_WEB',
      REFRESH_TOKEN: 'NANNY_FORGOT_PASSWORD_REFRESH_TOKEN_NANNY_WEB',
    },
  },
  PARENT: {
    REGISTER: {
      ACCESS_TOKEN: 'PARENT_REGISTER_ACCESS_TOKEN_NANNY_WEB',
      REFRESH_TOKEN: 'PARENT_REGISTER_REFRESH_TOKEN_NANNY_WEB',
    },
    FORGOT_PASSWORD: {
      ACCESS_TOKEN: 'PARENT_FORGOT_PASSWORD_ACCESS_TOKEN_NANNY_WEB',
      REFRESH_TOKEN: 'PARENT_FORGOT_PASSWORD_REFRESH_TOKEN_NANNY_WEB',
    },
  },
}

export const PHOTO_SIZE = 5 * 1024 * 1024 // bytes 5242880

import { Box } from '@mui/material'
import { TrashIcon } from 'assets'
import { NANNY_PHOTO } from 'models/nanny/photo'
import { Fragment } from 'react'
import { theme } from 'theme'
import { StyledButtonRemove, StyledCaptionImg, StyledImageItem } from './styles'

interface Props {
  index: number
  item: NANNY_PHOTO
  onDelete: (item: NANNY_PHOTO) => void
  onShowPhoto: (idx: number) => void
}

export const PictureItem = ({ item, index, onDelete, onShowPhoto }: Props) => {
  return (
    <Fragment>
      <StyledImageItem item xs={6} md={3}>
        <StyledButtonRemove
          onClick={() => onDelete(item)}
          sx={{ color: theme.palette.white.light }}
        >
          <TrashIcon />
        </StyledButtonRemove>
        <Box onClick={() => onShowPhoto(index)} className="box_image">
          <img src={item.imageUrl} alt={item.caption} className="image_style" />
        </Box>
        <StyledCaptionImg variant="Web_Label_16">
          {item.caption || ''}
        </StyledCaptionImg>
      </StyledImageItem>
    </Fragment>
  )
}

export default PictureItem

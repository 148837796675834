import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PARENT_PROFILE, USER_PROFILE } from 'models'
import { NANNY_PROFILE_SUMMARY } from 'models/nanny'

export interface AuthenticationType {
  nanny: NANNY_PROFILE_SUMMARY
  parent: USER_PROFILE
  loadingAuth: boolean
}

const initialState: AuthenticationType = {
  nanny: null,
  parent: null,
  loadingAuth: true,
}

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setNannyProfile(
      state: AuthenticationType,
      action: PayloadAction<NANNY_PROFILE_SUMMARY>,
    ) {
      state.nanny = action.payload
    },
    setParentProfile(
      state: AuthenticationType,
      action: PayloadAction<PARENT_PROFILE>,
    ) {
      state.parent = action.payload
    },
    setLoadingAuth(state: AuthenticationType, action: PayloadAction<boolean>) {
      state.loadingAuth = action.payload
    },
  },
})

// Actions
export const { setNannyProfile, setParentProfile, setLoadingAuth } =
  authenticationSlice.actions

export default authenticationSlice.reducer

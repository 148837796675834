import { IconButton, TextField, styled } from '@mui/material';
import { theme } from 'theme';

export const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin: -10px;
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    cursor: pointer;
    &.Mui-readOnly,
    &.Mui-disabled {
      background: ${theme.palette.backgrounds.tertiary};
      color: ${theme.palette.label.primary};
    }
    .MuiOutlinedInput-input {
      pointer-events: none;
    }
  }
`;

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  InputAdornment,
  Stack,
  SvgIconProps,
  SxProps,
} from '@mui/material'
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { CalendarIcon } from 'assets'
import { memo, useState } from 'react'
import { Control, FieldError, useController } from 'react-hook-form'
import { StyledIconButton, StyledTextField } from './styles'

export interface DatePickerFieldProps
  extends Omit<
    DesktopDatePickerProps<moment.Moment, moment.Moment>,
    'renderInput' | 'value' | 'onChange'
  > {
  label?: string
  sx?: SxProps
  fullWidth?: boolean
  name?: string
  control?: Control<any>
  hideHelper?: boolean
  errorMess?: string
  iconColor?: SvgIconProps['color']
  rootProps?: FormControlProps
  placeholder?: string
  value?: DesktopDatePickerProps<moment.Moment, moment.Moment>['value']
  onChange?: DesktopDatePickerProps<moment.Moment, moment.Moment>['onChange']
  getErrorMess?: (error: FieldError, value: string) => string
  helperTextProps?: FormHelperTextProps
}

export const DatePickerField = memo(
  ({
    label,
    name,
    fullWidth = true,
    rootProps = {},
    value: externalValue,
    onChange: externalOnChange,
    control,
    placeholder,
    hideHelper,
    helperTextProps,
    iconColor,
    readOnly,
    disabled,
    errorMess,
    getErrorMess,
    ...rest
  }: DatePickerFieldProps) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
            onBlur: undefined,
            ref: undefined,
          },
          fieldState: { error: undefined },
        }

    const [openDate, setOpenDate] = useState<boolean>(false)

    const onOpenDate = () => {
      if (disabled || readOnly) return
      setOpenDate(true)
    }

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} {...rootProps}>
        {label && (
          <Stack flexDirection="row" alignItems="center">
            <FormLabel htmlFor={name}>{label}</FormLabel>
          </Stack>
        )}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            inputRef={ref}
            disableMaskedInput
            value={value ?? null}
            inputFormat="DD/MM/YYYY"
            readOnly={readOnly}
            disabled={disabled}
            open={openDate}
            onOpen={onOpenDate}
            onClose={() => setOpenDate(false)}
            onChange={(...rest) => {
              if (control) externalOnChange?.(...rest)
              onChange(...rest)
            }}
            renderInput={(params) => (
              <StyledTextField
                onBlur={onBlur}
                onClick={onOpenDate}
                {...params}
                error={!!error}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  tabIndex: -1,
                  placeholder: placeholder,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  notched: false,
                  endAdornment: !readOnly && !disabled && (
                    <InputAdornment position="end">
                      <StyledIconButton onClick={onOpenDate}>
                        <CalendarIcon color={iconColor} />
                      </StyledIconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {...rest}
          />
        </LocalizationProvider>
        {!hideHelper && (error || errorMess) && (
          <FormHelperText
            error={!!error || !!errorMess}
            sx={{ textAlign: label ? 'left' : 'right' }}
            {...helperTextProps}
          >
            {getErrorMess
              ? getErrorMess(error, value)
              : error?.message || errorMess}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

export default DatePickerField

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { InputField } from 'components'
import { ButtonSubmit, PageTitle } from 'components/common/styles/auth'
import yup from 'config/yup.custom'
import { NotifyService } from 'helpers'
import { useDebounce, useNavigateQuery } from 'hooks'
import { useForm } from 'react-hook-form'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import LogoAuth from '../../components/LogoAuth'

interface IForgotPasswordForm {
  email: string
}

const schema = yup
  .object({
    email: yup.string().email('Incorrect Email').required('Email is required'),
  })
  .required()

function ForgotPassword() {
  const navigateQuery = useNavigateQuery()
  const dispatch = useAppDispatch()

  const { handleSubmit, control } = useForm<IForgotPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = useDebounce(async (data: IForgotPasswordForm) => {
    dispatch(setGlobalLoading(true))
    await authApi.parent
      .forgotPassword({
        email: data.email,
      })
      .then(() => {
        NotifyService.success('Success')
        navigateQuery(APP_ROUTES.PARENT.AUTH.CHECK_CODE.to, {
          email: data.email,
        })
      })
      .catch((e) => {
        NotifyService.error(e)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 500)

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <LogoAuth />
      <PageTitle variant="h1" mb={1}>
        Forgot Password
      </PageTitle>
      <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
        Enter the email associated with your account and we'll send instructions
        to reset your password
      </Typography>
      <Stack spacing={2}>
        <InputField control={control} name="email" placeholder="Email" />
      </Stack>
      <Stack
        mt={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonSubmit color="primary">Send email</ButtonSubmit>
      </Stack>
    </Stack>
  )
}

export default ForgotPassword

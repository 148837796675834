import { SvgIcon, SvgIconProps } from '@mui/material'

export function BoldInfoCircle(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0003 1.66663C5.40866 1.66663 1.66699 5.40829 1.66699 9.99996C1.66699 14.5916 5.40866 18.3333 10.0003 18.3333C14.592 18.3333 18.3337 14.5916 18.3337 9.99996C18.3337 5.40829 14.592 1.66663 10.0003 1.66663ZM9.37533 6.66663C9.37533 6.32496 9.65866 6.04163 10.0003 6.04163C10.342 6.04163 10.6253 6.32496 10.6253 6.66663V10.8333C10.6253 11.175 10.342 11.4583 10.0003 11.4583C9.65866 11.4583 9.37533 11.175 9.37533 10.8333V6.66663ZM10.767 13.65C10.7253 13.7583 10.667 13.8416 10.592 13.925C10.5087 14 10.417 14.0583 10.317 14.1C10.217 14.1416 10.1087 14.1666 10.0003 14.1666C9.89199 14.1666 9.78366 14.1416 9.68366 14.1C9.58366 14.0583 9.49199 14 9.40866 13.925C9.33366 13.8416 9.27533 13.7583 9.23366 13.65C9.19199 13.55 9.16699 13.4416 9.16699 13.3333C9.16699 13.225 9.19199 13.1166 9.23366 13.0166C9.27533 12.9166 9.33366 12.825 9.40866 12.7416C9.49199 12.6666 9.58366 12.6083 9.68366 12.5666C9.88366 12.4833 10.117 12.4833 10.317 12.5666C10.417 12.6083 10.5087 12.6666 10.592 12.7416C10.667 12.825 10.7253 12.9166 10.767 13.0166C10.8087 13.1166 10.8337 13.225 10.8337 13.3333C10.8337 13.4416 10.8087 13.55 10.767 13.65Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default BoldInfoCircle

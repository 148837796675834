import { SvgIcon, SvgIconProps } from '@mui/material'

export function BoldHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.1999 12.3999C14.5499 12.3999 12.3999 14.5499 12.3999 17.1999C12.3999 18.0999 12.6499 18.9499 13.0999 19.6699C13.9299 21.0599 15.4499 21.9999 17.1999 21.9999C18.9499 21.9999 20.4799 21.0599 21.2999 19.6699C21.7399 18.9499 21.9999 18.0999 21.9999 17.1999C21.9999 14.5499 19.8499 12.3999 17.1999 12.3999ZM19.5799 16.5699L17.0199 18.9299C16.8799 19.0599 16.6899 19.1299 16.5099 19.1299C16.3199 19.1299 16.1299 19.0599 15.9799 18.9099L14.7999 17.7299C14.5099 17.4399 14.5099 16.9599 14.7999 16.6699C15.0899 16.3799 15.5699 16.3799 15.8599 16.6699L16.5299 17.3399L18.5599 15.4599C18.8599 15.1799 19.3399 15.1999 19.6199 15.4999C19.8999 15.8099 19.8799 16.2899 19.5799 16.5699Z"
        fill="currentColor"
      />
      <path
        d="M22 8.73014C22 9.92014 21.81 11.0201 21.48 12.0401C21.42 12.2501 21.17 12.3101 20.99 12.1801C19.9 11.3701 18.57 10.9401 17.2 10.9401C13.73 10.9401 10.9 13.7701 10.9 17.2401C10.9 18.3201 11.18 19.3801 11.71 20.3201C11.87 20.6001 11.68 20.9601 11.38 20.8501C8.97 20.0301 4.1 17.0401 2.52 12.0401C2.19 11.0201 2 9.92014 2 8.73014C2 5.64014 4.49 3.14014 7.56 3.14014C9.37 3.14014 10.99 4.02014 12 5.37014C13.01 4.02014 14.63 3.14014 16.44 3.14014C19.51 3.14014 22 5.64014 22 8.73014Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </SvgIcon>
  )
}

export default BoldHeartIcon

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { CheckCircleBold } from 'assets'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadAvatar,
} from 'components'
import yup from 'config/yup.custom'
import { useAuth } from 'contexts/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import { NANNY_STATUS, OPTION_ITEM, USER_TYPE } from 'models'
import { NANNY_EDIT_PROFILE } from 'models/nanny'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { APP_ROUTES } from 'routers/routes'
import { profileApi, staticDataApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { getUserProfileAction } from 'store/reducers/auth/actionTypes'
import { setGlobalLoading } from 'store/reducers/global'
import PopupChangePassword from '../../components/PopupChangePassword'

interface IEditProfileForm {
  bio: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirth: moment.Moment
  startOfNannyExperience: moment.Moment
  ethnicity: OPTION_ITEM<string>
  nationality: OPTION_ITEM<string>
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup.mixed().required('Phone number is required'),
    nationality: yup.mixed().required('Nationality is required'),
    ethnicity: yup.mixed().required('Ethnicity is required'),
    dateOfBirth: yup.mixed().notRequired(),
    startOfNannyExperience: yup
      .mixed()
      .required('Start of nanny experience is required'),
    bio: yup.string().notRequired(),
  })
  .required()

export const EditProfile = () => {
  const { nanny } = useAuth()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.global)
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false)
  const [profile, setProfile] = useState<NANNY_EDIT_PROFILE>()
  const [ethnicities, setEthnicities] = useState<OPTION_ITEM<string>[]>([])
  const [nationalities, setNationalities] = useState<OPTION_ITEM<string>[]>([])
  const isAccountPending = [
    NANNY_STATUS.PENDING_APPROVAL,
    NANNY_STATUS.VERIFIED,
  ].includes(nanny?.status)

  const { handleSubmit, control, reset } = useForm<IEditProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (profile) {
      reset({
        bio: profile.bio || '',
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        email: profile.email || '',
        phoneNumber: profile.phoneNumber || '',
        dateOfBirth: profile.dateOfBirth
          ? moment(profile.dateOfBirth, 'DD-MM-YYYY')
          : undefined,
        startOfNannyExperience: profile.startOfNannyExperience
          ? moment(profile.startOfNannyExperience, 'DD-MM-YYYY')
          : undefined,
        ethnicity: profile.ethnicity
          ? { id: profile.ethnicity, name: profile.ethnicity }
          : undefined,
        nationality: profile.nationality
          ? { id: profile.nationality, name: profile.nationality }
          : undefined,
      })
    }
  }, [profile, reset])

  useEffect(() => {
    const fetchOptions = async () => {
      await Promise.all([
        staticDataApi.getEthnicity(),
        staticDataApi.getNationality(),
      ])
        .then(
          ([
            {
              data: { data: _ethnicities },
            },
            {
              data: { data: _nationalities },
            },
          ]) => {
            setEthnicities(
              _ethnicities.map((t) => ({ id: t.name, name: t.name })),
            )
            setNationalities(
              _nationalities.map((t) => ({ id: t.name, name: t.name })),
            )
          },
        )
        .catch((e) => NotifyService.error(e))
    }
    fetchOptions()
  }, [])

  const fetchData = async () => {
    if (!nanny?.nannyId) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .getEditProfile(nanny.nannyId)
      .then(({ data: { data } }) => {
        setProfile(data)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nanny?.nannyId, dispatch])

  const onRefeshData = () => {
    fetchData()
    dispatch(
      getUserProfileAction({
        data: {
          userType: USER_TYPE.NANNY,
        },
      }),
    )
  }

  const onSubmit = useDebounce(async (data: IEditProfileForm) => {
    if (loading || !profile) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .updateProfile({
        nannyId: profile?.nannyId,
        bio: data.bio || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        dateOfBirth: data.dateOfBirth
          ? moment(data.dateOfBirth).format('DD-MM-YYYY')
          : null,
        startOfNannyExperience: data.startOfNannyExperience
          ? moment(data.startOfNannyExperience).format('DD-MM-YYYY')
          : null,
        ethnicity: data.ethnicity?.id,
        nationality: data.nationality?.id,
      })
      .then(() => {
        onRefeshData()
      })
      .catch((e) => {
        NotifyService.error(e)
        dispatch(setGlobalLoading(false))
      })
  }, 500)

  const onChangeProfilePicture = async (file: File) => {
    if (!profile) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .uploadPicture(profile.nannyId, file)
      .then(() => {
        onRefeshData()
      })
      .catch((e) => {
        NotifyService.error(e)
        dispatch(setGlobalLoading(false))
      })
  }

  return (
    <Stack spacing={7} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Edit Profile</Typography>
        <Stack direction="row" spacing={1.5}>
          <Button
            disabled={isAccountPending}
            sx={{
              padding: '8.2px 16.4px',
            }}
            variant="outlined"
            color="secondary"
            component="a"
            href={APP_ROUTES.NANNY.PROFILE.PREVIEW.to(nanny.nannyId)}
          >
            Preview Profile
          </Button>
          <Button
            sx={{
              padding: '8.2px 16.4px',
              display: { xs: 'none', md: 'flex' },
            }}
            type="submit"
            variant="contained"
            color="secondary"
            endIcon={<CheckCircleBold sx={{ width: '24px', height: '24px' }} />}
          >
            Save Profile
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="Web_Title_22">Personal Information</Typography>
          <Typography variant="Web_Label_18" color="#959595">
            Only visible to parents from matched booking
          </Typography>
        </Stack>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="center">
            <UploadAvatar
              role={USER_TYPE.NANNY}
              value={profile?.profilePicture}
              onChange={(val) => onChangeProfilePicture(val)}
            />
          </Stack>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="firstName"
                  label="First Name*"
                  placeholder="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="lastName"
                  label="Last Name*"
                  placeholder="Last Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="email"
                  label="Email"
                  placeholder="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled
                  control={control}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  control={control}
                  name="nationality"
                  label="Nationality*"
                  placeholder="Nationality"
                  options={nationalities}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  control={control}
                  name="ethnicity"
                  label="Ethnicity*"
                  placeholder="Ethnicity"
                  options={ethnicities}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  disabled
                  control={control}
                  iconColor="secondary"
                  name="dateOfBirth"
                  label="Date of Birth*"
                  placeholder="Date of Birth"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  control={control}
                  iconColor="secondary"
                  name="startOfNannyExperience"
                  label="Start of Nanny Experience (Year)*"
                  placeholder="When did you start your nanny career?"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  rows={5}
                  multiline
                  control={control}
                  name="bio"
                  label="Bio"
                  placeholder="Describe yourself"
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Button
            sx={{
              padding: '8.2px 16.4px',
              display: { xs: 'flex', md: 'none' },
            }}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={() => {}}
          >
            Save Profile
          </Button>
          <Button
            sx={{
              padding: '8.2px 16.4px',
              width: { xs: '100%', md: '200px' },
            }}
            variant="outlined"
            color="secondary"
            onClick={() => setOpenChangePassword(true)}
          >
            Change Password
          </Button>
        </Stack>
      </Stack>
      <PopupChangePassword
        openPop={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </Stack>
  )
}

export default EditProfile

import {
  FormControl,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import {
  SolidExchangeRectangleIcon,
  SolidImageUploadIcon,
  SolidTrashIcon,
} from 'assets'
import { PHOTO_SIZE } from 'config'
import { NotifyService } from 'helpers'
import { useIsMountedRef } from 'hooks'
import { ReactNode, memo, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Control, FieldError, useController } from 'react-hook-form'
import { fData } from 'utils'
import {
  ContentBox,
  DropBox,
  DropSubText,
  DropText,
  FilePreview,
  FilePreviewAction,
  FilePreviewOverLay,
} from './styles'

interface UploadImageFieldProps {
  value?: string | File
  label?: string | ReactNode
  name?: string
  sx?: SxProps
  fullWidth?: boolean
  control?: Control<any>
  hideHelper?: boolean
  errorMess?: string
  rootProps?: Partial<FormControlProps>
  formControlLabel?: Partial<FormControlLabelProps> & { $error?: boolean }
  onChange?: (file?: File) => void
  getErrorMess?: (error: FieldError, value: string) => string
}

export const UploadImageField = memo(
  ({
    label,
    name,
    fullWidth,
    rootProps,
    value: externalValue,
    onChange: externalOnChange,
    control,
    hideHelper,
    errorMess,
    getErrorMess,
    formControlLabel,
    ...rest
  }: UploadImageFieldProps) => {
    const {
      field: { onChange, value },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
          },
          fieldState: { error: undefined },
        }

    const isMountedRef = useIsMountedRef()
    const [errorFile, setErrorFile] = useState<string>('')

    const _onChange = (file?: File) => {
      setErrorFile('')
      onChange?.(file ?? undefined)
    }

    const handleDrop = useCallback(
      async (acceptedFiles: File[]) => {
        let file = acceptedFiles[0]
        const checkSize = file.size < PHOTO_SIZE
        const checkType = file.type.startsWith('image/')
        if (!checkSize) {
          NotifyService.error(`Images must be less than ${fData(PHOTO_SIZE)}`)
          return
        }
        if (!checkType) {
          NotifyService.error('Unsupported format, please use an image file.')
          return
        }
        _onChange(file)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMountedRef, onChange],
    )

    const { open, getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
      multiple: false,
      maxFiles: 1,
      noClick: true,
    })

    const [filePreview, setFilePreview] = useState<string>()

    useEffect(() => {
      if (!!value && typeof value === 'object') {
        const reader = new FileReader()
        reader.readAsDataURL(value)
        reader.onload = () => setFilePreview(reader.result as string)
      } else {
        setFilePreview(value as string)
      }
    }, [value])

    return (
      <FormControl variant="outlined" fullWidth={fullWidth} {...rootProps}>
        {label && (
          <Stack flexDirection="row" alignItems="center">
            <FormLabel htmlFor={name}>{label}</FormLabel>
          </Stack>
        )}
        <ContentBox $error={!!error || !!errorFile} {...getRootProps()}>
          <input {...getInputProps()} />
          {!filePreview ? (
            <DropBox onClick={open}>
              <SolidImageUploadIcon />
              <DropText>
                Drop you file here, or <span>Browse</span>
              </DropText>
              <DropSubText>Maximum file size {fData(PHOTO_SIZE)}</DropSubText>
            </DropBox>
          ) : (
            <FilePreview>
              <FilePreviewOverLay />
              <img src={filePreview} alt="preview" />
              <Stack direction="row" spacing={5.75} sx={{ zIndex: 10 }}>
                <FilePreviewAction onClick={open}>
                  <SolidExchangeRectangleIcon />
                  <Typography>Change</Typography>
                </FilePreviewAction>
                <FilePreviewAction onClick={() => _onChange()}>
                  <SolidTrashIcon />
                  <Typography>Remove</Typography>
                </FilePreviewAction>
              </Stack>
            </FilePreview>
          )}
        </ContentBox>
        {!hideHelper && (error || errorMess || !!errorFile) && (
          <FormHelperText error={!!error || !!errorMess || !!errorFile}>
            {getErrorMess
              ? getErrorMess(error, value)
              : error?.message || errorMess || errorFile}
          </FormHelperText>
        )}
      </FormControl>
    )
  },
)

export default UploadImageField

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { APP_IMG } from 'assets'
import { InputField } from 'components'
import ModalUI from 'components/common/ModalUI'
import CheckboxField from 'components/form-control/CheckboxField'
import RatingField from 'components/form-control/RatingField'
import yup from 'config/yup.custom'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import { BOOKING_AVAILABLE_NANNY } from 'models'
import { memo } from 'react'
import { useForm } from 'react-hook-form'
import { bookingApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'

interface PopupTestimonialProps {
  parentId: string
  bookingId: string
  nanny: BOOKING_AVAILABLE_NANNY
  onClose: () => void
  onSuccess: () => void
}

interface TestimonialForm {
  stars: number
  content: string
  isAnonymous: boolean
}

const schema = yup
  .object({
    stars: yup.number().notRequired(),
    content: yup.string().notRequired(),
    isAnonymous: yup.boolean().notRequired(),
  })
  .required()

const PopupTestimonial = memo(
  ({
    parentId,
    bookingId,
    nanny,
    onClose,
    onSuccess,
  }: PopupTestimonialProps) => {
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector((state) => state.global)

    const { handleSubmit, control } = useForm<TestimonialForm>({
      defaultValues: {
        stars: 0,
        content: '',
        isAnonymous: false,
      },
      mode: 'onChange',
      resolver: yupResolver(schema),
    })
    const handleClose = () => {
      onClose()
    }

    const onSubmit = useDebounce(async (data: TestimonialForm) => {
      if (loading) return
      dispatch(setGlobalLoading(true))
      await bookingApi.parent
        .createTestimonial({
          nannyId: nanny.nannyId,
          parentId,
          bookingId,
          stars: data.stars,
          content: data.content,
          isAnonymous: data.isAnonymous,
        })
        .then(() => {
          NotifyService.success('Success')
          onSuccess()
          handleClose()
        })
        .catch((e) => NotifyService.error(e))
    }, 500)

    return (
      <ModalUI open onClose={handleClose} title="Write a Testimonial">
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              alt="avatar_parent"
              src={nanny.profilePicture || APP_IMG.nannyDefaultAvatar}
              sx={{ width: 48, height: 48 }}
            />
            <Box>
              <Typography component="p" variant="Web_Title_18">
                {nanny.nannyFirstName}
              </Typography>
              <Typography variant="Web_Label_16" sx={{ opacity: 0.5 }}>
                {`${nanny.age}, ${nanny.nationality}, ${nanny.yearsOfExp}+exp`}
              </Typography>
            </Box>
          </Stack>
          <Divider />
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid mb={2} container spacing={2}>
              <Grid item xs={12}>
                <RatingField control={control} name="stars" />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  rows={5}
                  multiline
                  control={control}
                  name="content"
                  label="How was your experience?"
                  placeholder="Share your experiences"
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  name="isAnonymous"
                  control={control}
                  label="Post as anonymous"
                />
              </Grid>
            </Grid>
            <Stack
              mt={{ md: 4 }}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="flex-end"
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: { xs: '100%', sm: '200px' } }}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Stack>
      </ModalUI>
    )
  },
)

export default PopupTestimonial

import { API } from 'config'
import {
  DATA_PAGINATION,
  NANNY_CERTIFICATION_ASSOCIATION,
  NANNY_GET_ALL_CERTIFICATION_ASSOCIATION_REQUEST,
} from 'models'
import { APIInstance } from './configApi'

export const certificationApi = {
  nanny: {
    getAllCertificationAssociation: (
      params: NANNY_GET_ALL_CERTIFICATION_ASSOCIATION_REQUEST,
    ) => {
      return APIInstance.get<DATA_PAGINATION<NANNY_CERTIFICATION_ASSOCIATION>>(
        API.NANNY.CERTIFICATION.LIST,
        {
          params,
        },
      )
    },
  },
}

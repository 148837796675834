import { Divider, Stack, Typography } from '@mui/material'
import { USER_COLOR } from 'config'
import { formatDate } from 'helpers'
import { NANNY_BOOKING_DETAIL, PARENT_BOOKING_DETAIL, USER_TYPE } from 'models'
import { memo } from 'react'
import { theme } from 'theme'
import { StyledBookingDetailPanel } from './styles'

interface BookingDetailPanelProps {
  item: PARENT_BOOKING_DETAIL | NANNY_BOOKING_DETAIL
  userType: USER_TYPE
}

const BookingDetailPanel = memo(
  ({ item, userType }: BookingDetailPanelProps) => {
    return (
      <StyledBookingDetailPanel spacing={2}>
        <Stack>
          <Typography
            sx={{
              opacity: 0.5,
            }}
            variant="Web_Title_14"
          >
            Booking Details
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 7.3 }}
        >
          {userType === USER_TYPE.NANNY && (
            <Stack spacing={{ xs: 1, md: 0.5 }}>
              <Typography
                variant="Web_Label_18"
                color={theme.palette.grey3.dark}
              >
                Parent
              </Typography>
              <Typography variant="Web_Label_18">
                {item.parentFirstName}
              </Typography>
            </Stack>
          )}
          {item.commencementDate && (
            <Stack spacing={{ xs: 1, md: 0.5 }}>
              <Typography
                variant="Web_Label_18"
                color={theme.palette.grey3.dark}
              >
                Commencement Date
              </Typography>
              <Typography
                variant="Web_Label_18"
                color={USER_COLOR[userType].main}
              >
                {formatDate(item.commencementDate, {
                  format: 'DD/MM/YYYY',
                  valFormat: 'DD-MM-YYYY',
                })}
              </Typography>
            </Stack>
          )}
          <Stack spacing={{ xs: 1, md: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Expected Due Date
            </Typography>
            <Typography
              variant="Web_Label_18"
              color={USER_COLOR[userType].main}
            >
              {formatDate(item.edd, {
                format: 'DD/MM/YYYY',
                valFormat: 'DD-MM-YYYY',
              })}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 1, md: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Duration
            </Typography>
            <Typography variant="Web_Label_18">{item.duration} Days</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 4.3 }}
        >
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Single/Twin Pregnancy
            </Typography>
            <Typography variant="Web_Label_18">
              {item.singleTwinPregnancy}
            </Typography>
          </Stack>
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Single/Multi-storey
            </Typography>
            <Typography variant="Web_Label_18">{item.houseType}</Typography>
          </Stack>
          <Stack spacing={{ xs: 0.5 }}>
            <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
              Pets at home
            </Typography>
            <Typography variant="Web_Label_18">
              {item.pets ? 'Yes' : 'No'}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_18" color={theme.palette.grey3.dark}>
            Other Requirements
          </Typography>
          <Typography variant="Web_paragraph">
            {item.otherRequirements}
          </Typography>
        </Stack>
        <Divider />
        <Stack color={theme.palette.grey3.dark} direction="row" spacing={0.5}>
          <Typography variant="Small_Body_16">Created on:</Typography>
          <Typography variant="Small_Body_16">
            {formatDate(item.createdAt, { format: 'DD/MM/YYYY' })}
          </Typography>
        </Stack>
      </StyledBookingDetailPanel>
    )
  },
)

export default BookingDetailPanel

import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { theme } from 'theme'
import { transientOptions } from 'utils/transientOptions'

export const StyledNannyPanel = styled(Stack, transientOptions)`
  text-decoration: none;
  color: ${theme.palette.black.dark};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  padding: 16px 22px;
`

export const StyledNannyItem = styled(Stack, transientOptions)`
  color: ${theme.palette.black.dark};
  border: 1px solid #959595;
  border-radius: 5px;
  padding: 17px 25px 18px;
  & .btn_view {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.light};
    padding: 8px 10px;
    border-radius: 2.5px;
    width: 92px;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.25px;
    transition: 0.5s all;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  }
`

export const StyledNannyTestimonial = styled(Stack, transientOptions)`
  text-decoration: none;
  color: ${theme.palette.black.dark};
  background-color: ${theme.palette.primary.light};
  border-radius: 8px;
  padding: 23px 12px 18px 23px;
  height: 100%;
  & .btn_view {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.light};
    padding: 8px 10px;
    border-radius: 2.5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.25px;
    width: 135px;
    max-height: 28px;

    transition: 0.5s all;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  }
`

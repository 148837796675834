import { Box, Button, Stack, Typography } from '@mui/material'
import { APP_IMG, PlusIcon } from 'assets'
import { useDebounce } from 'hooks'
import { ACTION_TYPE, MODAL_ACTION, PARENT_BOOKING } from 'models'
import { useEffect, useState } from 'react'
import { APP_ROUTES } from 'routers/routes'
import { bookingApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import BookingItem from '../../components/BookingItem'
import PopupConfirm from '../../components/PopupConfirm'
import PopupCreateBooking from '../../components/PopupCreateBooking'

function ListBooking() {
  const dispatch = useAppDispatch()
  const [bookings, setBookings] = useState<PARENT_BOOKING[]>([])
  const [modalAction, setModalAction] = useState<MODAL_ACTION<PARENT_BOOKING>>()

  const fetchData = useDebounce(async () => {
    dispatch(setGlobalLoading(true))
    await bookingApi.parent
      .getSummary()
      .then(({ data: { data } }) => {
        setBookings(data)
      })
      .catch(() => {})
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 50)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseModalAction = () => {
    setModalAction(undefined)
  }

  const onCreateSuccess = (id: string) => {
    fetchData()
    setModalAction({ type: ACTION_TYPE.SUBMITTED, custom: id })
  }

  return (
    <Box>
      <Stack
        mb={2.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Web_Title_22">Bookings</Typography>
        <Button
          sx={{
            display: !bookings?.length ? 'none' : 'flex',
            padding: '8.2px 16.4px',
            width: '229px',
          }}
          variant="contained"
          endIcon={<PlusIcon sx={{ width: '24px', height: '24px' }} />}
          onClick={() => setModalAction({ type: ACTION_TYPE.CREATE })}
        >
          Create New Booking
        </Button>
      </Stack>
      <Stack spacing={2}>
        {!!bookings?.length ? (
          bookings.map((booking) => (
            <BookingItem key={booking.bookingId} item={booking} />
          ))
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Box position="relative" mt={3.75}>
              <img src={APP_IMG.bg.bgEmpty} alt="bg_empty" />
              <Typography
                position="absolute"
                bottom={50}
                left={0}
                sx={{ opacity: 0.5 }}
                width="326px"
                textAlign="center"
                variant="Web_Label_14"
              >
                You don’t have any bookings yet, use the button below to make a
                booking.
              </Typography>
            </Box>
            <Button
              sx={{
                padding: '8.2px 16.4px',
                width: '229px',
              }}
              variant="contained"
              endIcon={<PlusIcon sx={{ width: '24px', height: '24px' }} />}
              onClick={() => setModalAction({ type: ACTION_TYPE.CREATE })}
            >
              Create New Booking
            </Button>
          </Stack>
        )}
      </Stack>
      {modalAction?.type === ACTION_TYPE.CREATE && (
        <PopupCreateBooking
          onSuccess={onCreateSuccess}
          onClose={onCloseModalAction}
        />
      )}
      {modalAction?.type === ACTION_TYPE.SUBMITTED && (
        <PopupConfirm
          onClose={onCloseModalAction}
          title="Booking Submitted"
          content={
            <Typography variant="Web_Label_14">
              Our admin will contact you within <b>1-2 business days</b> to
              confirm your details
            </Typography>
          }
          textButton="View Bookings"
          hrefButton={APP_ROUTES.PARENT.BOOKING.DETAIL.to(modalAction.custom)}
        />
      )}
    </Box>
  )
}

export default ListBooking

import { GlobalStylesProps } from '@mui/system';

const globalStyles: GlobalStylesProps['styles'] = {
  '*': {
    boxSizing: 'border-box',
    WebkitAppearance: 'none',
  },
  '*::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    position: 'absolute',
    borderRadius: '3px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  '*::-webkit-scrollbar-thumb': {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '3px',
    width: '5px',
    height: '5px',
  },
  html: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    WebkitOverflowScrolling: 'touch',
  },
  body: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },
  '#root': {
    width: '100%',
    height: '100%',
  },
  input: {
    '&[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button': {
        margin: 0,
        WebkitAppearance: 'none',
      },
      '&::-webkit-inner-spin-button': {
        margin: 0,
        WebkitAppearance: 'none',
      },
    },
  },
  ':root': {},
};

export default globalStyles;

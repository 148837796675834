import { Button, Stack, Typography } from '@mui/material'
import { PageTitle } from 'components/common/styles/auth'
import { NotifyService } from 'helpers'
import { useDebounce } from 'hooks'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { authApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import * as yup from 'yup'
import LogoAuth from '../../components/LogoAuth'

const schemaQuery = yup
  .object({
    code: yup.string().required(),
  })
  .required()

enum STATUS {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export const EmailVerify = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState<STATUS>()
  const { loading } = useAppSelector((state) => state.global)

  const { code }: { code?: string } = queryString.parse(location.search)

  const onVerify = useDebounce(async () => {
    setStatus(null)
    const isValidQuery = schemaQuery.isValidSync({ code })
    if (!isValidQuery) {
      setStatus(STATUS.FAIL)
      return
    }
    dispatch(setGlobalLoading(true))
    await authApi.nanny
      .verifyEmail(code)
      .then(() => {
        NotifyService.success('Success')
        setStatus(STATUS.SUCCESS)
      })
      .catch((e) => {
        NotifyService.error(e)
        setStatus(STATUS.FAIL)
      })
      .finally(() => dispatch(setGlobalLoading(false)))
  }, 200)

  useEffect(() => {
    onVerify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return (
    <Stack>
      <LogoAuth />
      {!loading &&
        (status === STATUS.SUCCESS ? (
          <>
            <PageTitle variant="h1" mb={1}>
              Email Verified
            </PageTitle>
            <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
              Your Email has been verified
            </Typography>
          </>
        ) : (
          <>
            <PageTitle variant="h1" mb={1}>
              Something went wrong
            </PageTitle>
            <Typography mb={2} variant="Web_Label_14" color="grey2.dark">
              Your email could not be verified
            </Typography>
          </>
        ))}

      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          component={Link}
          to={APP_ROUTES.NANNY.AUTH.LOGIN.to}
          variant="contained"
          color="secondary"
          sx={{ width: '174px' }}
        >
          Back to Login
        </Button>
      </Stack>
    </Stack>
  )
}

export default EmailVerify

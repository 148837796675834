import { EarningBreakdown, EarningSummary } from 'models'

export const DATA_EARNINGS_SUMMARY: EarningSummary = {
  total_earnings: 200,
  total_paid: 100,
  total_pending: 100,
  last_updated: '10/09/2023',
}

export const DATA_EARNINGS_BREAKDOWN: EarningBreakdown[] = [
  {
    booking_id: 1,
    price: 10,
  },
  {
    booking_id: 2,
    price: 10,
  },
  {
    booking_id: 3,
    price: 10,
  },
  {
    booking_id: 4,
    price: 10,
  },
  {
    booking_id: 5,
    price: 10,
  },
]

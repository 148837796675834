import { Avatar, Box, Stack, Typography } from '@mui/material'
import { APP_IMG } from 'assets'
import { BOOKING_AVAILABLE_NANNY } from 'models'
import { NavLink, createSearchParams } from 'react-router-dom'
import { APP_ROUTES } from 'routers/routes'
import { StyledNannyItem } from '../styles'

interface Props {
  bookingId: string
  nanny: BOOKING_AVAILABLE_NANNY
}
function NannyItem({ bookingId, nanny }: Props) {
  return (
    <StyledNannyItem direction="row" spacing={1}>
      <Avatar
        alt="avatar_parent"
        src={nanny.profilePicture || APP_IMG.nannyDefaultAvatar}
        sx={{ width: 66, height: 66 }}
      />
      <Stack spacing={2.75}>
        <Box pt={0.875}>
          <Typography component="p" variant="Web_Title_18">
            {nanny.nannyFirstName}
          </Typography>
          <Typography
            variant="Web_Label_16"
            sx={{ opacity: 0.5, lineHeight: '24px' }}
          >
            {`${nanny.age}, ${nanny.nationality}, ${nanny.yearsOfExp}+exp`}
          </Typography>
        </Box>
        <NavLink
          className="btn_view"
          to={{
            pathname: APP_ROUTES.PARENT.NANNY_DETAIL.to(nanny.nannyId),
            search: `?${createSearchParams({ bookingId })}`,
          }}
        >
          View Profile
        </NavLink>
      </Stack>
    </StyledNannyItem>
  )
}

export default NannyItem

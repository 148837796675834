import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import { theme } from 'theme';

export const TopBox = styled(Box)`
  height: 159px;
  padding-top: 37px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: ${theme.palette.secondary.main};
  ${theme.breakpoints.down('lg')} {
    height: 139px;
    align-items: center;
    padding: 40px 70px;
  }
`;

export const StyledContainer = styled(Container)`
  border-radius: 5px;
  position: relative;
  top: -52px;
  background: ${theme.palette.white.light};
  ${theme.breakpoints.down('lg')} {
    padding-left: 24px;
    padding-right: 24px;
    position: unset;
    top: 0;
    border-radius: unset;
  }
`;

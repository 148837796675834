import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadAvatar,
} from 'components'
import { useAuth } from 'contexts/auth'
import { NotifyService, formatDateParam } from 'helpers'
import { useDebounce } from 'hooks'
import { OPTION_ITEM, USER_TYPE } from 'models'
import { NANNY_EDIT_PROFILE } from 'models/nanny'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { profileApi, staticDataApi } from 'services'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { getUserProfileAction } from 'store/reducers/auth/actionTypes'
import { setGlobalLoading } from 'store/reducers/global'
import * as yup from 'yup'
import { FormBox } from './styles'

interface IPersonalInfoForm {
  bio: string
  firstName: string
  lastName: string
  dateOfBirth: moment.Moment
  startOfNannyExperience: moment.Moment
  ethnicity: OPTION_ITEM<string>
  nationality: OPTION_ITEM<string>
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    nationality: yup.mixed().required('Nationality is required'),
    ethnicity: yup.mixed().required('Ethnicity is required'),
    dateOfBirth: yup.mixed().required('Date of birth is required'),
    startOfNannyExperience: yup
      .mixed()
      .required('Start of nanny experience is required'),
    bio: yup.string().notRequired(),
  })
  .required()

export const PersonalInfo = () => {
  const { nanny } = useAuth()
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.global)

  const [ethnicities, setEthnicities] = useState<OPTION_ITEM<string>[]>([])
  const [nationalities, setNationalities] = useState<OPTION_ITEM<string>[]>([])

  const [profile, setProfile] = useState<NANNY_EDIT_PROFILE>()
  const { handleSubmit, control, reset } = useForm<IPersonalInfoForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    const fetchOptions = async () => {
      await Promise.all([
        staticDataApi.getEthnicity(),
        staticDataApi.getNationality(),
      ])
        .then(
          ([
            {
              data: { data: _ethnicities },
            },
            {
              data: { data: _nationalities },
            },
          ]) => {
            setEthnicities(
              _ethnicities.map((t) => ({ id: t.name, name: t.name })),
            )
            setNationalities(
              _nationalities.map((t) => ({ id: t.name, name: t.name })),
            )
          },
        )
        .catch((e) => NotifyService.error(e))
    }
    fetchOptions()
  }, [])

  const fetchData = async () => {
    if (!nanny?.nannyId) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .getEditProfile(nanny.nannyId)
      .then(({ data: { data } }) => {
        setProfile(data)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nanny?.nannyId, dispatch])

  useEffect(() => {
    if (profile) {
      reset({
        bio: profile.bio || '',
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        dateOfBirth: profile.dateOfBirth
          ? moment(profile.dateOfBirth, 'DD-MM-YYYY')
          : undefined,
        startOfNannyExperience: profile.startOfNannyExperience
          ? moment(profile.startOfNannyExperience, 'DD-MM-YYYY')
          : undefined,
        ethnicity: profile.ethnicity
          ? { id: profile.ethnicity, name: profile.ethnicity }
          : undefined,
        nationality: profile.nationality
          ? { id: profile.nationality, name: profile.nationality }
          : undefined,
      })
    }
  }, [profile, reset])

  const onRefeshData = () => {
    fetchData()
    dispatch(
      getUserProfileAction({
        data: {
          userType: USER_TYPE.NANNY,
        },
      }),
    )
  }

  const onSubmit = useDebounce(async (data: IPersonalInfoForm) => {
    if (loading || !profile) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .updateProfile({
        nannyId: profile?.nannyId,
        bio: data.bio || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        dateOfBirth: formatDateParam(data.dateOfBirth),
        startOfNannyExperience: formatDateParam(data.startOfNannyExperience),
        ethnicity: data.ethnicity?.id,
        nationality: data.nationality?.id,
      })
      .then(() => {
        onRefeshData()
      })
      .catch((e) => {
        NotifyService.error(e)
        dispatch(setGlobalLoading(false))
      })
  }, 500)

  const onChangeProfilePicture = async (file: File) => {
    if (!profile) return
    dispatch(setGlobalLoading(true))
    await profileApi.nanny
      .uploadPicture(profile.nannyId, file)
      .then(() => {
        onRefeshData()
      })
      .catch((e) => {
        NotifyService.error(e)
        dispatch(setGlobalLoading(false))
      })
  }

  return (
    <FormBox component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        mb={0.5}
        variant="Web_Title_22"
        component="div"
        sx={{ lineHeight: '111%', fontSize: { xs: '18px', lg: '22px' } }}
      >
        Personal Information
      </Typography>
      <Typography
        mb={2.5}
        variant="Web_Label_18"
        color="#959595"
        component="div"
        sx={{ lineHeight: '114%', fontSize: { xs: '14px', lg: '18px' } }}
      >
        Only visible to parents from matched booking
      </Typography>
      <Stack mb={4} direction="row" justifyContent="center">
        <UploadAvatar
          role={USER_TYPE.NANNY}
          value={profile?.profilePicture}
          onChange={(val) => onChangeProfilePicture(val)}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputField
            control={control}
            name="firstName"
            label="First Name*"
            placeholder="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            control={control}
            name="lastName"
            label="Last Name*"
            placeholder="Last Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            control={control}
            name="nationality"
            label="Nationality*"
            placeholder="Nationality"
            options={nationalities}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            control={control}
            name="ethnicity"
            label="Ethnicity*"
            placeholder="Ethnicity"
            options={ethnicities}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            control={control}
            iconColor="secondary"
            name="dateOfBirth"
            label="Date of Birth*"
            placeholder="Date of Birth"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            control={control}
            iconColor="secondary"
            name="startOfNannyExperience"
            label="Start of Nanny Experience (Year)*"
            placeholder="When did you start your nanny career?"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            rows={3}
            multiline
            control={control}
            name="bio"
            label="Bio"
            placeholder="Describe yourself"
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      <Stack direction="row" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={loading || !profile}
          sx={{ width: { xs: '100%', sm: '282px' } }}
        >
          Submit
        </Button>
      </Stack>
    </FormBox>
  )
}

export default PersonalInfo

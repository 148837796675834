import { Box, Stack, Typography } from '@mui/material'
import { USER_COLOR } from 'config'
import { USER_TYPE } from 'models'
import { Fragment, memo } from 'react'
import { StyledStatusPanel, StyledStepper } from './styles'

export interface StatusPanelStep {
  label: string
  active: boolean
  activeLabel: boolean
}

interface StatusPanelProps {
  bookingId: number | string
  userType: USER_TYPE
  listStep: StatusPanelStep[]
}

export const StatusPanel = memo(
  ({ bookingId, userType, listStep }: StatusPanelProps) => {
    return (
      <StyledStatusPanel $userType={userType}>
        <Box className="panel_header">
          <Typography variant="Web_Title_18">Booking #{bookingId}</Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          className="panel_body"
        >
          {listStep.map((step, idx) => {
            return (
              <Fragment key={idx}>
                {idx !== 0 && (
                  <StyledStepper
                    $userType={userType}
                    className={step.active ? 'active' : ''}
                  ></StyledStepper>
                )}
                <Stack spacing={1} justifyContent="center" alignItems="center">
                  <Box
                    width="26px"
                    height="26px"
                    borderRadius="50%"
                    bgcolor={
                      step.active ? USER_COLOR[userType].main : '#D9D9D9'
                    }
                  />
                  <Stack alignItems="center">
                    <Typography
                      variant="Small_Profile_16"
                      color={
                        step.activeLabel ? USER_COLOR[userType].main : '#B6B6B6'
                      }
                    >
                      {step.label}
                    </Typography>
                    {/* <Typography
                    variant="Web_Label_14"
                    color={step.activeLabel ? 'rgba(0, 0, 0, 0.60)' : '#B6B6B6'}
                  >
                    {step.update_date || 'n/a'}
                  </Typography> */}
                  </Stack>
                </Stack>
              </Fragment>
            )
          })}
        </Stack>
      </StyledStatusPanel>
    )
  },
)

export default StatusPanel

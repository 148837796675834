import jwtDecode from 'jwt-decode';

export const getJWTDecode = <T>(token?: string) => {
  if (!token) return null;
  try {
    return jwtDecode(token) as T;
  } catch (_) {
    return null;
  }
};

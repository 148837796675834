import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { theme } from 'theme';
import { transientOptions } from 'utils/transientOptions';

export const StyledTestimonialItem = styled(Stack, transientOptions)`
  text-decoration: none;
  color: ${theme.palette.black.dark};
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #959595;
  border-radius: 8px;
  padding: 16px 22px;
`;

import { BOOKING_ACTIVITY } from 'models/parent/booking'

export interface NANNY_BOOKING_SUMMARY {
  bookingId: string
  duration: number
  nannyStatus: BOOKING_NANNY_STATUS
  parentFirstName: string
  houseType: string
  singleTwinPregnancy: string
  edd: string
  commencementDate: string
  createdAt: string
}

export interface NANNY_BOOKING_DETAIL {
  bookingId: string
  parentFirstName: string
  status: BOOKING_NANNY_STATUS
  edd: string
  commencementDate: string
  duration: number
  singleTwinPregnancy: string
  houseType: string
  pets: boolean
  otherRequirements: string
  activityList: BOOKING_ACTIVITY[]
  createdAt: string
}

export enum BOOKING_NANNY_STATUS {
  SUBMITTED = 'SUBMITTED',
  MATCHED = 'MATCHED',
  CONFIRMED = 'CONFIRMED',
  COMMENCED = 'COMMENCED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const bookingNannyStatusTitle = {
  [BOOKING_NANNY_STATUS.SUBMITTED]: 'Submitted',
  [BOOKING_NANNY_STATUS.MATCHED]: 'Matched',
  [BOOKING_NANNY_STATUS.CONFIRMED]: 'Confirmed',
  [BOOKING_NANNY_STATUS.COMMENCED]: 'Commenced',
  [BOOKING_NANNY_STATUS.COMPLETED]: 'Completed',
  [BOOKING_NANNY_STATUS.CANCELLED]: 'Cancelled',
  [BOOKING_NANNY_STATUS.DELETED]: 'Deleted',
}

export const bookingNannyStatusColor = {
  [BOOKING_NANNY_STATUS.SUBMITTED]: 'yellow.light',
  [BOOKING_NANNY_STATUS.MATCHED]: 'secondary.main',
  [BOOKING_NANNY_STATUS.CONFIRMED]: 'secondary.main',
  [BOOKING_NANNY_STATUS.COMMENCED]: 'green.light',
  [BOOKING_NANNY_STATUS.COMPLETED]: 'green.light',
  [BOOKING_NANNY_STATUS.CANCELLED]: '#B6B6B6',
  [BOOKING_NANNY_STATUS.DELETED]: '#B6B6B6',
}

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { NotifyService } from 'helpers'
import { DATA_PAGINATION, NANNY_PHOTO } from 'models'
import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { photoApi } from 'services'
import { useAppDispatch } from 'store/hook'
import { setGlobalLoading } from 'store/reducers/global'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import {
  StyledButton,
  StyledCaptionImg,
  StyledImageItem,
  StyledSkeleton,
} from './styles'

const mockItems = Array.from(Array(8).keys())

interface SectionGalleryProps {
  nannyId: string
}

const SectionGallery = memo(({ nannyId }: SectionGalleryProps) => {
  const dispatch = useAppDispatch()
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [listPhoto, setListPhoto] = useState<DATA_PAGINATION<NANNY_PHOTO>>()

  const isHasMore = useMemo(() => {
    return listPhoto && listPhoto.pageNum < listPhoto.totalPages
  }, [listPhoto])

  const slides = useMemo(
    () =>
      listPhoto?.data.map((img) => ({
        src: img.imageUrl,
        description: img.caption,
      })) || [],
    [listPhoto],
  )

  const getPhotos = async (pageNum: number = 1, pageSize = 8) => {
    const data: DATA_PAGINATION<NANNY_PHOTO> = await photoApi.nanny
      .getPhotos({ pageNum, pageSize, nannyId })
      .then(({ data }) => data)
    return data
  }

  const fetchInit = async () => {
    await getPhotos()
      .then((data) => setListPhoto(data))
      .catch(() => {})
  }

  const onLoadMore = async () => {
    if (!isHasMore) return
    dispatch(setGlobalLoading(true))
    await getPhotos(listPhoto.pageNum + 1)
      .then((data) => {
        setListPhoto((pre) => ({ ...data, data: [...pre.data, ...data.data] }))
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)))
  }

  useEffect(() => {
    fetchInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nannyId])

  const onShowPreviewImage = (idx: number) => {
    setPhotoIndex(idx)
    setIsOpenLightBox(true)
  }

  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Gallery</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 1, md: 2.5 }}>
          {listPhoto
            ? listPhoto.data.map((item, idx) => (
                <Fragment key={item.id}>
                  <StyledImageItem
                    onClick={() => onShowPreviewImage(idx)}
                    item
                    xs={6}
                    md={3}
                  >
                    <img
                      src={item.imageUrl}
                      alt={item.caption}
                      className="image_style"
                    />
                    <StyledCaptionImg variant="Web_Label_16">
                      {item.caption}
                    </StyledCaptionImg>
                  </StyledImageItem>
                </Fragment>
              ))
            : mockItems.map((_, idx) => (
                <Grid key={idx} item xs={6} md={3}>
                  <StyledSkeleton variant="rectangular" />
                  <Skeleton variant="text" sx={{ fontSize: 10 }} />
                </Grid>
              ))}
          {isHasMore && (
            <Grid item xs={12}>
              <StyledButton onClick={onLoadMore} variant="contained" fullWidth>
                Load More Photos
              </StyledButton>
            </Grid>
          )}
        </Grid>
        <Lightbox
          open={isOpenLightBox}
          close={() => setIsOpenLightBox(false)}
          slides={slides}
          plugins={[Captions]}
          index={photoIndex}
        />
      </Box>
    </Stack>
  )
})

export default SectionGallery

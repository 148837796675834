import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { theme } from 'theme';

export const FormBox = styled(Box)`
  padding-top: 66px;
  max-width: 686px;
  margin: auto;
  width: 100%;
  ${theme.breakpoints.down('lg')} {
    padding: 26px 0;
  }
`;

import styled from '@emotion/styled'
import { Box, Dialog } from '@mui/material'
import { theme } from 'theme'

export const ModalBoxStyled = styled(Box)`
  padding: 53px 24px 43px 24px;
  width: 100%;
  ${theme.breakpoints.up('md')} {
    padding: 33px 32px 52px 30px;
  }
`

export const DialogStyled = styled(Dialog)``
